import { Route, Routes } from "react-router-dom";
import React from "react";
import HomePage from "./pages/HomePage";
import IQAC from "./pages/iqac/iqac.jsx";
import OnlineServices from "./pages/online-services/OnlineServices";
import ContactUs from "./pages/contact-us/ContactUs";
import CommitteeCell from "./pages/committee-cell/CommitteeCell";
import VacanciesOfColleges from "./pages/recruiment/VacanciesOfColleges";
import Department from "./pages/department/Department";
import PhotoGallary from "./pages/media/PhotoGallary";
import AdminLogin from "./pages/admin/AdminLogin.jsx";
import CentralLibrary from "./pages/student-corner/CentralLibrary.jsx";
import AffiliatedColleges from "./pages/acadenics/AffiliatedColleges.jsx";



import DashboardLayout from "./pages/admin/DashboardLayout.jsx";
import CreatePage from "./pages/admin/pages/CreatePage.jsx";
import ResultList from "./pages/admin/results/ResultList.jsx";
import UploadResult from "./pages/admin/results/UploadResult.jsx";
import NoticeType from "./pages/admin/notice/NoticeType.jsx";
import AboutAbvv from "./pages/about/AboutAbvv.jsx";
import Notices from "./pages/admin/notice/Notices.jsx";
import UploadNotice from "./pages/admin/notice/UploadNotice.jsx";
import AddNoticeCategory from "./pages/admin/notice/AddNoticeCategory.jsx";
import Sliders from "./pages/admin/sliders/Sliders.jsx";
import PhotoGallery from "./pages/admin/galleries/PhotoGallery.jsx";
import WhatsNew from "./pages/admin/whatsnew/WhatsNew.jsx";
import Departments from "./pages/admin/departments/Departments.jsx";
import LeftMenu from "./pages/admin/leftmenu/LeftMenu.jsx";
import ImpWebsite from "./pages/admin/impwebsite/ImpWebsite.jsx";
import NewsList from "./pages/admin/news/NewsList.jsx";
import EventsList from "./pages/admin/events/EventsList.jsx";
import MediaFile from "./pages/admin/media/MediaFile.jsx";
import Contact from "./pages/admin/contact/Contact.jsx";
import FeedbackFromStudent from "./pages/admin/feedback/FeedbackFromStudent.jsx";
import Alumnies from "./pages/admin/alumnies/Alumnies.jsx";
import AddNewSlider from "./pages/admin/sliders/AddNewSlider.jsx";
import AddImages from "./pages/admin/galleries/AddImages.jsx";
import UploadMedia from "./pages/admin/media/UploadMedia.jsx";
import AddWhatNew from "./pages/admin/whatsnew/AddWhatNew.jsx";
import AddNewDepartment from "./pages/admin/departments/AddNewDepartment.jsx";
import AddMenuContent from "./pages/admin/leftmenu/AddMenuContent.jsx";
import HonbleChancellor from "./pages/administration/HonbleChancellor.jsx";
import Nep from "./pages/other/Nep.jsx";
import AddNews from "./pages/admin/news/AddNews.jsx";
import AddNewEvent from "./pages/admin/events/AddNewEvent.jsx";
import AmenitiesPage from "./pages/amenities/Amenities.jsx";



// import FtpProgram from "./pages/department/ftpDept/program/FtpProgram.jsx";
import AddNewAdmin from "./pages/admin/admin-list/AddNewAdmin.jsx";
import Settings from "./pages/admin/settings/Settings.jsx";
import AdminList from "./pages/admin/admin-list/AdminList.jsx";
import DepartmentPages from "./pages/admin/department-page/DepartmentPages.jsx";
import CreateDepartmentPage from "./pages/admin/department-page/CreateDepartmentPage.jsx";
import AddNewAlumnies from "./pages/admin/alumnies/AddNewAlumnies.jsx";
import AddURL from "./pages/admin/impwebsite/AddURL.jsx";
import PageNotFound from "./pages/PageNotFound.jsx";
import DepartmentHome from "./pages/department/commonDept/DepartmentHome.jsx";
import DepartmentSubPages from "./pages/department/commonDept/DepartmentSubPages.jsx";
import AdminPrivateRoute from "./middleware/AdminPrivateRoute.js";
import Syllabus from "./pages/syllabus/Syllabus.jsx";
import ResearchHome from "./pages/research/researchHome.jsx";
import Copyright from "./pages/termsadncondition/Copyright.jsx";
import Disclaimer from "./pages/termsadncondition/Disclaimer.jsx";
import PrivacyPolicy from "./pages/termsadncondition/PrivacyPolicy.jsx";
import Hyperlinking from "./pages/termsadncondition/Hyperlinking.jsx";
import TermAndCondition from "./pages/termsadncondition/TermAndCondition.jsx";
import TableView from "./components/TableView.jsx";
import PagesList from "./pages/admin/pages/PagesList.jsx";
import QuickLink from "./pages/quicklink/QuickLink.jsx";
import VcProfile from "./pages/other/VcProfile.jsx";
import SyllabusTable from "./pages/admin/syllabus/SyllabusTable.jsx";
import AddSyllabus from "./pages/admin/syllabus/AddSyllabus.jsx";
import Courses from "./pages/admin/courses/Courses.jsx";
import AddCourse from "./pages/admin/courses/AddCourse.jsx";
import CourseData from "./pages/admin/course-data/CourseData.jsx";
import AddCourseData from "./pages/admin/course-data/AddCourseData.jsx";
import IqacHome from "./pages/iqac/commonDept/IqacHome.jsx";
import IqacSubPages from "./pages/iqac/commonDept/IqacSubPages.jsx";
import CentralLib from "./pages/central-library/CentralLib.jsx";
import ComplaintForm from "./pages/complaint-form/ComplaintForm.jsx";
import Complaint from "./pages/admin/complaint/Complaint.jsx";
import AtalGyan from "./pages/e-resources/Eresource.jsx";
import Eresource from "./pages/e-resources/Eresource.jsx";
import EligilibilityCertificate from "./pages/e-resources/EligilibilityCertificate.jsx";

const BaseRouter = () => {
  return (
    <div>
      <Routes>
        {/* New website */}
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/about-us" element={<AboutAbvv />} />
        <Route exact path="/administrations" element={<HonbleChancellor />} />
        <Route exact path="/academics" element={<AffiliatedColleges />} />
        <Route exact path="/amenities" element={<AmenitiesPage />} />
        <Route exact path="/student-corner" element={<CentralLibrary />} />
        <Route exact path="/media" element={<PhotoGallary />} />
        <Route exact path="/committees-cells" element={<CommitteeCell />} />
        {/* <Route exact path="/IQAC" element={<Iqac />} /> */}
        <Route exact path="/recruiment" element={<VacanciesOfColleges />} />
        <Route exact path="/research" element={<ResearchHome/>}/>
        {/* for single pages without connectinected database */}
        <Route exact path="/IQAC" element={<IqacHome />} />
        {/* <Route exact path="/CommitteeCell" element={<CommitteeCell />} /> */}
        <Route exact path="/online-services" element={<OnlineServices />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/department" element={<Department />} />
        <Route exact path="/syllabus" element={<Syllabus />} />
        <Route exact path="/central-library" element={<CentralLib />} />
        <Route exact path="/complaint-form" element={<ComplaintForm />} />
        <Route exact path="/e-resources" element={<Eresource />} />
        <Route exact path="/eligilibility-certificate" element={<EligilibilityCertificate />} />

        {/* Other Page link */}
        
        <Route exact path="/nep" element={<Nep />} />
        

        <Route exact path="/department-home" element={<DepartmentHome />} />
        <Route exact path="/department-sub-pages" element={<DepartmentSubPages />} />
        <Route exact path="/table-view" element={<TableView />} />
        <Route exact path="/vc-profile" element={<VcProfile />} />
        <Route exact path="/iqac-sub-pages" element={<IqacSubPages />} />



        {/* quicklinks */}
        <Route exact path="/quick-links" element={<QuickLink />} />
        
      {/* terms and condition on footer */}

        <Route exact path="/copyright" element={<Copyright />} />
        <Route exact path="/disclaimer" element={<Disclaimer />} />
        <Route exact path="/hyper-links" element={< Hyperlinking/>} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-and-condition" element={<TermAndCondition />} />





      
        {/* ADMIN ROUTING */}


        <Route exact path="/admin-login" element={<AdminLogin />} />
        <Route element={<AdminPrivateRoute />}>
          <Route exact path="admin/dashboard" element={<DashboardLayout />} />
          <Route exact path="admin/pages" element={<PagesList />} />
          <Route exact path="admin/create-page" element={<CreatePage />} />
          <Route exact path="admin/results" element={<ResultList />} />
          <Route exact path="admin/upload-result" element={<UploadResult />} />
          <Route exact path="admin/notice-type" element={<NoticeType />} />
          <Route exact path="admin/notices" element={<Notices />} />
          <Route exact path="admin/upload-notice" element={<UploadNotice />} />
          <Route exact path="admin/add-notice-cate" element={<AddNoticeCategory />} />
          <Route exact path="admin/slider" element={<Sliders />} />
          <Route exact path="admin/addslider" element={<AddNewSlider />} />
          <Route exact path="admin/PhotoGallery" element={<PhotoGallery />} />
          <Route exact path="admin/addimages" element={<AddImages />} />
          <Route exact path="admin/mediafile" element={<MediaFile />} />
          <Route exact path="admin/uploadmedia" element={<UploadMedia />} />
          <Route exact path="admin/whatsnew" element={<WhatsNew />} />
          <Route exact path="admin/addwhatsnew" element={<AddWhatNew />} />
          <Route exact path="admin/departments" element={<Departments />} />
          <Route exact path="admin/addnewdepartment" element={<AddNewDepartment />} />
          <Route exact path="admin/leftmenu" element={<LeftMenu />} />
          <Route exact path="admin/addleftmenu" element={<AddMenuContent />} />
          <Route exact path="admin/impwebsite" element={<ImpWebsite />} />
          <Route exact path="admin/newslist" element={<NewsList />} />
          <Route exact path="admin/addnews" element={<AddNews />} />
          <Route exact path="admin/eventslist" element={<EventsList />} />
          <Route exact path="admin/addnewevent" element={<AddNewEvent />} />
          <Route exact path="admin/contact" element={<Contact />} />
          <Route exact path="admin/feedback" element={<FeedbackFromStudent />} />
          <Route exact path="admin/alumnies" element={<Alumnies />} />
          <Route exact path="admin/add-alumnies" element={<AddNewAlumnies />} />
          <Route exact path="admin/add-URL" element={<AddURL />} />
          <Route exact path="admin/admin-list" element={<AdminList />} />
          <Route exact path="admin/add-new-admin" element={<AddNewAdmin />} />
          <Route exact path="admin/settings" element={<Settings />} />
          <Route exact path="admin/department-page" element={<DepartmentPages />} />
          <Route exact path="admin/createdepartmentpage" element={<CreateDepartmentPage />} />
          <Route exact path="/admin/syllabus" element={<SyllabusTable/>} />
        <Route exact path="/admin/add-syllabus" element={<AddSyllabus/>} />
        <Route exact path="/admin/course" element={<Courses/>} />
        <Route exact path="/admin/add-course" element={<AddCourse/>} />
        <Route exact path="/admin/course-data" element={<CourseData/>} />
        <Route exact path="/admin/add-course-data" element={<AddCourseData/>} />
        <Route exact path="/admin/complaint" element={<Complaint/>} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default BaseRouter;