import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './App.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
        <Suspense fallback={<div>Loading...</div>}>

            <App />
        </Suspense>
);
// Optionally, you can also include reportWebVitals if needed
reportWebVitals();