// src/pages/DashboardPage.js
import React, { useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useEffect } from "react";
import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import { toJS } from "mobx";
import moment from "moment";
import Swal from "sweetalert";
import { BASE_FILE_URL } from "../../../api/config";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../elements/Spinner";

const EventsList = observer(() => {
  const [eventList, setEventList] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    adminStore.getEvents();
  }, []);

  useEffect(() => {
    setEventList(toJS(adminStore.data.events));
  }, [adminStore.data.events]);

  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Event!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        adminStore.deleteEvent(row.id, navigationCallBack);
      }
    });
  };

  const handleEdit = (row) => {
    navigate('/admin/addnewevent', { state: { event_id: row.id } });
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Deleted", "success");
    adminStore.getEvents();
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "60px",
    },
    {
      name: "Event Name",
      selector: (row) => row.event_name,
      sortable: true,
      wrap: true,

    },
    {
      name: "Event Level",
      selector: (row) => row.event_level,
      sortable: true,
    },
    {
      name: "Convener",
      selector: (row) => row.convener,
      sortable: true,
    },
    {
      name: "Venue",
      selector: (row) => row.venue,
      sortable: true,
    },

    {
      name: "Dept Id",
      selector: (row) => row.dept_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Files",
      selector: (row) => (
        row.brochure_file?  <a
          href={BASE_FILE_URL + row.brochure_file}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa-regular fa-folder-open"></i>
        </a>:"No File"
      ),
      sortable: true,
    },
 
    {
      name: "Status",
      selector: (row) => row.status ? "Active" : "In-active",
      sortable: true,
    },
    {
      name: "Creation Time",
      selector: (row) => moment(row.created_at).format("DD/MM/YYYY hh:mm A"),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={() => handleEdit(row)}
          >
            <i className="far fa-edit"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDelete(row)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true, // Ensures the column is on the far right
      style: {
        position: 'sticky',
        right: 0,
        backgroundColor: '#fff', // Background color to prevent overlay issues
      }
    },
  ];

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div  >
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Events
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a
                href="/admin/addnewevent"
                className="page-title signup-link fs-4 pointer"
              >
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Event List
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={eventList} />
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>
  );
});

export default EventsList;

// name email password status roll 