// src/components/Header.js
import React, { useEffect, useMemo, useState } from 'react';
import TokenStore from '../../../store/TokenStore';
import { useNavigate } from 'react-router-dom';
import adminStore from '../../../store/AdminStore';
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import Swal from "sweetalert";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BASE_FILE_URL } from '../../../api/config';
import CopyToClipboard from 'react-copy-to-clipboard';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

const Header = observer(() => {
  const navigate = useNavigate()
  const [mediaFiles, setMediaFiles] = useState();
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [showMedia, setMedia] = useState(false);
  const [filter, setFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const logout = () => {
    TokenStore.clearAllAsyncStore();
    navigate("/admin-login");
  }
  const openUploadModal = () => {
    setShowMediaModal(true)
  }
  const openMediaList = () => {
    adminStore.getMediaFiles();
    setMedia(true)
  }
  useEffect(() => {
    setMediaFiles(toJS(adminStore.data.mediaFiles));
  }, [adminStore.data.mediaFiles]);

  const validationSchema = Yup.object({
    files: Yup.mixed().required("Required"),
    status: Yup.boolean().required("Required"),
  });

  const addMediaFiles = (values) => {
    const formData = new FormData();
    formData.append("delete_ids", values.delete_ids);
    values.files.forEach((file, index) => {
      formData.append(`files`, file.filePath);
      formData.append(`titles[]`, file.title);
    });

    adminStore.addMediaFiles(formData, navigationCallBack);
  };

  const navigationCallBack = () => {
    adminStore.getMediaFiles();
    Swal("Success!", "Successfully Added", "success");
    handleClose();
  };

  const handleClose = () => {
    setShowMediaModal(false)
    setMedia(false)
  }
  const onCopy = () => {
    toast.success("URL Copied")
  }
  const sortedFilteredFiles = useMemo(() => {
    let filtered = mediaFiles?.filter(file =>
      file.title.toLowerCase().includes(filter.toLowerCase())
    );

    let sorted = filtered?.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.id - b.id;
      } else {
        return b.id - a.id;
      }
    });

    return sorted;
  }, [mediaFiles, filter, sortOrder]);
  return (
    <>
      <nav className="navbar sticky-top navbar-expand-sm navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavHeader"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavHeader">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Account
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#">
                  Profile
                </a>
                <a className="dropdown-item" href="#">
                  Settings
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#" onClick={logout}>
                  Logout
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link ms-3 pointer" onClick={openUploadModal}> <i class="fas fa-upload"></i> Upload Media Files</span>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link ms-3 pointer" onClick={openMediaList}> <i class="fas fa-photo-video"></i> Media Files</span>
            </li>
          </ul>
        </div>
      </nav>
      <div className={`modal fade ${showMediaModal ? 'show' : ''}`} tabIndex="-1" style={{ display: showMediaModal ? 'block' : 'none' }} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Upload Media Files</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{
                  id: mediaFiles?.id,
                  delete_ids: [],
                  files: mediaFiles?.files || [{ title: "", filePath: "" }],
                  status: true,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  addMediaFiles(values);
                }}
              >
                {({ values, handleChange, setFieldValue }) => (
                  <Form>
                    <div className="row mb-3">
                      <FieldArray
                        name="files"
                        render={(arrayHelpers) => (
                          <div>
                            <div className="d-flex flex-wrap">
                              {values.files.map((file, index) => (
                                <div key={index} className={values.delete_ids.includes(file.id) ? "form-group card deleted-card" : "form-group card"}>
                                  <i className="fas fa-undo-alt undo-icon" style={{ filter: "none", pointerEvents: "none" }}></i>
                                  <label htmlFor={`files.${index}.filePath`}>
                                    Select File
                                  </label>
                                  <input
                                    id={`files.${index}.filePath`}
                                    name={`files.${index}.filePath`}
                                    type="file"
                                    className="form-control"
                                    onChange={(event) => {
                                      const file = event.target.files[0];
                                      const fileSizeLimit = 50 * 1024 * 1024; // 50MB
                                      if (file.size <= fileSizeLimit) {
                                        setFieldValue(`files.${index}.filePath`, file);
                                      } else {
                                        toast.warn("File size exceeds 50MB limit");
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`files.${index}.filePath`}
                                    component="div"
                                    className="text-danger"
                                  />
                                  <label htmlFor={`files.${index}.title`}>
                                    Title
                                  </label>
                                  <input
                                    id={`files.${index}.title`}
                                    name={`files.${index}.title`}
                                    type="text"
                                    value={values.files[index].title}
                                    className="form-control"
                                    onChange={(event) => {
                                      setFieldValue(`files.${index}.title`, event.target.value);
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`files.${index}.title`}
                                    component="div"
                                    className="text-danger"
                                  />
                                  <button
                                    disabled={values.files.length === 1}
                                    type="button"
                                    className="btn btn-danger mt-3"
                                    onClick={() => {
                                      if (values.files[index].id) {
                                        setFieldValue('delete_ids', [values.files[index].id, ...values.delete_ids]);
                                      } else {
                                        arrayHelpers.remove(index);
                                      }
                                    }}
                                  >
                                    Remove
                                  </button>
                                </div>
                              ))}
                            </div>
                            <button
                              type="button"
                              className="btn btn-success "
                              onClick={() =>
                                arrayHelpers.push({
                                  title: "",
                                  order: values.files?.length + 1,
                                  filePath: "",
                                })
                              }
                            >
                              <i className="fas fa-plus  pe-3"></i>
                              Add Image
                            </button>
                          </div>
                        )}
                      />
                    </div>
                    <div className="row justify-content-center">
                      <button type="submit" className="btn btn-primary w-25">
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal fade ${showMedia ? 'show' : ''}`} tabIndex="-1" style={{ display: showMedia ? 'block' : 'none' }} aria-labelledby="exampleModalLabel2" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Media Files</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="container mt-4">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Filter by title"
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 text-end">
                      <button
                        className="btn btn-primary"
                        onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Sort by ID {sortOrder === 'asc' ? '🔼' : '🔽'}
                      </button>
                    </div>
                  </div>

                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>File</th>
                        <th>Title</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedFilteredFiles?.map((file) => (
                        <tr key={file.id}>
                          <td>{file.id}</td>
                          <td>
                            {file?.filename && (
                              ['jpg', 'png', 'jpeg', 'gif'].includes(file.filename?.split('.').pop()) ? (
                                <img
                                  className="rounded"
                                  height={50}
                                  width={50}
                                  src={BASE_FILE_URL + file.filename}
                                  alt={<a>{BASE_FILE_URL + file.filename}</a>}
                                  style={{ cursor: 'pointer' }}
                                />
                              ) : (
                                <a href={BASE_FILE_URL + file.filename} target='_blank'>  <button className="btn btn-sm btn-success">View</button></a>
                              )
                            )}
                          </td>
                          <td>{file.title}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <CopyToClipboard onCopy={() => onCopy()} text={BASE_FILE_URL + file.filename}>
                                <button className="btn btn-sm btn-success">
                                  <i className="far fa-copy"></i>
                                </button>
                              </CopyToClipboard>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Header;
