import React from "react";

const ReusableModal = ({ id, title, body, footer, onClose }) => {
    return (
        <div
            className="modal modal-lg show d-block"
            tabIndex="-1"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header justify-content-between">
                        <h5 className="modal-title text-uppercase" id={`${id}Label`}>
                            {title}
                        </h5>
                        <button
                            type="button"
                            className="btn fs-3 p-0 m-0"
                            data-dismiss="modal"
                            onClick={onClose} // Add the onClick handler here
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center">{
                        <div>
                            <img src={body?.image} className="mb-3 img-fluid img-thumbnail" alt="he" title="he" />
                            <h4>{body?.name}</h4>
                            <p className="vcheight">
                                {body?.designation}
                            </p>
                            <blockquote >
                                <small>{body?.message}</small>
                            </blockquote>



                        </div>}
                    </div>
                    <div className="modal-footer">
                        <a href={body?.href} target="blank"><button className="btn btn-primary mx-2">View Detailed Profile</button></a>
                        {footer}</div>
                </div>
            </div>
        </div>
    );
};

export default ReusableModal;
