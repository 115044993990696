import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { observer } from 'mobx-react';
import secureLocalStorage from 'react-secure-storage';

const Sidebar = observer(() => {
  const [adminData, setAdminData] = useState([]);
  const [rights, setRights] = useState([]);

  useEffect(() => {
    if (secureLocalStorage.getItem('adminData')) {
      setAdminData(JSON.parse(secureLocalStorage.getItem('adminData')));
      if (JSON.parse(secureLocalStorage.getItem('adminData'))?.role === 0) {
        setRights(JSON.parse(JSON.parse(secureLocalStorage.getItem('adminData'))?.rights))
      } else {
        setRights(['all'])
      }
    }
  }, []);

  console.log(rights);

  return (
    <aside className="sidebar pt-3">
      <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <span className="fs-4 "><img src="image/ABVV-NewLogo.png" width="30px " alt="" />ABVV | Admin</span>
      </a>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">

        {(rights?.includes('dashboard') || rights?.includes('all')) && <li>
          <NavLink to="/admin/dashboard" className="nav-link">
            <i className="fas fa-home pe-2"></i>
            Dashboard
          </NavLink>
        </li>}
        {(rights?.includes('admin-list') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/admin-list" className="nav-link">
            <i class="fas fa-user-tie pe-2"></i>
            Admins
          </NavLink>
        </li>}
        {(rights?.includes('departments') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/departments" className="nav-link">
            <i class="fas fa-graduation-cap pe-2"></i>
            Departments
          </NavLink>
        </li>}
        {(rights?.includes('pages') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/pages" className="nav-link ">
            <i className="fas fa-laptop pe-2"></i>
            Pages
          </NavLink>
        </li>}
        {(rights?.includes('department-page') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/department-page" className="nav-link">
            <i class="fa-solid fa-building-user pe-2"></i>
            Department Page
          </NavLink>
        </li>}
        {(rights?.includes('newslist') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/newslist" className="nav-link">
            <i class="fas fa-newspaper pe-2"></i>
            News
          </NavLink>
        </li>}
        {(rights?.includes('eventslist') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/eventslist" className="nav-link">
            <i class="fas fa-calendar-minus pe-2"></i>
            Events
          </NavLink>
        </li>}
        {(rights?.includes('whatsnew') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/whatsnew" className="nav-link">
            <i class="fas fa-bullhorn pe-2"></i>
            What's New
          </NavLink>
        </li>}

        {(rights?.includes('results') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/results" className="nav-link ">
            <i className="fas fa-list pe-2" ></i>
            Results
          </NavLink>
        </li>}
        {(rights?.includes('notices') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/notices" className="nav-link">
            <i className="fas fa-bell pe-2"></i>
            Notices
          </NavLink>
        </li>}
        {(rights?.includes('all-syllabus') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/syllabus" className="nav-link">
            <i className="fas fa-bell pe-2"></i>
            Syllabus
          </NavLink>
        </li>}
        {(rights?.includes('all-syllabus') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/course" className="nav-link">
            <i className="fas fa-bell pe-2"></i>
            Courses
          </NavLink>
        </li>}
        {(rights?.includes('all-syllabus') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/course-data" className="nav-link">
            <i className="fas fa-bell pe-2"></i>
            Course Data
          </NavLink>
        </li>}
        {(rights?.includes('slider')|| rights?.includes('all')) && <li>
          <NavLink to="/admin/slider" className="nav-link">
            <i class="fas fa-sliders pe-2"></i>
            Sliders
          </NavLink>
        </li>}
        {(rights?.includes('PhotoGallery') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/PhotoGallery" className="nav-link">
            <i class="fas fa-photo-video"></i>&nbsp;
            Photo/Video Gallary
          </NavLink>
        </li>}
        {(rights?.includes('mediafile') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/mediafile" className="nav-link">
            <i class="fas fa-forward pe-2"></i>
            Media Files
          </NavLink>
        </li>}

        {(rights?.includes('impwebsite') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/impwebsite" className="nav-link">
            <i class="fas fa-globe pe-2"></i>
            Imp Webs
          </NavLink>
        </li>
        }

        {(rights?.includes('contact') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/contact" className="nav-link">
            <i class="fas fa-id-badge pe-2"></i>
            Contacts
          </NavLink>
        </li>}
        {(rights?.includes('complaint') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/complaint" className="nav-link">
            <i class="fas fa-id-badge pe-2"></i>
            Complaint
          </NavLink>
        </li>}
        {(rights?.includes('feedback')|| rights?.includes('all')) && <li>
          <NavLink to="/admin/feedback" className="nav-link">
            <i class="fas fa-comments pe-2"></i>
            Feedback
          </NavLink>
        </li>}
        {(rights?.includes('alumnies') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/alumnies" className="nav-link">
            <i class="fas fa-users pe-2"></i>
            Alumnies
          </NavLink>
        </li>}
        {(rights?.includes('settings') || rights?.includes('all'))&& <li>
          <NavLink to="/admin/settings" className="nav-link">
            <i class="fa-solid fa-gears pe-2"></i>
            Settings
          </NavLink>
        </li>}

      </ul>
      <hr />

    </aside>
  );
});

export default Sidebar;
