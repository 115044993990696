// src/pages/DashboardPage.js
import React from 'react';

import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';

const DashboardLayout = () => {
  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          {<Dashboard />}
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
