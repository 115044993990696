import React from 'react'

const PrivacyPolicy=()=> {
  return (
   <div>
    <>
  <header>
    {" "}
    <h1>Atal Bihari Vajpayee Vishwavidyalaya</h1>{" "}
    <p>© ABVV, Bilaspur (C.G.) - 495001, India</p>{" "}
  </header>{" "}
  <main>
    {" "}
    <section id="terms-policies">
      {" "}
      <h2>Terms &amp; Policies</h2>{" "}
      <p>
        Welcome to Atal Bihari Vajpayee Vishwavidyalaya (ABVV). By accessing or
        using our website, you agree to comply with and be bound by the
        following terms and conditions. Please review these terms carefully. If
        you do not agree with any of these terms, you are prohibited from using
        this site.
      </p>{" "}
      <ol>
        {" "}
        <li>
          <strong>Use of Site</strong>: The content provided on this website is
          for general informational purposes only. It is subject to change
          without notice.
        </li>{" "}
        <li>
          <strong>User Conduct</strong>: Users are prohibited from using the
          site in a manner that could damage, disable, overburden, or impair the
          site or interfere with any other party's use and enjoyment of the
          site.
        </li>{" "}
        <li>
          <strong>Intellectual Property</strong>: All content, trademarks, and
          data on this site, including but not limited to text, designs,
          graphics, and images, are the property of ABVV unless otherwise
          stated.
        </li>{" "}
        <li>
          <strong>Limitation of Liability</strong>: ABVV will not be liable for
          any direct, indirect, incidental, special, or consequential damages
          that result from the use or inability to use the website.
        </li>{" "}
      </ol>{" "}
    </section>{" "}
    <section id="disclaimer">
      {" "}
      <h2>Disclaimer</h2>{" "}
      <p>
        The information provided by Atal Bihari Vajpayee Vishwavidyalaya (ABVV)
        on this website is for general informational purposes only. All
        information on the site is provided in good faith; however, we make no
        representation or warranty of any kind, express or implied, regarding
        the accuracy, adequacy, validity, reliability, availability, or
        completeness of any information on the site.
      </p>{" "}
      <ol>
        {" "}
        <li>
          <strong>No Professional Advice</strong>: The site does not contain any
          professional advice. Users should seek appropriate professional advice
          before acting on any information provided on the site.
        </li>{" "}
        <li>
          <strong>External Links</strong>: The site may contain links to
          external websites that are not provided or maintained by or in any way
          affiliated with ABVV. Please note that ABVV does not guarantee the
          accuracy, relevance, timeliness, or completeness of any information on
          these external websites.
        </li>{" "}
      </ol>{" "}
    </section>{" "}
    <section id="privacy-policy">
      {" "}
      <h2>Privacy Policy</h2>{" "}
      <p>
        At Atal Bihari Vajpayee Vishwavidyalaya (ABVV), we are committed to
        protecting your privacy. This Privacy Policy outlines our practices
        regarding the collection, use, and disclosure of your information
        through our website.
      </p>{" "}
      <ol>
        {" "}
        <li>
          <strong>Information Collection</strong>: We may collect personal
          information such as your name, email address, and phone number when
          you voluntarily submit it through our website forms.
        </li>{" "}
        <li>
          <strong>Use of Information</strong>: The information we collect is
          used to provide and improve our services, respond to your inquiries,
          and communicate with you about updates and promotions.
        </li>{" "}
        <li>
          <strong>Data Security</strong>: We implement appropriate security
          measures to protect your personal information from unauthorized
          access, alteration, disclosure, or destruction.
        </li>{" "}
        <li>
          <strong>Cookies</strong>: Our website may use cookies to enhance your
          user experience. You can choose to accept or decline cookies through
          your browser settings.
        </li>{" "}
        <li>
          <strong>Third-Party Disclosure</strong>: We do not sell, trade, or
          otherwise transfer your personal information to outside parties
          without your consent, except as required by law.
        </li>{" "}
      </ol>{" "}
      <p>
        By using our website, you consent to our Terms &amp; Policies,
        Disclaimer, and Privacy Policy. If you have any questions or concerns,
        please contact us at [contact information].
      </p>{" "}
    </section>{" "}
  </main>{" "}
  <footer>
    {" "}
    <p>© ABVV, Bilaspur (C.G.) - 495001, India</p>{" "}
  </footer>
</>

   </div>
  )
}

export default PrivacyPolicy