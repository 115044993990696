import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import {
  adminTabs,
  validationSchemaAddAdmin,
  validationSchemaUpdateAdmin,
} from "../../../utils/constentFunc";
import Select from "react-select";
import { toJS } from "mobx";
import Spinner from "../../../elements/Spinner";

const AddNewAdmin = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState(null);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    adminStore.getDepartments();

  }, []);

  console.log(adminData,"adminData");
  

  useEffect(() => {
    setDepartments(toJS(adminStore.data.departments));
  }, [adminStore.data.departments]);

  useEffect(() => {
    setAdminData(
      adminStore.data.admins?.find((x) => x.id === location?.state?.id)
    );
  }, [location?.state?.admins]);

  const addNewAdminList = (values) => {
    if (!values.id) {
      adminStore.addNewAdminList(values, navigationCallBack);
    } else {
      adminStore.updateAdminList(values, navigationCallBackUpdate);
    }
  };

  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/admin-list");
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Added", "success");
    navigate("/admin/admin-list");
    
  };

  return (

    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container mt-3 p-3 bg-white">
            <h3 className="text-center mb-4">
              {adminData?.id ? "Update admin" : "Create Admin"}
            </h3>
            <Formik
              initialValues={{
                id: adminData?.id,
                name: adminData?.name || "",
                email: adminData?.email || "",
                password: "",
                confirm_password: "",
                status: adminData?.status || true,
                role: adminData?.role !== undefined ? String(adminData.role) : "1",
                dept_id: adminData?.dept_id || "",
                rights: adminData?.rights ? JSON.parse(adminData?.rights) : [],
              }}
              
              enableReinitialize
              validationSchema={
                adminData?.id
                  ? validationSchemaUpdateAdmin
                  : validationSchemaAddAdmin
              }
              onSubmit={(values) => {
                addNewAdminList(values);
              }}
            >
              {({ setFieldValue, handleChange, values }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <label htmlFor="name">Name</label>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="email"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("email", event.target.value)
                          }
                        />
                        <label htmlFor="email">Email </label>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="password"
                          name="password"
                          autoComplete="off"
                          className="form-control"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("password", event.target.value)
                          }
                        />
                        <label htmlFor="password">Password</label>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="confirm_password"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue(
                              "confirm_password",
                              event.target.value
                            )
                          }
                        />
                        <label htmlFor="confirm_password">
                          Confirm Password
                        </label>
                        <ErrorMessage
                          name="confirm_password"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="role"
                          className="form-select"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("role", event.target.value)
                          }
                        >
                          <option value="" disabled>Select</option>
                          <option value="0">Sub-Admin</option>
                          <option value="1">Admin</option>
                        </Field>
                        <label htmlFor="role">Role</label>
                        <ErrorMessage
                          name="role"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Select
                        name="rights"
                        isMulti
                        isSearchable
                        closeMenuOnSelect={false}
                        value={adminTabs?.filter((x) =>
                          values.rights?.includes(x.tab_value)
                        )}
                        options={adminTabs}
                        getOptionLabel={(option) => option.tab_name}
                        getOptionValue={(option) => option.tab_value}
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            "rights",
                            selectedOptions.map((option) => option.tab_value)
                          );
                        }}
                        placeholder="Select Rights"
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 1000, maxHeight: 200, overflowY: "auto" }),
                        }}
                      />

                      <ErrorMessage
                        name="rights"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Select
                          value={departments?.find((x) => x.id === values.dept_id) || ""}
                          options={departments}
                          getOptionLabel={(option) => option.department_name}
                          getOptionValue={(option) => option.id}
                          onChange={(event) =>
                            setFieldValue("dept_id", event?.id)
                          }
                          required
                          placeholder="Select a department"
                          styles={{
                            control: (provided) => ({ ...provided, height: 58 }),
                          }}
                        />
                        <ErrorMessage
                          name="dept_id"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="status"
                          className="form-select"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("status", event.target.value)
                          }
                        >
                          <option value="" disabled>Select </option>
                          <option value={true}>Active</option>
                          <option value={false}>In-active</option>
                        </Field>
                        <label htmlFor="status">Status</label>
                        <ErrorMessage
                          name="dept_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                  <div className="row justify-content-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{ width: "120px" }}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default AddNewAdmin;
