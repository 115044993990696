import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { observer } from "mobx-react";
import websiteStore from "../../store/WebsiteStore";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../api/config";
import moment from "moment";

const CentralLib = observer(() => {
  const [librarySubPage, setLibrarySubPage] = useState([]);
  const [selectorTab, setSelectorTab] = useState(null);
  const [filterTabName, setFilterTabName] = useState("");

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "tabName") setFilterTabName(value); // Handle tab name filter change
  };

  useEffect(() => {
    const pages = websiteStore.data.pages?.filter(
      (x) => x.tab_name === "central-library"
    );
    setLibrarySubPage(pages);
  }, [websiteStore.data.pages]);

//   useEffect(() => {
//     setLibrarySubPage(
//       websiteStore.data.pages?.filter((x) => x.tab_name === "central-library")
//     );
//     if (
//       websiteStore.data.pages?.length !== 0 &&
//       !websiteStore.data.selectedSubTab
//     ) {
//       websiteStore.data.selectedSubTab = websiteStore.data.pages?.filter(
//         (x) => x.tab_name === "central-library"
//       )[0];
//     }
//   }, [websiteStore.data.pages]);

  useEffect(() => {
    setSelectorTab(websiteStore.data.selectedSubTab);
  }, [websiteStore.data.selectedSubTab]);

  const handleTabClick = (item) => {
    setSelectorTab(item);
  };

  return (
    <div>
      <Header />
      <section className="innerbanner" />
      <div style={{ width: "100%" }}>
        <div className="container-fluid">
          <div className="row2">
            <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light">
              <div className="d-flex flex-column align-items-start px-3 pt-2 text-white">
                <h3 className="main-head w-100">Central Library</h3>
                <div className="form-group mb-2 w-100 d-flex" style={{gap:"15px"}}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Filter"
                    value={filterTabName}
                    name="tabName"
                    
                    onChange={handleFilterChange}
                    
                    
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" style={{width:"20px", opacity:"0.5"}} viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                </div>
                {librarySubPage?.length > 0 && (
                  <ul
                    className="nav mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
                    id="menu"
                  >
                    {librarySubPage
                      .filter((item) =>
                        item.page_name
                          .toLowerCase()
                          .includes(filterTabName.toLowerCase())
                      )?.map((item,ind) => (
                      <li key={ind} className="w-100">
                        <a
                          onClick={() => handleTabClick(item)}
                          className={
                            selectorTab?.id === item.id
                              ? "active-tab nav-link align-middle px-2"
                              : "nav-link align-middle px-2"
                          }
                        >
                          <i className="fs-4 bi-house" />
                          <span className="d-sm-inline gd active-text">
                            {item?.page_name}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
                <hr />
              </div>
            </div>
            <div className="row1 mx-auto">
              <h3 className="main-head">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                </svg>
                {selectorTab?.page_name}
              </h3>
              <div>
                {console.log(librarySubPage,"librarySubPage")}
                {selectorTab ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: selectorTab?.page_data }}
                  />
                ) : (
                  <p>Select a tab to view content</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default CentralLib;
