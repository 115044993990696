// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import { toJS } from "mobx";
import moment from "moment";
import Swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import CKEditor from 'ckeditor4-react';
import ReusableModal from "../../../components/ReusableModal";
import PageViewModal from "../../../components/PageViewModal";
import CopyToClipboard from "react-copy-to-clipboard";
import { BASE_FILE_URL } from "../../../api/config";
import { toast } from "react-toastify";
import Spinner from "../../../elements/Spinner";

const PagesList = observer(() => {
  const navigate = useNavigate()
  const [pageList, setPageList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();

  useEffect(() => {
    adminStore.getPages();
  }, []);

  useEffect(() => {
    setPageList(toJS(adminStore.data.pages))
  }, [adminStore.data.pages]);

  const onCopy = () => {
    toast.success("URL Copied")
  }
  const navigationCallBack = () => {
    Swal("Success!", "Successfully Deleted!", "success");
    adminStore.getPages();
  }

  const handleEdit = (row) => {
    navigate('/admin/create-page', { state: { page_id: row.id } });
  }

  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this page!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          adminStore.deletePage(row.id, navigationCallBack)

        }
      });
  }

  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      width: '60px',
    },
    {
      name: 'Page Name',
      selector: row => row.page_name,
      sortable: true,

    },
    {
      name: 'Nav Tab Name',
      selector: row => row.tab_name,
      sortable: true,

    },
    {
      name: 'Order',
      selector: row => row.order,
      sortable: true,

    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,

    },
    {
      name: 'Creation Time',
      selector: row => moment(row.created_at).format('DD/MM/YYYY hh:mm A'),
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div className="d-flex align-items-center">
          <button className="btn btn-sm btn-outline-success me-2" onClick={() => openModal(row)}><i className="far fa-eye"></i></button>
          <button className="btn btn-sm btn-outline-primary me-2" onClick={() => handleEdit(row)}><i className="far fa-edit"></i></button>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleDelete(row)}><i className="far fa-trash-alt"></i></button>
        </div>
      ),
    },
  ];
  const openModal = (data) => {
    setShowModal(true);
    setModalData(data)
  };
  const onClose = () => {
    setShowModal(false);
    setModalData()
  }
 
  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="content-wrapper mt-3 p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Page Records
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a href="/admin/create-page" className="page-title signup-link fs-4 pointer">
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Pages
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={pageList}/>
        </main>
      </div>
      {showModal && (
        <PageViewModal
          data={modalData}
          footer={
            <div className="d-flex align-items-center">
              <CopyToClipboard onCopy={() => onCopy()} text={modalData?.page_data}>
                <button className="btn  btn-outline-primary me-2"><i className="far fa-copy"></i></button>
              </CopyToClipboard>
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          }
          onClose={onClose}
        />
      )}
      {<Spinner loading={adminStore.loading}/>}
    </div>

  );
});

export default PagesList;
