import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toJS } from "mobx";
import Spinner from "../../../elements/Spinner";

const UploadMedia = observer(() => {
  const navigate = useNavigate();
  const [mediaFiles, setmediaFiles] = useState();
  const location = useLocation();

  useEffect(() => {
    setmediaFiles(toJS(adminStore.data.mediaFiles?.find((x) => x.id === location?.state?.id)));
  }, [location?.state?.mediaFiles]);

  const validationSchema = Yup.object({
    // title: Yup.string().required('Required'),
    files: Yup.mixed().required("Required"),
    status: Yup.boolean().required("Required"),
  });

  const addMediaFiles = (values) => {
    const formData = new FormData();
    formData.append("delete_ids", values.delete_ids);
    values.files.forEach((file, index) => {
      formData.append(`files`, file.filePath);
      formData.append(`titles[]`, file.title);
    });

    adminStore.addMediaFiles(formData, navigationCallBack);
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Added", "success");
    navigate("/admin/mediafile");
  };

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">Upload Media Files</h3>
            <Formik
              initialValues={{
                id: mediaFiles?.id,
                delete_ids: [],
                files: mediaFiles?.files || [{ title: "", filePath: "" }],
                status: true,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addMediaFiles(values);
              }}
            >
              {({ values, handleChange, setFieldValue }) => (
                  <Form>
                  {/* {console.log(values.delete_ids,"delete_ids")} */}
                  <div className="row mb-3">
                    <FieldArray
                      name="files"
                      render={(arrayHelpers) => (
                        <div>
                          <div className="d-flex flex-wrap">
                            {values.files.map((file, index) => (
                              <div key={index} className={values.delete_ids.includes(file.id)? "form-group card deleted-card":"form-group card"}>
                                <i className="fas fa-undo-alt undo-icon" style={{filter:"none", pointerEvents:"none"}}></i>

                                {/* {console.log(image,"image")} */}
                                <label htmlFor={`files.${index}.filePath`}>
                                  Select File
                                </label>
                                <input
                                  id={`files.${index}.filePath`}
                                  name={`files.${index}.filePath`}
                                  type="file"
                                  className="form-control"
                                  onChange={(event) => {
                                    const file = event.target.files[0];
                                    const fileSizeLimit = 50 * 1024 * 1024; // 50MB
                                    if (file.size <= fileSizeLimit) {
                                      setFieldValue(
                                        `files.${index}.filePath`,
                                        file
                                      );
                                    } else {
                                      toast.warn("File size exceeds 50MB limit");
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name={`files.${index}.filePath`}
                                  component="div"
                                  className="text-danger"
                                />
                                <label htmlFor={`files.${index}.title`}>
                                  Title
                                </label>
                                <input
                                  id={`files.${index}.title`}
                                  name={`files.${index}.title`}
                                  type="text"
                                  value={values.files[index].title}
                                  className="form-control"
                                  onChange={(event) => {
                                    setFieldValue(
                                      `files.${index}.title`,
                                      event.target.value
                                    );
                                  }}
                                />
                                {/* {console.log(values.files[index].title)} */}
                                <ErrorMessage
                                  name={`files.${index}.title`}
                                  component="div"
                                  className="text-danger"
                                />
                                <button
                                  disabled={values.files.length === 1}
                                  type="button"
                                  className="btn btn-danger mt-3"
                                  onClick={() => {

                                    if (values.files[index].id) {
                                      setFieldValue('delete_ids', [values.files[index].id, ...values.delete_ids]);
                                    }else{
                                    arrayHelpers.remove(index);
                                  }
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                              
                            ))}
                          </div>
                          <button
                            type="button"
                            className="btn btn-success float-button "
                            onClick={() =>
                              arrayHelpers.push({
                                title: "",
                                order: values.files?.length + 1,
                                filePath: "",
                              })
                            }
                          >
                            <i className="fas fa-plus pe-3"></i>
                            Add Image
                          </button>
                        </div>
                      )}
                    />
                  </div>

                  {/* <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group"> */}
                        {/* <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                    modules={modules}
                    formats={formats}
                  /> */}
                        {/* <ErrorMessage
                          name="content"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default UploadMedia;
