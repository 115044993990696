import { observer } from 'mobx-react';
import websiteStore from '../store/WebsiteStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const IqacHeader = observer(() => {
  const navigate = useNavigate();

  const [aboutIqacPage, setAboutIqacPage] = useState([]);
  const [committeeSubPage, setCommitteeSubPage] = useState([]);
  const [iqacActivity, setIqacActivity] = useState([]);
  const [iqacMeeting, setIqacMeeting] = useState([]);
  const [iqacAcademy, setIqacAcademy] = useState([]);
  const [iqacNaac, setIqacNaac] = useState([]);
  const [navbarOpen, setNavbarOpen] = useState(false);

  useEffect(() => {
    setAboutIqacPage(websiteStore.data.deptPages?.filter(x => x.tab_name === 'about'));
    setCommitteeSubPage(websiteStore.data.deptPages?.filter(x => x.tab_name === 'Committee'));
    setIqacActivity(websiteStore.data.deptPages?.filter(x => x.tab_name === 'Activities'));
    setIqacMeeting(websiteStore.data.deptPages?.filter(x => x.tab_name === 'Meetings And Minutes'));
    setIqacAcademy(websiteStore.data.deptPages?.filter(x => x.tab_name === 'Academic And Administrative Audit'));
    setIqacNaac(websiteStore.data.deptPages?.filter(x => x.tab_name === 'NAAC'));
  }, [websiteStore.data.deptPages]);

  const loadSelectedData = (item) => {
    websiteStore.data.selectedDeptSubTab = item;
    localStorage.setItem("selected_tab", JSON.stringify(item))
    navigate("/iqac-sub-pages");
    setNavbarOpen(false); // Close the navbar after navigation
  };

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <div>
      <section className="innerbanner"></section>
      <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "white", marginTop: -70 }}>
        <div className="container-fluid">
          {/* Mobile navigation button */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarSupportedContent"
            aria-expanded={navbarOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Your logo or brand */}
          <div className={`collapse navbar-collapse ${navbarOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/IQAC">
                  Home
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button">
                  About Iqac
                </a>
                {aboutIqacPage?.length > 0 && (
                  <ul className="dropdown-menu">
                    {aboutIqacPage?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button">
                  Committee
                </a>
                {committeeSubPage?.length > 0 && (
                  <ul className="dropdown-menu">
                    {committeeSubPage?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button">
                  Activity
                </a>
                {iqacActivity?.length > 0 && (
                  <ul className="dropdown-menu">
                    {iqacActivity?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button">
                  Meeting And Minutes
                </a>
                {iqacMeeting?.length > 0 && (
                  <ul className="dropdown-menu">
                    {iqacMeeting?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button">
                  Academic And Administrative Audit
                </a>
                {iqacAcademy?.length > 0 && (
                  <ul className="dropdown-menu">
                    {iqacAcademy?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button">
                  NAAC
                </a>
                {iqacNaac?.length > 0 && (
                  <ul className="dropdown-menu">
                    {iqacNaac?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
});

export default IqacHeader;
