import React from "react";

const PageViewModal = ({ data, footer, onClose }) => {
    return (
        <div
            className="modal modal-lg show d-block"
            tabIndex="-1"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header justify-content-between">
                        <h5 className="modal-title text-uppercase">
                            {data?.page_name}
                        </h5>
                        <button
                            type="button"
                            className="btn fs-3 p-0 m-0"
                            data-dismiss="modal"
                            onClick={onClose} // Add the onClick handler here
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {data?.page_data ? <div dangerouslySetInnerHTML={{ __html: data?.page_data }}></div> : <center>No Page Data Found!</center>}
                    </div>
                    <div className="modal-footer">{footer}</div>
                </div>
            </div>
        </div>
    );
};

export default PageViewModal;
