// src/pages/Home.js
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { observer } from "mobx-react";
import websiteStore from '../../store/WebsiteStore';
import { toJS } from 'mobx';
import { BASE_FILE_URL } from '../../api/config';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../elements/Spinner';
const Department = observer(() => {
    const navigate = useNavigate()
    const [departmentData, setDepartmentData] = useState([]);

    useEffect(() => {
        websiteStore.getDeparments()
    }, []);

    useEffect(() => {
        setDepartmentData(toJS(websiteStore.data.departments)?.filter(x=>x.id != '7' && x.id != '8'))
    }, [websiteStore.data.departments]);

    const gotoPage = (id) => {
        navigate(`/department-home?department_id=${id}`)
       localStorage.setItem("tempPath",`/department-home?department_id=${id}`)
    }

    return (
        <div>
            <Header />
            <section className="innerbanner" />
            <div className="row0">
                <div className="row111 w-auto">
                    <h3 className="main-head">
                        <h1>Department</h1>
                    </h3>
                    <div className="content">
                        <div className="comm">
                            <div className="row row-cols-1 row-cols-md-3 g-5">
                                {departmentData?.length > 0 ?
                                    departmentData?.map((depart, ind) => {
                                        return <div key={ind + "deprt"} className="card card-x shadow p-3 mb-5 bg-white rounded">
                                            <img
                                                src={BASE_FILE_URL + depart?.thumbnail_image}
                                                className=""
                                                width={"100%"}
                                                height={300}
                                                alt={depart?.thumbnail_image}
                                            />
                                            <div className="card-body" style={{ height: "150px" }}>
                                                <h5 className="card-title">
                                                    {depart?.department_name}
                                                </h5>
                                                <div className="mt-4">
                                                    <a className='button-arrow pointer' onClick={() => gotoPage(depart.id)}>Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    }) : "No Department Found!"}

                                {/* <div className="col">
                                    <div className="card card1">
                                        <img
                                            src="image/Students GRC.jpeg"
                                            className="card-img-top"
                                            alt="Department"
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                Department of Commerce &amp; Financial Studies
                                            </h5>
                                            <button type="button" className="btn btn-success btn-sm">
                                                <a href="/CfsHome">read more</a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card card1">
                                        <img
                                            src="image/Academic Activity cell.jpeg"
                                            className="card-img-top"
                                            alt="Department"
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                Department of Food Processing &amp; Technology
                                            </h5>
                                            <button type="button" className="btn btn-success btn-sm">
                                                <a href="/FtpHome">read more</a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card card1">
                                        <img
                                            src="image/Research Project Pic.jpeg"
                                            className="card-img-top"
                                            alt="Department"
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                Department of Hotel Management &amp; Hospitality
                                            </h5>
                                            <button type="button" className="btn btn-success btn-sm">
                                                <a href="/HmhHome">read more</a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card card1">
                                        <img
                                            src="image/Conference and Seminar.jpeg"
                                            className="card-img-top"
                                            alt="Department"
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                Department of Microbiology &amp; Bio-informatics
                                            </h5>
                                            <button type="button" className="btn btn-success btn-sm">
                                                <a href="/mbi-home">read more</a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card card1">
                                        <img
                                            src="image/Equal Opportunity cell.jpeg"
                                            className="card-img-top"
                                            alt="Department"
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">Department of Yoga Science</h5>
                                            <button type="button" className="btn btn-success btn-sm">
                                                <a href="/home-yoga">read more</a>
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {<Spinner loading={websiteStore.loading} />}
            <Footer />
        </div>
    );
}
);

export default Department;