// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Swal from "sweetalert";

import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import { toJS } from "mobx";
import moment from "moment";
import { BASE_FILE_URL } from "../../../api/config";
import Spinner from "../../../elements/Spinner";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";


const Notices = observer(() => {
  const [noticeData, setNoticeData] = useState([]);
  const [deptId, setDeptId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    adminStore.getNotifications();
    let userData = secureLocalStorage.getItem('adminData')
    if (userData) {
      setDeptId(JSON.parse(userData)?.dept_id)
    }
  }, [])
  useEffect(() => {
    if (!deptId) {
      setNoticeData(toJS(adminStore.data.notifications));
    } else {
      setNoticeData(toJS(adminStore.data.notifications)?.filter(x => x.dept_id == deptId))
    }
  }, [adminStore.data.notifications, deptId])

  const handleEdit = (row) => {

    navigate('/admin/upload-notice', { state: { id: row.id } });
  }
  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this page!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          adminStore.deleteNotification(row.id, navigationCallBack)

        }
      });
  }

  const navigationCallBack = () => {
    Swal("success", "successfully deleted", "success");
    adminStore.getNotifications();
  }

  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      width: "60px"
    },
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
      wrap: true,

    },
    {
      name: 'Notice Type',
      selector: row => row.notice_type,
      sortable: true,

    },
    {
      name: 'Department',
      selector: row => row.dept_name,
      sortable: true,
      width: "150px",
    },
    {
      name: 'URL',
      selector: row => <a href={row.url}
        target="_blank">{row.url} </a>,
      sortable: true,
    },
    {
      name: "File",
      selector: (row) => (
        <a
          href={BASE_FILE_URL + row.notification_file}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa-regular fa-folder-open pe-1"></i> {row?.notification_file?.substring(row?.notification_file.lastIndexOf('.') + 1)}
        </a>
      ),
      sortable: true,
    },
    {
      name: 'Notice Date',
      selector: row => moment(row.notice_date).format("DD/MM/YYYY hh:mm A"),
      sortable: true,
    },

    {
      name: 'Status',
      selector: row => row.status === true ? "Active" : "In-active",
      sortable: true,

    },
    {
      name: 'Action',
      cell: (row) => (
        <div className="d-flex align-items-center">
           <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={() => handleEdit(row)}
          >
            <i className="far fa-edit"></i>
          </button>
          {/* <button className="btn btn-sm btn-outline-primary me-2" onClick={() => handleEdit(row)}><i className="far fa-edit"></i></button> */}
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleDelete(row)}><i className="far fa-trash-alt"></i></button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div >
        <main className="main">
          <Header />
          <div className="content-wrapper mt-3 p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Notices
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a href="/admin/upload-notice" className="page-title signup-link fs-4 pointer">
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Notices
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={noticeData} />
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>

  );
});

export default Notices;
