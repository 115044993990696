import React from "react";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BASE_FILE_URL } from "../api/config";

const GalleryCarosel = ({ gallery, height, width }) => {
  return (
    
    <Carousel
      swipeable={true}
      emulateTouch={true}
      stopOnHover={true}
      showThumbs={true}
      interval="3000"
      transitionTime="300"
    >
      {gallery?.image_paths &&  JSON.parse(gallery?.image_paths)?.map((item, index) => {
        return (
          <div key={index}>
            <img
              height={height}
              width={width}
              src={BASE_FILE_URL + item}
              alt={item.alt}
            />



           
            {item.caption && <p className="legend">{item.caption}</p>}
          </div>
        );
      })}
    </Carousel>
  );
};

export default GalleryCarosel;
