import React from 'react'

const Hyperlinking=() =>{
  return (
    <div>
    <div className="row">
<div className="col text-center">
    <section>
        <h2>Introduction</h2>
        <p>This Hyperlinking Policy governs the process of linking to or from the website of Atal Bihari Vajpayee Vishwavidyalaya (ABVV).</p>
        
        <h2>Links to Other Websites</h2>
        <p>ABVV may provide links to other websites that are not under its control. These links are provided for convenience and informational purposes only. ABVV does not endorse the content of these external sites and is not responsible for their accuracy, legality, or any other aspect of their content.</p>
        
        <h2>Linking to ABVV Website</h2>
        <p>You are welcome to link to the ABVV website, provided that:</p>
        <ul>
            <li>Your website does not contain content that could be construed as distasteful, offensive, or controversial.</li>
            <li>Your website does not infringe any intellectual property rights or violate any laws.</li>
            <li>You do not imply any endorsement by ABVV where none exists.</li>
            <li>You do not misrepresent your relationship with ABVV.</li>
        </ul>
        
        <h2>Removal of Links</h2>
        <p>ABVV reserves the right to request the removal of any link to its website that it believes does not comply with this policy or is otherwise inappropriate.</p>
        
        <h2>Contact Us</h2>
        <p>If you have any questions about this Hyperlinking Policy, please contact us at <a href="mailto:info@abvv.ac.in">info@abvv.ac.in</a>.</p>
    </section>
 
</div>
</div>

</div>
  )
}

export default Hyperlinking