// src/pages/Home.js
import { observer } from 'mobx-react';
import websiteStore from '../store/WebsiteStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toJS } from 'mobx';

const DHeader = observer(() => {
  const navigate = useNavigate();
  const [peopleSubPages, setPeopleSubPages] = useState([]);
  const [programSubPages, setProgramSubPages] = useState([]);
  const [researchSubPages, setResearchSubPages] = useState([]);
  const [activitySubPages, setActivitySubPages] = useState([]);
  const [studentSubpages, setStudentSubpages] = useState([]);
  const [aluminiSubpages, setAluminiSubpages] = useState([]);
  const [mediaSubPages, setMediaSubPages] = useState([]);
  const [learningSubPages, setLearningSubPages] = useState([]);
  const [facilitySubPages, setFacilitySubPages] = useState([]);
  const [placementSubPages, setPlacementSubPages] = useState([]);
  const [downloadSubPages, setDownloadSubPages] = useState([]);

  useEffect(() => {
    if (toJS(websiteStore.data.deptPages)?.length === 0) {
      navigate(localStorage.getItem('tempPath'))
    }
  }, [websiteStore.data.deptPages]);
  useEffect(() => {
    setPeopleSubPages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'people'));
    setProgramSubPages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'program'));
    setResearchSubPages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'research'));
    setActivitySubPages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'activity'));
    setStudentSubpages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'student'));
    setAluminiSubpages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'alumini'));
    setMediaSubPages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'media'));
    setLearningSubPages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'learning-material'));
    setFacilitySubPages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'facility'));
    setPlacementSubPages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'placement'));
    setDownloadSubPages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'download'));
  }, [websiteStore.data.deptPages]);

  const loadSelectedData = (item) => {
    websiteStore.data.selectedDeptSubTab = item;
    navigate("/department-sub-pages");
    closeNavbar(); // Close navbar after navigation
  };

  const closeNavbar = () => {
    const navbar = document.getElementById('navbarSupportedContent');
    if (navbar.classList.contains('show')) {
      navbar.classList.remove('show');
    }
  };

  return (
    <div>
      <section className="innerbanner"></section>
      <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "white", marginTop: -70 }}>
        <div className="container-fluid">
          {/* Mobile navigation button */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Your logo or brand */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" href={localStorage.getItem("tempPath")}>
                  Home
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button">
                  People
                </a>
                {peopleSubPages?.length > 0 && (
                  <ul className="dropdown-menu">
                    {peopleSubPages?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => { loadSelectedData(programSubPages[0]); closeNavbar(); }}>
                  Program
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" >
                  Research
                </a>
                {researchSubPages?.length > 0 && (
                  <ul className="dropdown-menu">
                    {researchSubPages?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" >
                  Activity
                </a>
                {activitySubPages?.length > 0 && (
                  <ul className="dropdown-menu">
                    {activitySubPages?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => { loadSelectedData(facilitySubPages[0]); closeNavbar(); }}>
                  Facility
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => { loadSelectedData(placementSubPages[0]); closeNavbar(); }}>
                  Placement
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" >
                  Student
                </a>
                {studentSubpages?.length > 0 && (
                  <ul className="dropdown-menu">
                    {studentSubpages?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" >
                  Alumini
                </a>
                {aluminiSubpages?.length > 0 && (
                  <ul className="dropdown-menu">
                    {aluminiSubpages?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" >
                  Media
                </a>
                {mediaSubPages?.length > 0 && (
                  <ul className="dropdown-menu">
                    {mediaSubPages?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" href="/media">
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => { loadSelectedData(downloadSubPages[0]); closeNavbar(); }}>
                  Download
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" >
                  Learning Material
                </a>
                {learningSubPages?.length > 0 && (
                  <ul className="dropdown-menu">
                    {learningSubPages?.map((item, ind) => (
                      <li key={ind}>
                        <a className="dropdown-item py-2" onClick={() => loadSelectedData(item)}>
                          {item?.page_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact-us">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
});

export default DHeader;
