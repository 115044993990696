// src/components/Dashboard.js
import React from "react";
import SimpleChart from "./SimpleChart";
import ResultPerWeek from "./ResultPerWeek";

const Dashboard = () => {
  return (
    <div className="mt-3">
      <div className="row content-wrapper p-3 bg-white  d-flex justify-content-between">
        <h1
          style={{ width: "100%", marginLeft: "auto" }}
          className="d-flex gap-3"
        >
          <i class="fa-solid fa-user-tie"></i>Admin Dashboard
        </h1>
        <div className="col-md-6">
          <SimpleChart />
        </div>
        <div className="col-md-6">
          <ResultPerWeek />
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
