// src/pages/Home.js
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { observer } from "mobx-react";
import websiteStore from '../../store/WebsiteStore';
import { toJS } from 'mobx';

const QuickLink = observer(() => {
  const [quickSubPage, setQuickSubPage] = useState([]);

  const [selectorTab, setSelectorTab] = useState(); 

  useEffect(() => {
    setQuickSubPage(websiteStore.data.pages?.filter(x => x.tab_name === 'quick-links'))
  }, [websiteStore.data.pages]);
  
  useEffect(() => {
    setSelectorTab(websiteStore.data.selectedSubTab)
  }, [websiteStore.data.selectedSubTab]);

  return (
    <div>
      <Header />
      <section className="innerbanner" />
      <div style={{ width: "100%" }}>
        <div className="container-fluid">
          <div className="row2">
            <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
              <div className=" d-flex flex-column align-items-start  px-3 pt-2 text-white">
                <h3 className="main-head">Quick Link</h3>
                {quickSubPage?.length > 0 && <ul
                  className="nav  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
                  id="menu"
                >
                  {quickSubPage?.map((item, ind) => {
                    return (

                      <li key={(ind)} className="w-100">
                        <a
                          onClick={() => setSelectorTab(item)}

                          className={selectorTab?.id === item.id ? "active-tab nav-link align-middle px-0" : "nav-link align-middle px-0"}

                        >
                          <i className="fs-4 bi-house" />{" "}
                          <span
                            className="ms-1  d-sm-inline gd active-text"
                          >
                            {item?.page_name}
                          </span>
                        </a>
                      </li>
                    )
                  })}


                </ul>}
                <hr />
              </div>
            </div>
            {<div className="row1 mx-auto">
              <h3 className="main-head">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                </svg>
                {selectorTab?.page_name}
              </h3>
              <div>
                <div dangerouslySetInnerHTML={{ __html: selectorTab?.page_data }} />
              </div>
            </div>}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
);

export default QuickLink;