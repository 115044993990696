import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert";
import adminStore from "../../../store/AdminStore";
import Spinner from "../../../elements/Spinner";
import { toJS } from "mobx";

const AddCourse = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [addCourse, setAddCourse] = useState("");
  const [syllabusID, setSyllabusID] = useState([]);

  useEffect(() => {
    setSyllabusID(toJS(adminStore.data.syllabus));
  }, [adminStore.data.syllabus]);

  useEffect(()=>{
    adminStore.getSyllabus();
  },[])
  useEffect(() => {
    setAddCourse(
      adminStore.data.courses?.find((x) => x.id === location?.state?.id)
    );
  }, [location?.state?.id]);

  const addNewCourse = (values) => {
    if (!values.id) {
      let param = {
        course_name: values.course_name,
        syllabus_id: values.syllabus_id,
        status: values.status,
      };
      adminStore.addCourse(param, navigationCallBack);
    } else {
      let param = {
        id: values.id,
        course_name: values.course_name,
        syllabus_id: values.syllabus_id,
        status: values.status,
      };
      adminStore.updateCourse(param, navigationCallBackUpdate);
    }
  };

  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/course");
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Added", "success");
    navigate("/admin/course");
  };

  const validationSchema = Yup.object({
    course_name: Yup.string().required("Required"),
    syllabus_id: Yup.string().required("Required"),
    status: Yup.boolean().required("Required"),
  });

  console.log(syllabusID,"syllabusID");

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">Add New Course</h3>
            <Formik
              initialValues={{
                id: addCourse?.id,
                course_name: addCourse?.course_name || "",
                syllabus_id: addCourse?.syllabus_id || "",
                status: addCourse?.status || true,
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addNewCourse(values);
              }}
            >
              {({ handleChange }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="course_name"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="course_name">Course Name</label>
                        <ErrorMessage
                          name="course_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="syllabus_id"
                          className="form-select"
                          placeholder=" "
                          onChange={handleChange}
                        >
                          <option value="">Select Syllabus ID</option>
                          {syllabusID.map((syllabus) => (
                            <option key={syllabus.id} value={syllabus.id}>
                              {syllabus.syllabus_title}
                            </option>
                          ))}
                        </Field>
                        <label htmlFor="syllabus_id">Syllabus ID</label>
                        <ErrorMessage
                          name="syllabus_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="status"
                          className="form-select"
                          placeholder=" "
                          onChange={handleChange}
                        >
                          <option value="">Not Set</option>
                          <option value={true}>Active</option>
                          <option value={false}>In-active</option>
                        </Field>
                        <label htmlFor="status">Status</label>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>
  );
});

export default AddCourse;
