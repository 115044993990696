// src/pages/DashboardPage.js
import React from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import adminStore from "../../../store/AdminStore";
import Spinner from "../../../elements/Spinner";


const FeedbackFromStudent = () => {
  const handleEdit = () => { };
  const handleDelete = () => { };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Contact no.",
      selector: (row) => row.contact_no,
      sortable: true,
    },
    {
      name: "Email Id",
      selector: (row) => row.email_id,
      sortable: true,
    },
    {
      name: "Class Now",
      selector: (row) => row.class_now,
      sortable: true,
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "Session",
      selector: (row) => row.session,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={() => handleEdit(row)}
          >
            <i className="far fa-edit"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDelete(row)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const data = [
    {
      id: 1,
      name: "AboutUs",
      contact_no: "",
      email_id: "",
      class_now: "",
      subject: "",
      session: "",
      created_at: "1988",
    },
    {
      id: 2,
      name: "Home",
      contact_no: "",
      email_id: "",
      class_now: "",
      subject: "",
      session: "",
      created_at: "1989",
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div >
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Feedback From Student
            </h3>
          </div>
          <CustomDataTable columns={columns} data={data} config={config} />
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
};

export default FeedbackFromStudent;
