import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { BASE_FILE_URL } from "../../../api/config";
import Spinner from "../../../elements/Spinner";


const AddURL = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [urlData, setAddURL] = useState({});

  useEffect(() => {
    if (location?.state?.id) {
      const foundUrl = adminStore.data.impwebData.find((x) => x.id === location?.state?.id);
      if (foundUrl) {
        setAddURL(foundUrl);
      }
    }
  }, [location?.state?.id]);

  const addURL = (values) => {
    const formData = new FormData()
    formData.append('impweb_title', values.impweb_title)
    formData.append('impweb_url', values.impweb_url)
    formData.append('thumbnail_img', values.thumbnail_img)
    formData.append('status', values.status)
    
    adminStore.addURL(formData, navigationCallBack);

    console.log(values);

    // if (!values.id) {
    //   adminStore.addURL(values, navigationCallBack);
    // } else {
    //   formData.append("id", values.id);
    //   // adminStore.updateAddURL(values.id, navigationCallBackUpdate);
    // }
  };


  // const navigationCallBackUpdate = () => {
  //   Swal("Success!", "Successfully Updated", "success");
  //   navigate("/admin/impwebsite");
  // };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Added", "success");
    navigate("/admin/impwebsite");
  };

  const validationSchema = Yup.object({
    impweb_title: Yup.string().required("Required"),
    impweb_url: Yup.string().required("Required"),
    thumbnail_img: Yup.mixed().required("Required"),
    status: Yup.boolean().required("Required"),
  });


  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">Add Url </h3>
            <Formik
              initialValues={{
                id: urlData.id,
                impweb_title: urlData.impweb_title || "",
                impweb_url: urlData.impweb_url || "",
                thumbnail_img: urlData.thumbnail_img || "",
                status: urlData.status || true,
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addURL(values);
              }}
            >
              {({ setFieldValue, handleChange, values, errors }) => (
                <Form>
                  {console.log(errors)}
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="impweb_title"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="impweb_title">Title</label>
                        <ErrorMessage
                          name="impweb_title"
                          component="div"
                          className="text-danger"


                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="url"
                          name="impweb_url"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="impweb_url">URL</label>
                        <ErrorMessage
                          name="impweb_url"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="status"
                          className="form-select"
                          placeholder=" "
                          onChange={handleChange}
                        >
                          <option value="">Not Set</option>
                          <option value={true}>Active</option>
                          <option value={false}>In-active</option>
                        </Field>
                        <label htmlFor="status">Status</label>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="thumbnail_img">Image File</label>
                        <input
                          multiple
                          id="thumbnail_img"
                          name="thumbnail_img"
                          type="file"
                          className="form-control"
                          fileName={values.image || ""}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file.size <= fileSizeLimit) {
                              setFieldValue("thumbnail_img", file);
                            } else {
                              alert("File size exceeds 2MB limit");
                            }
                          }}
                        />
                        <a href={BASE_FILE_URL + values?.thumbnail_img} target="_blank" >View</a>
                        <ErrorMessage
                          name="thumbnail_img"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default AddURL;