import React from 'react'

const PageNotFound=()=> {
  return (
    <div
  style={{
    backgroundImage: "url(/image/image.jpg)",
    backgroundSize: "cover",
    padding: 50,
    textAlign: "center",
    height: "100vh"
  }}
>
  <div className="book card">
    <div>
      <img
        src="/image/—Pngtree—open book decoration illustration_4603005.png"
        style={{ width: 300, height: 200 }}
        alt=""
      />
    </div>
    <h1 style={{ fontSize: 50, marginBottom: 10 }}>404</h1>
    <p style={{ fontSize: 20, marginBottom: 20 }}>
      Sorry, the page you are looking for is missing from our library.
    </p>
    <p>
      <a style={{color:'blue'}} href="/">Return to the Homepage</a>
    </p>
  </div>
</div>

  )
}

export default PageNotFound