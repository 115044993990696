// Spinner.js
import React from 'react';
import { MutatingDots } from 'react-loader-spinner';

const Spinner = ({ loading }) => (
    <>
        {loading && <div className="spinner">
            <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#0d6efd"
                secondaryColor="#0d6efd"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>}
    </>
);

export default Spinner;
