import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import Spinner from "../../../elements/Spinner";


const AddImages = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [gallery, setGallery] = useState();
  
  useEffect(() => {
    setGallery(
      adminStore.data.galleries?.find((x) => x.id === location?.state?.id)
    );
  }, [location?.state?.id]);

  const addNewGallery = (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    values.images?.forEach((file) => {
      formData.append("images", file);
    });
    
    formData.append("place", values.place);
    formData.append("video_url", values.video_url);
    formData.append("status", values.status);

    console.log(formData);

    adminStore.addNewGallery(formData, navigationCallBack);
  };
  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    // description: Yup.string().required("Required"),
    

   
    status: Yup.boolean().required("Required"),
  });
  const navigationCallBack = () => {
    Swal("success", "successfully Uploded", "success");
    navigate("/admin/PhotoGallery");
  };

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />

      <div>
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">Upload Images/Video</h3>
            <Formik
              initialValues={{
                id:gallery?.id,
                title: gallery?.title || "",
                description: gallery?.description || "",
                images: [],
                place: gallery?.place || "",
                video_url: gallery?.video_url || "",
                status: true,
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addNewGallery(values);
              }}
            >
              {({ setFieldValue, handleChange, values }) => (
                <Form>
                  {console.log(values)}
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="title"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="title">Title</label>
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="description"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="description">Description</label>
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                  <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="place"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="place">Place</label>
                        <ErrorMessage
                          name="place"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        
                        <label htmlFor="images">Select Images</label>
                        <input
                          multiple
                          id="images"
                          name="images"
                          type="file"
                          accept="image/png"
                          className="form-control"
                          onChange={(event) => {
                            const selectedFiles = event.target.files;
                            setFieldValue('images', [...values.images, ...selectedFiles]);
                          }}
                        />
                        <ErrorMessage
                          name="images"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="video_url"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="video_url">Video Url</label>
                        <ErrorMessage
                          name="video_url"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="status"
                          className="form-select"
                          autoComplete="off"
                          placeholder=" "
                          onChange={handleChange}
                        >
                          <option value="">Select</option>
                          <option value={true}>Active</option>
                          <option value={false}>In-active</option>
                        </Field>
                        <label htmlFor="status">Status</label>
                        <ErrorMessage
                          name="dept_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        {/* <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                    modules={modules}
                    formats={formats}
                  /> */}
                        <ErrorMessage
                          name="content"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default AddImages;
