import React, { useEffect, useRef, useState } from "react";
import NewsTicker from "react-advanced-news-ticker";
import "font-awesome/css/font-awesome.min.css"; // Import Font Awesome CSS
import moment from "moment";
import { BASE_FILE_URL } from "../api/config";
const ResultsNewsTickerComponentt = ({ results }) => {
  const tickerRef = useRef(null);
  const [key, setKey] = useState(2);

  useEffect(() => {
    // Update key to force re-render when newsData changes
    setKey((prevKey) => prevKey + 1);
  }, [results]);

  const handleMovePrev = () => {
    if (tickerRef.current) {
      tickerRef.current.movePrev();
      tickerRef.current.resetInterval();
    }
  };

  const handleMoveNext = () => {
    if (tickerRef.current) {
      tickerRef.current.moveNext();
      tickerRef.current.resetInterval();
    }
  };
  return (
    <div className="news-ticker-container">
      <i
        className="fa fa-arrow-up news-ticker-control pointer"
        onClick={handleMovePrev}
      />
      <div className="news-ticker-wrapper">
        <NewsTicker
          key={key}
          ref={tickerRef}
          rowHeight={30}
          maxRows={10}
          speed={600}
          duration={4000}
          autoStart={true}
          pauseOnHover={true}
          style={{ marginTop: 5 }}
        >
          {results?.length > 0 ? (
            results?.map((news, ind) => (
              <div className="news-item" key={ind + "news"}>
                {/* <div>{news?.course_name}</div>
                            <div>{news?.result_date}</div> */}
                <div>
                <a target="blank" href={BASE_FILE_URL + news.notification_file}>
                  <b className="" style={{color:"#030899"}}>
                    {moment(news.result_date).format("DD/MM/YYYY")}
                  </b>{" "}
                  <div style={{color:"black"}}>{news.course_name}</div>
                </a>
                </div>
              </div>
            ))
          ) : (
            <span>No New Result!</span>
          )}
        </NewsTicker>
      </div>
      <i
        className="fa fa-arrow-down news-ticker-control pointer"
        onClick={handleMoveNext}
      />
    </div>
  );
};

export default ResultsNewsTickerComponentt;
