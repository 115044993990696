import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { validationSchemaUploadResult } from "../../../utils/constentFunc";
import { useLocation, useNavigate } from "react-router-dom";
import { values } from "mobx";
import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import Spinner from "../../../elements/Spinner";

const UploadResult = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [result, setResult] = useState(null);

  useEffect(() => {
    setResult(
      adminStore.data.results?.find((x) => x.id === location?.state?.id)
    );
  }, [location?.state?.id]);

  const addResult = (values) => {
    const formData = new FormData();
    formData.append("course_name", values.course_name);
    formData.append("course_type", values.course_type);
    formData.append("result_date", values.result_date);
    formData.append("status", values.status);
    formData.append("file_type", values.file_type);
    formData.append("notification_file", values.notification_file);
    formData.append("result_link", values.result_link);
    formData.append("reval_retot_link", values.reval_retot_link);
    formData.append("show_in_homepage", values.show_in_homepage);
    if (!values.id) {
      adminStore.addResult(formData, navigationCallBack);
    } else {
      adminStore.updateResult(values, navigationCallBackUpdate);
    }
    console.log(values);
  };

  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/results");
  };

  const navigationCallBack = () => {
    Swal("success", "Successfully Added", "success");
    navigate("/admin/results");
  };

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white mt-3">
            <h3 className="text-center mb-4">Upload Result</h3>
            <Formik
              initialValues={{
                id: result?.id,
                course_name: result?.course_name || "",
                course_type: result?.course_type || "",
                result_date: result?.result_date || "",
                status: result?.status || true,
                file_type: result?.file_type || "",
                notification_file: [],
                result_link: result?.result_link || "",
                reval_retot_link: result?.reval_retot_link || "",
                show_in_homepage: false,
              }}
              enableReinitialize
              validationSchema={validationSchemaUploadResult}
              onSubmit={(values) => {
                addResult(values);
              }}
            >
              {({ setFieldValue, handleChange, values }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="course_name"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />

                        <label htmlFor="course_name">Course Name</label>
                        <ErrorMessage
                          name="course_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="course_type"
                          className="form-select"
                          placeholder=" "
                          onChange={handleChange}
                        >
                          <option value="">Select course type</option>
                          <option value="undergraduate">Undergraduate</option>
                          <option value="postgraduate">Postgraduate</option>
                          <option value="doctorate">Doctorate</option>
                        </Field>
                        <label htmlFor="course_type">Course Type</label>
                        <ErrorMessage
                          name="course_type"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="date"
                          name="result_date"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="result_date">Result Date</label>
                        <ErrorMessage
                          name="result_date"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="status"
                          className="form-select"
                          placeholder=" "
                          onChange={handleChange}
                        >
                          <option value="">Select status</option>
                          <option value="draft">Draft</option>
                          <option value="published">Published</option>
                          <option value="archived">Archived</option>
                        </Field>
                        <label htmlFor="status">Status</label>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="file_type"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="file_type">File Type</label>
                        <ErrorMessage
                          name="file_type"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="notification_file">
                          Notification File
                        </label>
                        <input
                          id="notification_file"
                          name="notification_file"
                          type="file"
                          className="form-control"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file.size <= fileSizeLimit) {
                              setFieldValue("notification_file", file);
                            } else {
                              alert("File size exceeds 2MB limit");
                            }
                          }}
                        />
                        <ErrorMessage
                          name="notification_file"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="url"
                          name="result_link"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="result_link">Result Link</label>
                        <ErrorMessage
                          name="result_link"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="url"
                          name="reval_retot_link"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="reval_retot_link">
                          Revaluation/Retotaling Link
                        </label>
                        <ErrorMessage
                          name="reval_retot_link"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        {/* <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                    modules={modules}
                    formats={formats}
                  /> */}
                        <ErrorMessage
                          name="content"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-check mb-3">
                        <Field
                          type="checkbox"
                          name="show_in_homepage"
                          className="form-check-input"
                          id="show_in_homepage"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="show_in_homepage"
                        >
                          Show in Homepage
                        </label>
                        <ErrorMessage
                          name="show_in_homepage"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>
  );
});

export default UploadResult;
