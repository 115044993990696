import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { observer } from "mobx-react";
import websiteStore from "../../store/WebsiteStore";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../api/config";
import moment from "moment";

const AboutAbvv = observer(() => {
  const [aboutSubPages, setAboutSubPages] = useState([]);
  const [recognition, setRecognition] = useState([]);
  const [selectorTab, setSelectorTab] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const [filterSubject, setFilterSubject] = useState("");
  const [filterMonth, setFilterMonth] = useState(0);
  const [filterYear, setFilterYear] = useState(0);

  const [filterTabName, setFilterTabName] = useState(""); // New state for tab name filter

  useEffect(() => {
    setRecognition(toJS(websiteStore.data.notifications) || []);
  }, [websiteStore.data.notifications]);

  useEffect(() => {
    const sortedSubPages = toJS(
      websiteStore.data.pages?.filter((x) => x.tab_name === "about-us")
    )?.sort((a, b) => a.order - b.order);
    setAboutSubPages(sortedSubPages);
    if (
      websiteStore.data.pages?.length !== 0 &&
      !websiteStore.data.selectedSubTab
    ) {
      websiteStore.data.selectedSubTab = websiteStore.data.pages?.filter(
        (x) => x.tab_name === "about-us"
      )[0];
    }
  }, [websiteStore.data.pages]);

  useEffect(() => {
    setSelectorTab(websiteStore.data.selectedSubTab);
  }, [websiteStore.data.selectedSubTab]);

  const handleTabClick = (item) => {
    setSelectorTab(item);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "subject") setFilterSubject(value);
    if (name === "month") setFilterMonth(parseInt(value, 10));
    if (name === "year") setFilterYear(parseInt(value, 10));
    if (name === "tabName") setFilterTabName(value); // Handle tab name filter change
  };

  const filteredRecognition = recognition
    .filter((item) => item.notice_type === "Recognition")
    .filter((item) => {
      const matchesSubject =
        filterSubject === "" ||
        item.title.toLowerCase().includes(filterSubject.toLowerCase());
      const matchesMonth =
        filterMonth === 0 || moment(item.notice_date).month() + 1 === filterMonth;
      const matchesYear =
        filterYear === 0 || moment(item.notice_date).year() === filterYear;

      return matchesSubject && matchesMonth && matchesYear;
    });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredRecognition.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredRecognition.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  console.log(aboutSubPages, "aboutSubPages");

  return (
    <div>
      <Header />
      <section className="innerbanner" />
      <div style={{ width: "100%" }}>
        <div className="container-fluid">
          <div className="row2">
            <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light">
              <div className="d-flex flex-column align-items-start px-3 pt-2 text-white">
                <h3 className="main-head w-100">About Us</h3>
                <div className="form-group mb-2 w-100 d-flex" style={{gap:"15px"}}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Filter"
                    value={filterTabName}
                    name="tabName"
                    
                    onChange={handleFilterChange}
                    
                    
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" style={{width:"20px", opacity:"0.5"}} viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                </div>
                {aboutSubPages?.length > 0 && (
                  <ul
                    className="nav mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
                    id="menu"
                  >
                    {aboutSubPages
                      .filter((item) =>
                        item.page_name
                          .toLowerCase()
                          .includes(filterTabName.toLowerCase())
                      )
                      .map((item, ind) => (
                        <li key={ind} className="w-100">
                          <a
                            onClick={() => handleTabClick(item)}
                            className={
                              selectorTab?.id === item.id
                                ? "active-tab nav-link align-middle px-2"
                                : "nav-link align-middle px-2"
                            }
                          >
                            <i className="fs-4 bi-house" />{" "}
                            <span className="d-sm-inline gd active-text">
                              {item?.page_name}
                            </span>
                          </a>
                        </li>
                      ))}
                  </ul>
                )}
                <hr />
              </div>
            </div>
            <div className="row1 mx-auto">
              <h3 className="main-head">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                </svg>
                {selectorTab?.page_name}
              </h3>
              <div>
                {selectorTab?.page_name === "Recognition" ? (
                  <div>
                    <form className="table-responsive">
                      <table className="table table-primary">
                        <tbody>
                          <tr>
                            <td>
                              <div className="form-group">
                                <label htmlFor="pagesize">Display Per Page</label>{" "}
                                <select
                                  className="form-control"
                                  id="pagesize"
                                  name="pagesize"
                                  value={itemsPerPage}
                                  onChange={(e) => setItemsPerPage(Number(e.target.value))}
                                >
                                  {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100].map((size) => (
                                    <option key={size} value={size}>{size}</option>
                                  ))}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="subject">Subject</label>{" "}
                                <input
                                  className="form-control"
                                  value={filterSubject}
                                  id="subject"
                                  name="subject"
                                  placeholder="Subject"
                                  type="text"
                                  onChange={handleFilterChange}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="month">Month</label>{" "}
                                <select
                                  className="form-control"
                                  id="month"
                                  name="month"
                                  value={filterMonth}
                                  onChange={handleFilterChange}
                                >
                                  <option value={0}>All</option>
                                  {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, index) => (
                                    <option key={index} value={index + 1}>{month}</option>
                                  ))}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="year">Year</label>{" "}
                                <select
                                  className="form-control"
                                  id="year"
                                  name="year"
                                  value={filterYear}
                                  onChange={handleFilterChange}
                                >
                                  <option value={0}>All</option>
                                  {[2019, 2020, 2021, 2022, 2023, 2024].map((year) => (
                                    <option key={year} value={year}>{year}</option>
                                  ))}
                                </select>
                              </div>
                            </td>
                            <td className="align-middle">
                              <div className="form-group">
                                <button
                                  className="btn btn-primary mt-4"
                                  style={{
                                    color: "white",
                                    backgroundColor: "#0b559f",
                                  }}
                                  type="button"
                                  onClick={() => setCurrentPage(1)} // Reset to first page on filter change
                                >
                                  Search!
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                    <table
                      id="bt-table"
                      data-filter-control="true"
                      data-show-search-clear-button="true"
                      className="table table-hover table-striped"
                      style={{ marginTop: "20px" }}
                    >
                      <thead>
                        <tr className="table-primary col-md-1">
                          <th data-field="id">S No.</th>
                          <th data-field="subject" className="col-md-7" data-filter-control="input">Subject</th>
                          <th data-field="date" data-filter-control="date" className="col-md-2">Notice Date</th>
                          <th>View/Download</th>
                        </tr>
                      </thead>
                      <tbody id="body-table">
                        {currentItems.map((item, index) => (
                          <tr key={index}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>
                              <a
                                href={`${BASE_FILE_URL}${item.notification_file}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <b>{item.title}</b>
                              </a>
                            </td>
                            <td>
                              <b>{moment(item.notice_date).format("DD/MM/YYYY")}</b>
                            </td>
                            <td>
                              <a
                                href={`${BASE_FILE_URL}${item.notification_file}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button
                                  className="btn btn-primary w-80"
                                  style={{
                                    color: "white",
                                    backgroundColor: "#0b559f",
                                  }}
                                >
                                  View/Download
                                </button>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-center">
                        {Array.from({ length: totalPages }, (_, i) => (
                          <li key={i} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
                            <a onClick={() => paginate(i + 1)} className="page-link" href="#!" style={{ color: "white"}}>
                              {i + 1}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: selectorTab?.page_data }} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default AboutAbvv;
