import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import NewsTicker from "react-advanced-news-ticker";
const NewsTickerFlashComponent = ({ flashNews }) => {
  const tickerRef = useRef(null);
  const [key, setKey] = useState(2);

  useEffect(() => {
    // Update key to force re-render when newsData changes
    setKey((prevKey) => prevKey + 1);
  }, [flashNews]);

  
  return (
    <div id="nt-example2-container" className="margin-for-dept" style={{ marginLeft: 175, color: "red" }}>
      <NewsTicker
        key={key}
        ref={tickerRef}
        id="nt-news"
        rowHeight={60}
        maxRows={1}
        speed={200}
        duration={3000}
        hasMoved={() => {
        //   var index = ref.current.state.items[0].key;
        //   setCurrentItem(news[index]);
        }}
        paused={() => {
          document.querySelector("#nt-news li i")?.classList.remove("fa-play");
          document.querySelector("#nt-news li i")?.classList.add("fa-pause");
        }}
        unPaused={() => {
          document.querySelector("#nt-news li i")?.classList.remove("fa-pause");
          document.querySelector("#nt-news li i")?.classList.add("fa-play");
        }}
      >
        {flashNews?.length > 0 &&
          flashNews?.map((item, ind) => (
            <div key={ind} className="blink">
              <div>
                
                <i className="fa fa-fw state fa-play" />
                <i className="fas fa-arrow-circle-right "><a target="_blank" href={item.link} style={{color: "red"}} className="danger"> {item.subject}</a> </i>
                
              </div>
            </div>
          ))}
      </NewsTicker>
    </div>
  );
};

export default NewsTickerFlashComponent;
