import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import websiteStore from '../store/WebsiteStore';
import { toJS } from 'mobx';
import { BASE_FILE_URL } from '../api/config';

const LogoHeader = observer(() => {
    const [headerSettings, setheaderSettings] = useState([]);
   
    useEffect(()=>{
        setheaderSettings(websiteStore.data.settings?.length > 0 ? toJS(websiteStore.data.settings[0]):"")
    },[websiteStore.data.settings]);

    return (
        <section className="logo-section">
            {/* <div class="sidebar">*/}
            <div className="col1">
                {/* <ul class="left-nav"> */}
                <div className="row11">
                    <div className="logo-left">
                        <a href="/">
                            <img src="image/ABVV-NewLogo.png" alt="ABVV-NewLogo" />
                        </a>
                        <div className="heading">
                            <h1 className="mb-0">अटल बिहारी वाजपेयी विश्‍वविद्यालय, बिलासपुर (छ.ग)</h1>
                            <span className="hindi-span mont">
                                (छत्तीसगढ़ सरकार द्वारा अधिनियम 07/2012 द्वारा स्थापित पूर्ववर्ती
                                बिलासपुर विश्‍वविद्यालय)
                            </span>
                            <h2 className="mont mb-0 " style={{fontSize:25}}>
                                {headerSettings?.title}
                            </h2>
                            <span className="hindi-span mont">
                                (Formerly Bilaspur Vishwavidyalaya Established by Act No. 07/2012
                                by Chhattisgarh Government)
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row22">
                    <div className="logo-right">
                        <a href="#">
                            <img
                                className="pm-usha"
                                src="image/PM-UShA Logo-01.png"
                                alt="PM-UShA"
                            />
                        </a>
                        <div className="heading">
                            <img src="image/logo-hi.png" alt="" />
                        </div>
                        <a href="#">
                            <img src="image/AtalBihariVajpayee.png" alt="PM-UShA" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
})
export default LogoHeader;
