// src/pages/Home.js
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import NewsTicker from "react-advanced-news-ticker";
import DHeader from "../../../components/DHeader";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import websiteStore from "../../../store/WebsiteStore";
import { toJS } from "mobx";
// import CommonCarousel from '../../../components/CommonCarousel';
import { BASE_FILE_URL } from "../../../api/config";
import NewsTickerComponent from "../../../components/NewsTickerComponent";
import DeptCarousel from "../../../components/DeptCarousel";
import NewsTickerFlashComponent from "../../../components/NewsTickerFlashComponent";

const DepartmentHome = observer(() => {
  const location = useLocation();
  const [departmentData, setDepartmentData] = useState("");
  const [sliderData, setSliderData] = useState([]);
  const [flashData, setFlashData] = useState([]);

  const [departmentId, setDepartmentId] = useState(null);
  const [notificationsData, setNotificationsData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setDepartmentId(params.get("department_id"));
    if (websiteStore.data.departments?.length === 0) {
      websiteStore.getDeparments();
    }
    websiteStore.getDepartmentPages({ dept_id: params.get("department_id") });
  }, []);

  useEffect(() => {
    setNotificationsData(
      toJS(
        websiteStore.data.notifications?.filter(
          (x) => x.dept_id == departmentId
        )
      )
    );
  }, [websiteStore.data.notifications, departmentId]);

  useEffect(() => {
    setDepartmentData(
      websiteStore.data.departments?.find((x) => x.id == departmentId)
    );
  }, [websiteStore.data.departments, departmentId]);

  useEffect(() => {
    setFlashData(toJS(websiteStore.data.whatsnew));
  }, [websiteStore.data.whatsnew]);

  useEffect(() => {
    if (departmentData?.slider_id) {
      websiteStore.getSliderById(departmentData?.slider_id);
    }
  }, [departmentData]);

  useEffect(() => {
    let sliders = toJS(websiteStore.data.departmentSliders);
    sliders = sliders?.images?.map((slide) => {
      return {
        url: BASE_FILE_URL + slide?.image_path,
        alt: "Slide Image",
        caption: slide?.caption,
      };
    });
    setSliderData(sliders);
  }, [websiteStore.data.departmentSliders]);

  const goToDeptNotice = () => {
    navigate('/table-view', { state: { type: "DepartmentNotificatons", dept_id: departmentId } });
  };

  return (
    <div>
      <Header />
      <DHeader />
      <div
        className="container-fluid bg-white"
        style={{ display: "flex", gap: 9, width: "100%", paddingTop: 10 }}
      >
        <div className="carousel-slide col-md-9">
          <h3
            style={{
              backgroundColor: "white",
              position: "relative",
              borderBottom: "2px solid #03356E",
              padding: 10,
            }}
            className="text-primary-emphasis text-center d-flex justify-content-start gap-2"
          >
            {departmentData?.department_name}
          </h3>
          {/* <div className="p-2">
          <div className="w-100">
            <div className="col-md-12">
              <div id="latestnews">
                <div className="breaking-news-ticker bn-effect-scroll bn-direction-ltr">
                  <div className="bn-label" id="whatnew">
                    <span style={{color:"white"}}>What's New</span>&nbsp;{" "}
                  </div>
                  <div className=" ">
                    <NewsTickerFlashComponent flashNews={flashData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div> */}
          {sliderData && (
            <div>
              <DeptCarousel height={500} width={"100%"} images={sliderData} />
            </div>
          )}
          <div className="p-4">
            {departmentData?.about_content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: departmentData?.about_content,
                }}
              />
            )}
          </div>
        </div>
        <div className="sidesection col-md-3">
          <div className="panel panel-default boxed">
            <div className="panel-heading">
              <h3 className="text-primary-emphasis text-center d-flex justify-content-center gap-2">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width="24.5px"
                  fill="#03356E"
                >
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
                </svg>{" "}
                Notifications
              </h3>
            </div>
            <div className="panel-body p-2">
              <div className="row">
                <div className="col-sm-12">
                  <NewsTickerComponent newsData={notificationsData} />
                </div>
              </div>
            </div>
            <div className="panel-footer my-3">
              <a className="btn btn-infobtn btn-info" role="button" onClick={() => goToDeptNotice()}>
                View All Notices
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default DepartmentHome;
