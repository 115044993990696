// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../../api/config";
import Spinner from "../../../elements/Spinner";


const ImpWebsite = observer(() => {
  const [impwebsite, setImpWebsite] = useState([]);
  const navigate = useNavigate()
  
  useEffect(() => {
    adminStore.getImpWebsite();
  }, []);

  useEffect(() => {
    setImpWebsite(toJS(adminStore.data.impwebData));
  }, [adminStore.data.impwebData]);

  const handleEdit = (row) => {
    navigate("/admin/add-URL", { state: { id: row.id } });

  };

  console.log(impwebsite,"impwebsite");
 
  const handleDelete = (row) => { 
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imp website!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          adminStore.deleteAddURL(row.id, navigationCallBack)
        }
      });
  };

  const navigationCallBack = () => {
    Swal("success", "successfully deleted", "success");
    adminStore.getImpWebsite();

  }
  console.log(impwebsite, "subhi");
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width:"60px"
    },
    {
      name: "Title",
      selector: (row) => row.impweb_title,
      sortable: true,
    },
    {
      name: "Url ",
      selector: (row) => row.impweb_url,
      sortable: true,
    },
    {
      name: "Thumbnail Image ",
      selector: (row) => <img className="rounded" height={50} width={50} src={BASE_FILE_URL + row.thumbnail_img} alt={row.thumbnail_img} />,
      sortable: true,
    },

    {
      name: "Status",
      selector: row => row.status?"Active":"In-Active",
      sortable: true,

    },

    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          {/* <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={() => handleEdit(row)}
          >
            <i className="far fa-edit"></i>
          </button> */}
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDelete(row)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div  >
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Important URL's
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a
                href="/admin/add-URL"
                className="page-title signup-link fs-4 pointer"
              >
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New URL
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={impwebsite} />
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default ImpWebsite;
