import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select, { components } from "react-select";
import {
  FloatingLabelOption,
  validationSchemaPage,
} from "../../../utils/constentFunc";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import CKEditor from "ckeditor4-react";
import websiteStore from "../../../store/WebsiteStore";
import { toJS } from "mobx";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Spinner from "../../../elements/Spinner";




const CreatePage = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState("");
  const [tabsData, setTabsData] = useState([]);
  const [masterTabsData, setMasterTabsData] = useState([]);

  useEffect(() => {
    websiteStore.getTabs();
  }, []);

  useEffect(() => {
    adminStore.getMasterTabs();
  }, []);

  useEffect(() => {
    setTabsData(toJS(websiteStore.data.navTabs));
  }, [websiteStore.data.navTabs]);


  useEffect(() => {
    setMasterTabsData(toJS(adminStore.data.masterTabs));
  }, [adminStore.data.masterTabs]);


  useEffect(() => {
    setPageData(
      adminStore.data.pages?.find((x) => x.id === location?.state?.page_id)
    );
  }, [location?.state?.page_id]);
  
  console.log(masterTabsData,"master tab data");

  const createPage = (values) => {
    if (!values.page_id) {
      let param = {
        page_name: values.page_name,
        tab_name: values.tab_name,
        status: values.status,
        page_data: values.page_data,
        order: values.order,
        master_page_id: values.master_page_id,
      };
      adminStore.createPage(param, navigationCallBack);
    } else if (values.page_id) {
      let param = {
        id: values.page_id,
        page_name: values.page_name,
        tab_name: values.tab_name,
        status: values.status,
        order: values.order,
        page_data: values.page_data,
        master_page_id: values.master_page_id,
      };
      adminStore.updatePage(param, navigationCallBackUpdate);
    }
  };
  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/pages");
  };
  const navigationCallBack = () => {
    Swal("Success!", "Successfully Created", "success");
    navigate("/admin/pages");
  };

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
        <Header />
          <div className="container mt-3 p-3 bg-white ">
            <h3 className="text-center mb-4">Create Page</h3>
            <Formik
              initialValues={{
                page_id: pageData?.id,
                page_name: pageData?.page_name || "",
                page_data: pageData?.page_data || "",
                tab_name: pageData?.tab_name || "",
                master_page_id: pageData?.master_page_id || "",
                order: pageData?.order || "",
                status: pageData?.status || "published",
              }}
              validationSchema={validationSchemaPage}
              onSubmit={(values) => {
                createPage(values);
              }}
              enableReinitialize
            >
              {({
                errors,
                status,
                touched,
                setFieldValue,
                isSubmitting,
                values,
                handleChange,
              }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="page_name"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="title">Page Title</label>
                        <ErrorMessage
                          name="page_name"
                          component="div"
                          className="text-danger fs-6"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Select
                          value={
                            tabsData?.find((x) => x.tab_name === values.tab_name) ||
                            ""
                          }
                          options={tabsData}
                          getOptionLabel={(option) => option.tab_name}
                          getOptionValue={(option) => option.tab_name}
                          onChange={(event) =>
                            setFieldValue("tab_name", event?.tab_name)
                          }
                          placeholder="Select a tab"
                          styles={{
                            control: (provided) => ({ ...provided, height: 58 }),
                            menu: (provided) => ({ ...provided, zIndex: 1000 }),

                          }}
                        />
                        <ErrorMessage
                          name="tab_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    
                    {/* <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Select
                          value={
                            masterTabsData?.find((x) => x.tab_name === values.master_page_name) ||
                            ""
                          }
                          options={masterTabsData}
                          getOptionLabel={(option) => option.master_page_name}
                          getOptionValue={(option) => option.master_page_name}
                          onChange={(event) =>
                            setFieldValue("master_page_id", event?.master_page_name)
                          }
                          placeholder="Master Page"
                          styles={{
                            control: (provided) => ({ ...provided, height: 58 }),
                            menu: (provided) => ({ ...provided, zIndex: 1000 }),
                          }}
                        />
                        <ErrorMessage
                          name="tab_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="order"
                          className="form-control"
                          onChange={handleChange}
                          placeholder=" "
                        />
                        <label htmlFor="order">Order</label>
                        <ErrorMessage
                          name="order"
                          component="div"
                          className="text-danger fs-6"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
</div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="content">Page Content</label>
                        <CKEditor
                          data={values?.page_data}
                          config={{
                            extraAllowedContent: "*(*);*{*}",
                            allowedContent: true, // Allow all content (use cautiously)
                          }}
                          onChange={(event) => {
                            let data = event.editor.getData();
                            console.log(data);
                            setFieldValue("page_data", data);
                          }}
                        />
                      </div>
                      {/* <div>
                                    <h2>Preview</h2>
                                    <div dangerouslySetInnerHTML={{ __html: values.page_data }}></div>
                                </div> */}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          value={values.status}
                          onChange={(event) => {
                            setFieldValue("status", event.target.value);
                          }}
                          name="status"
                          className="form-select"
                          placeholder=" "
                        >
                          <option value="">Select status</option>
                          <option value="draft">Draft</option>
                          <option value="published">Published</option>
                          <option value="archived">Archived</option>
                        </Field>
                        <label htmlFor="status">Status</label>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="text-danger fs-6"
                        />
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                  <div className="d-flex  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
      </div>
  );
});
export default CreatePage;
