// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Swal from "sweetalert";
import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import { toJS } from "mobx";
import Spinner from "../../../elements/Spinner";


const NoticeType = observer (() => {
  const [noticeType, setNoticeType] = useState([]);

  useEffect(() => {
    adminStore.getNotificationType();
  }, [])
  
  useEffect(() => {
    setNoticeType(toJS(adminStore.data.notificationsType));
  }, [adminStore.data.notificationsType])
  console.log(noticeType);

  const handleEdit = () => {}
 
  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this page!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        adminStore.deleteNotificationType(row.id, navigationCallBack)

      }
    });

  }

  const navigationCallBack = () => {
    Swal("success", "successfully deleted", "success");
    adminStore.getNotificationType();
  }
  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'Notice title',
      selector: row => row.title,
      sortable: true,

    },
    {
      name: 'Creation Time',
      selector: row => row.created_at,
      sortable: true,

    },
    {
      name: 'Action',
      cell: (row) => (
        <div className="d-flex align-items-center">
          <button className="btn btn-sm btn-outline-primary me-2" onClick={() => handleEdit(row)}><i className="far fa-edit"></i></button>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleDelete(row)}><i className="far fa-trash-alt"></i></button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };


  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 mt-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Notice Type
            </h3>
            {/* <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a href="/admin/add-notice-cate" className="page-title signup-link fs-4 pointer">
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Notice Type
              </a>
            </div> */}
          </div>
          <CustomDataTable columns={columns} data={noticeType} config={config}/>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>

  );
});

export default NoticeType;
