import React from "react";
import { Carousel } from "react-responsive-carousel";
// import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BASE_FILE_URL } from "../api/config";

const CommonCarousel = ({ media, height, width }) => {
  // {console.log(media, "media")}
  return (

    <Carousel
      swipeable={true}
      emulateTouch={true}
      stopOnHover={true}
      showThumbs={false}
      autoPlay
      interval="3000"
      transitionTime="300"
      infiniteLoop
    >
      {media?.images?.map((item, index) => {
        return (
          <div key={index}>
            <img
              height={height}
              width={width}
              src={BASE_FILE_URL + item.image_path}
              alt={item.alt}
              style={{ objectFit: 'cover' }}
            />



            {/* { item.type === 'video' ? (
            <ReactPlayer 
              url={item.url} 
              width={width} 
              height={400}
              controls 
            />
          ) : null} */}
            {item.caption && <p className="legend">{item.caption}</p>}
          </div>
        );
      })}
    </Carousel>
  );
};

export default CommonCarousel;
