// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import adminStore from "../../../store/AdminStore";
import Swal from 'sweetalert';
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Spinner from "../../../elements/Spinner";

const Contact = observer(() => {
  const [contactData, setContacts] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    adminStore.getContacts();
  }, []);

  useEffect(() => {
    setContacts(toJS(adminStore.data.contactData));
  }, [adminStore.data.contactData]);
  console.log(contactData);
  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        adminStore.deleteContact(row.id, navigationCallBack)
      }
    });
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Deleted", "success");
    adminStore.getContacts();
  }


  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "60px"
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Number",
      selector: (row) => row.contact_no,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
    },


    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">

          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDelete(row)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div >
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Contact
            </h3>

          </div>
          <CustomDataTable columns={columns} data={contactData} />
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>
  );
});

export default Contact;
