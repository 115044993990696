// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { toJS } from "mobx";
import moment from "moment";
import Spinner from "../../../elements/Spinner";


const Alumnies= observer(() => {
  const [alumnies, setAlumnies] = useState([]);
  const navigate = useNavigate()
  
  console.log(alumnies);
  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this alumnies!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          adminStore.deleteAlumnies(row.id, navigationCallBack)
        }
      });
  };

  const navigationCallBack = () => {
    Swal("success", "successfully deleted", "success");
    adminStore.getAlumnies();

  }
  
  const handleEdit = (row) => {
    navigate('/admin/add-alumnies', { state: { id: row.id } });

  };

  useEffect(() => {
    adminStore.getAlumnies();
  }, []);

  
  useEffect(() => {
    setAlumnies(toJS(adminStore.data.alumnies));
  }, [adminStore.data.alumnies]);

  const columns = [
    {
      name: "Id",
      selector: row => row.id,
      sortable: true,
      width: "60px",

    },
    {
      name: "Full Name",
      selector: row => row.full_name,
      sortable: true,

    },
    {
        name: "Father Name",
        selector: row => row.father_name,
        sortable: true,
  
      },
      {
        name: "Dob",
        selector: (row) => moment(row.date_of_birth).format("DD/MM/YYYY"),
        sortable: true,
      },
      {
        name: "Contact No.",
        selector: row => row.contact_no,
        sortable: true,
  
      },
    {
      name: "Email ",
      selector: row => row.email,
      sortable: true,

    },
  
      {
        name: "Year Of Passing",
        selector: row => row.year_of_passing,
        sortable: true,
  
      },

      {
        name: "Department",
        selector: row => row.department,
        sortable: true,
  
      },
      {
        name: "Course Completed",
        selector: row => row.course_completed,
        sortable: true,
  
      },
      {
        name: "Current ORG",
        selector: row => row.current_organization,
        sortable: true,
  
      },
      {
        name: "Designation",
        selector: row => row.current_designation,
        sortable: true,
  
      },
      {
        name: "Postal Address",
        selector: row => row.postal_address,
        sortable: true,
  
      },
      {
        name: "Status",
        selector: row => row.status?"Active":"In-Active",
        sortable: true,
  
      },
    
      {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
        <button
          className="btn btn-sm btn-outline-primary me-2"
          onClick={() => handleEdit(row)}
        >
          <i className="far fa-edit"></i>
        </button>
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => handleDelete(row)}
        >
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
   
  ];
  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div >
        <main className="main">
        <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Alumnies
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a
                href="/admin/add-alumnies"
                className="page-title signup-link fs-4 pointer"
              >
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Alumnies
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={alumnies}  />
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
    

  );
});

export default Alumnies;
