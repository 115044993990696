// src/components/Login.js
import React, { useState } from 'react';
import "../../assets/css/admin-style.css"
import { observer } from 'mobx-react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import adminStore from '../../store/AdminStore';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../elements/Spinner';

const AdminLogin = observer(() => {
  const navigate = useNavigate()

  const login = (values) => {
    adminStore.login(values, navigationCallBack, null, null)
  }
  const navigationCallBack = () => {
    navigate("/admin/dashboard");
  }

  return <>
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img
              alt="hey"
              src="image/ABVV-NewLogo.png"
              className="img-fluid"
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <Formik
              initialValues={{ email: '', password: '', rememberMe: false }}
              onSubmit={(values) => { login(values) }}
            >
              {({ values }) => (
                <Form className="login-form">
                  <div className="d-flex align-items-center my-4">
                    <h1 className="text-center fw-normal mb-0 me-3">Sign In</h1>
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="email">Email address</label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className="form-control form-control-lg"
                      placeholder="Enter a valid email address"
                    />
                    <ErrorMessage name="email" component="div" className="text-danger" />
                  </div>

                  <div className="form-outline mb-3">
                    <label className="form-label" htmlFor="password">Password</label>
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      className="form-control form-control-lg"
                      placeholder="Enter password"
                    />
                    <ErrorMessage name="password" component="div" className="text-danger" />
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="form-check mb-0">
                      <Field type="checkbox" id="rememberMe" name="rememberMe" className="form-check-input me-2" />
                      <label className="form-check-label" htmlFor="rememberMe">
                        Remember me
                      </label>
                    </div>
                    <a href="/reset-password" className="text-body">Forgot password?</a>
                  </div>

                  <div className="text-center text-lg-start mt-4 pt-2">
                    <button type="submit" className="btn btn-primary btn-lg text-white">Login
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {<Spinner loading={adminStore.loading}/>}
      </div>
    </section>

  </>
});

export default AdminLogin;


