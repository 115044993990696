import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert";
import adminStore from "../../../store/AdminStore";
import Spinner from '../../../elements/Spinner';
const AddSyllabus = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [addSyllabus, setAddSyllabus] = useState("");

  useEffect(() => {
    setAddSyllabus(
      adminStore.data.syllabus?.find((x) => x.id === location?.state?.id)
    );
  }, [location?.state?.id]);


  const addNewSyllabus = (values) => {
    if (!values.id) {
      let param = {
        syllabus_title: values.syllabus_title,
        status: values.status
      };
      adminStore.addSyllabus(param, navigationCallBack);
    } else {
      let param = {
        id: values.id,
        syllabus_title: values.syllabus_title,
        status: values.status,
      };
      adminStore.updateSyllabus(param, navigationCallBackUpdate);
    }
  };
  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/syllabus");
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Added", "success");
    navigate("/admin/syllabus");
  };

  const validationSchema = Yup.object({
    syllabus_title: Yup.string().required("Required"),
    status: Yup.boolean().required("Required"),
   
  });

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">Add New Syllabus</h3>
            <Formik
              initialValues={{
                id:addSyllabus?.id,
                syllabus_title:addSyllabus?.syllabus_title || "",
                status: addSyllabus?.status || true,
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addNewSyllabus(values);
              }}
            >
              {({ handleChange, values, errors}) => (
                <Form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="syllabus_title"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="syllabus_title">Title</label>
                        <ErrorMessage
                          name="syllabus_title"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <Field
                            as="select"
                            name="status"
                            className="form-select"
                            placeholder=" "
                            onChange={handleChange}
                          >
                            <option value="">Not Set</option>
                            <option value={true}>Active</option>
                            <option value={false}>In-active</option>
                          </Field>
                          <label htmlFor="status">Status</label>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    
                  </div>
                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default AddSyllabus;
