// src/pages/Home.js
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import NewsTicker from "react-advanced-news-ticker";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import websiteStore from "../../../store/WebsiteStore";
import { toJS } from "mobx";
// import CommonCarousel from '../../../components/CommonCarousel';
import { BASE_FILE_URL } from "../../../api/config";
import NewsTickerComponent from "../../../components/NewsTickerComponent";
import DeptCarousel from "../../../components/DeptCarousel";
import IqacHeader from "../../../components/IqacHeader";

const IqacHome = observer(() => {
  const location = useLocation();
  const [iqacData, setIqacData] = useState("");
  const [sliderData, setSliderData] = useState([]);
  const [notificationsData, setNotificationsData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("selected_tab","")
    if (websiteStore.data.departments?.length === 0) {
      websiteStore.getDeparments();
    }
    websiteStore.getDepartmentPages({ dept_id: "7"});
  }, []);

  useEffect(() => {
    setNotificationsData(
      toJS(
        websiteStore.data.notifications?.filter(
          (x) => x.dept_id == '7'
        )
      )
    );
  }, [websiteStore.data.notifications]);

  useEffect(() => {
    setIqacData(
      websiteStore.data.departments?.find((x) => x.id == '7')
    );
  }, [websiteStore.data.departments]);

  useEffect(() => {
    if (iqacData?.slider_id) {
      websiteStore.getSliderById(iqacData?.slider_id);
    }
  }, [iqacData]);

  useEffect(() => {
    let sliders = toJS(websiteStore.data.departmentSliders);
    sliders = sliders?.images?.map((slide) => {
      return {
        url: BASE_FILE_URL + slide?.image_path,
        alt: "Slide Image",
        caption: slide?.caption,
      };
    });
    setSliderData(sliders);
  }, [websiteStore.data.departmentSliders]);


  
const goToDeptNotice = () => {
  navigate('/table-view', { state: { type: "DepartmentNotificatons", dept_id: '7' } });
};

  return (
    <div>
      <Header />
      <IqacHeader/>
      <div
        className="container-fluid bg-white flex-iqac"
        style={{ display: "flex", gap: 9, width: "100%" , paddingTop:10}}
      >
        <div className="carousel-slide col-md-9">
          <h3
            style={{
              backgroundColor: "white",
              position: "relative",
              borderBottom: "2px solid #03356E",
              padding: 10,
            }}
            className="text-primary-emphasis text-center d-flex justify-content-start gap-2"
          >
            {iqacData?.department_name}
          </h3>
          
          
          <div className="p-2">
            {iqacData?.about_content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: iqacData?.top_menu_content,
                }}
              />
            )}
          </div>
          {sliderData && (
            <div>
              <DeptCarousel height={400} width={"100%"} images={sliderData} />
            </div>
          )}
          <div className="p-4">
            {iqacData?.about_content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: iqacData?.about_content,
                }}
              />
            )}
          </div>
        </div>
        <div className="sidesection col-md-3">
          <div className="panel panel-default boxed">
            <div className="panel-heading">
              <h3 className="text-primary-emphasis text-center d-flex justify-content-center gap-2">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width="24.5px"
                  fill="#03356E"
                >
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
                </svg>{" "}
                Notifications
              </h3>
            </div>
            <div className="panel-body p-2">
              <div className="row">
                <div className="col-sm-12">
                  <NewsTickerComponent newsData={notificationsData} />
                </div>
              </div>
            </div>
            <div className="panel-footer my-3">
              <a className="btn btn-infobtn btn-info" role="button" onClick={()=>goToDeptNotice()}>
                View All Notices
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default IqacHome;
