import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { validationSchemaAddSetting } from "../../../utils/constentFunc";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_FILE_URL } from "../../../api/config";
import { toast } from "react-toastify";
import { toJS } from "mobx";
import CKEditor from "ckeditor4-react";
import Spinner from "../../../elements/Spinner";


const Settings = observer(() => {
  const [settings, setSettings] = useState("");

  useEffect(() => {
    setSettings(adminStore.getSettings());
  }, []);

  useEffect(() => {
    setSettings(toJS(adminStore.data.settings)[0]);
  }, [adminStore.data.settings]);
  // console.log(settings);

  const addNewSettings = (values) => {
    const formData = new FormData();
    formData.append("full_name", values.full_name);
    formData.append("show_notice", values.show_notice);
    formData.append("fb_url", values.fb_url);
    formData.append("twitter_url", values.twitter_url);
    formData.append("youtube_url", values.youtube_url);
    formData.append("official_contact", values.official_contact);
    formData.append("official_email", values.official_email);
    formData.append("title", values.title);
    formData.append(
      "about_university_content",
      values.about_university_content
    );
    formData.append("popup_message_title", values.popup_message_title);
    formData.append("popup_page_content", values.popup_page_content);
    formData.append("university_address", values.university_address);
    formData.append("counter_numbers", values.counter_numbers);
    formData.append("show_events", values.show_events);
    formData.append("about_university", values.about_university);

    formData.append("university_logo", values.university_logo);
    formData.append("popup_image", values.popup_image);
    if (values.id) {
      formData.append("id", values.id);
      adminStore.updateSetting(formData, navigationCallBack);
    } else {
      adminStore.createSetting(formData, navigationCallBack);
    }

    // console.log(values., "payload");
  };

  const validationSchemaAddSetting = Yup.object({
    full_name: Yup.string().required("Required"),
    show_notice: Yup.string().required("Required"),
    fb_url: Yup.string().required("Required"),
    twitter_url: Yup.string().required("Required"),
    youtube_url: Yup.string().required("Required"),
    official_contact: Yup.string().required("Required"),
    official_email: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    university_logo: Yup.mixed().required("Required"),
    about_university_content: Yup.string().required("Required"),
    about_university: Yup.string().required("Required"),
    university_address: Yup.string().required("Required"),
  });

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Updated", "success");
    adminStore.getSettings();
  };

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">Add New Setting</h3>
            <Formik
              enableReinitialize
              initialValues={{
                id: settings?.id || "",
                full_name: settings?.full_name || "",
                title: settings?.title || "",
                show_notice: settings?.show_notice,
                show_events: settings?.show_events,
                fb_url: settings?.fb_url || "",
                twitter_url: settings?.twitter_url || "",
                youtube_url: settings?.youtube_url || "",
                official_contact: settings?.official_contact || "",
                official_email: settings?.official_email || "",
                university_address: settings?.university_address || "",
                about_university: settings?.about_university || "",
                popup_message_title: settings?.popup_message_title || "",
                popup_page_content: settings?.popup_page_content || "",
                counter_numbers: settings?.counter_numbers || "",
                university_logo: settings?.university_logo || "",
                popup_image: settings?.popup_image || "", 
                about_university_content:settings?.about_university_content || "",
              }}
              // validationSchema={validationSchemaAddSetting}
              onSubmit={(values) => {
                addNewSettings(values);
              }}
            >
              {({ setFieldValue, handleChange, values, errors }) => (
                <Form>
                  {console.log(errors)}
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="full_name"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="">Full Name</label>
                        <ErrorMessage
                          name="full_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="fb_url"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="fb_url">Fb Url</label>
                        <ErrorMessage
                          name="fb_url"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="twitter_url"
                          className="form-control"
                          placeholder=" "
                          onChange={(setting) =>
                            setFieldValue("twitter_url", setting.target.value)
                          }
                        />
                        <label htmlFor="twitter_url">Twitter Url</label>
                        <ErrorMessage
                          name="twitter_url"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="youtube_url"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="youtube_url">Youtube Url</label>
                        <ErrorMessage
                          name="youtube_url"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="official_contact"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="official_contact">
                          Official Contact
                        </label>
                        <ErrorMessage
                          name="official_contact"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="official_email"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="official_email">Official Email</label>
                        <ErrorMessage
                          name="official_email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="title"
                          className="form-control"
                          placeholder=" "
                          onChange={(setting) =>
                            setFieldValue("title", setting.target.value)
                          }
                        />
                        <label htmlFor="title">Title</label>
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="popup_message_title"
                          className="form-control"
                          placeholder=" "
                          onChange={(setting) =>
                            setFieldValue(
                              "popup_message_title",
                              setting.target.value
                            )
                          }
                        />
                        <label htmlFor="popup_message_title">
                          Pop Up Message Title
                        </label>
                        <ErrorMessage
                          name="popup_message_title"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="popup_page_content"
                          className="form-control"
                          placeholder=" "
                          onChange={(setting) =>
                            setFieldValue(
                              "popup_page_content",
                              setting.target.value
                            )
                          }
                        />
                        <label htmlFor="popup_page_content">
                          Pop Up Page Content
                        </label>
                        <ErrorMessage
                          name="popup_page_content"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="university_address"
                          className="form-control"
                          placeholder=" "
                          onChange={(setting) =>
                            setFieldValue(
                              "university_address",
                              setting.target.value
                            )
                          }
                        />
                        <label htmlFor="university_address">
                          University Address
                        </label>
                        <ErrorMessage
                          name="university_address"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <select
                          name="show_notice"
                          className="form-control"
                          onChange={handleChange}
                          value={values.show_notice}
                        >
                          <option value="">Select an option</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                        {console.log("show_notice",values.show_notice)}
                        <label htmlFor="show_notice">Show PopUp</label>
                        <ErrorMessage
                          name="show_notice"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="counter_numbers"
                          className="form-control"
                          placeholder=" "
                          onChange={(setting) =>
                            setFieldValue(
                              "counter_numbers",
                              setting.target.value
                            )
                          }
                        />
                        <label htmlFor="counter_numbers">Counter Numbers</label>
                        <ErrorMessage
                          name="counter_numbers"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <select
                          name="show_events"
                          className="form-control"
                          onChange={handleChange}
                          value={values.show_events}
                        >
                          <option value="">Select an option</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                        {console.log("show_events",values.show_events)}
                        <label htmlFor="show_events">Show Events</label>
                        <ErrorMessage
                          name="show_events"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input
                          type="file"
                          name="university_logo"
                          className="form-control"
                          // placeholder=" "
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file.size <= fileSizeLimit) {
                              setFieldValue("university_logo", file);
                            } else {
                              toast.warn("File size exceeds 2MB limit");
                            }
                          }}
                        />
                        <a
                          href={BASE_FILE_URL + values?.university_logo}
                          target="_blank"
                        >
                          <img
                            src={BASE_FILE_URL + values?.university_logo}
                            height="50"
                            width="50"
                          ></img>
                        </a>
                        <label htmlFor="university_logo">University Logo</label>
                        <ErrorMessage
                          name="university_logo"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input
                          type="file"
                          name="popup_image"
                          className="form-control"
                          placeholder=" "
                          onChange={(event) => {
                            const file = event?.target?.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file?.size <= fileSizeLimit) {
                              setFieldValue("popup_image", file);
                            } else {
                              toast.warn("File size exceeds 2MB limit");
                            }
                          }}
                        />

                        <a
                          href={BASE_FILE_URL + values?.popup_image}
                          target="_blank"
                        >
                          <img
                            src={BASE_FILE_URL + values?.popup_image}
                            height="50"
                            width="50"
                          ></img>
                        </a>
                        <label htmlFor="popup_image">Popup Image</label>
                        <ErrorMessage
                          name="popup_image"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="about_university"
                          className="form-control"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue(
                              "about_university",
                              event.target.value
                            )
                          }
                        />
                        <label htmlFor="about_university">
                          About University
                        </label>
                        <ErrorMessage
                          name="about_university"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <CKEditor
                        data={values?.about_university_content}
                        height="50px"
                        name="about_university_content"
                        config={{
                          extraAllowedContent: "*(*);*{*}",
                          allowedContent: true, // Allow all content (use cautiously)
                        }}
                        onChange={(event) => {
                          setFieldValue(
                            "about_university_content",
                            event.editor.getData()
                          );
                        }}
                      />
                      <ErrorMessage
                        name="about_university_content"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="row  justify-content-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{ width: "120px" }}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default Settings;
