import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { validationSchemaAddEvent } from "../../../utils/constentFunc";
import adminStore from "../../../store/AdminStore";
import Swal from 'sweetalert';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_FILE_URL } from "../../../api/config";
import Select from "react-select";
import { toJS } from "mobx";
import Spinner from "../../../elements/Spinner";



const AddNewEvent = observer(() => {
  const location = useLocation();
  const navigate = useNavigate()
  const [eventData, setEventData] = useState("");
  const [departments, setDepartments] = useState([]);


  useEffect(() => {
    setDepartments(toJS(adminStore.data.departments));
  }, [adminStore.data.departments]);

  useEffect(() => {
    adminStore.getDepartments();
    setEventData(
      adminStore.data.admins?.find((x) => x.id === location?.state?.id)
    );
  }, [location?.state?.admins]);

  const addEvent = (values) => {
    const formData = new FormData()
    formData.append('event_name', values.event_name)
    formData.append('event_level', values.event_level)
    formData.append('no_of_participants', values.no_of_participants)
    formData.append('convener', values.convener)
    formData.append('event_date', values.event_date)
    formData.append('start_date', values.start_date)
    formData.append('venue', values.venue)
    formData.append('brochure_file', values.brochure_file)
    formData.append('report_hindi', values.report_hindi)
    formData.append('report_english', values.report_english)
    formData.append('last_date', values.last_date)
    formData.append('dept_id', values.dept_id)
    formData.append('photo_gallery_id', values.photo_gallery_id)
    formData.append('video_gallery_id', values.video_gallery_id)
    formData.append('event_mode', values.event_mode)
    formData.append('status', values.status)

    formData.append('show_in_homepage', values.show_in_homepage)
    formData.append('thumbnail_image_url', values.thumbnail_image_url)
    // adminStore.addEvent(formData, navigationCallBack)

    console.log(values);

    if (!values.event_id) {
      adminStore.addEvent(formData, navigationCallBack);
    } else {
      formData.append("id", values.event_id);
      adminStore.updateEvent(formData, navigationCallBackUpdate);
    }
    // console.log(values., "payload");
  };

  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/eventslist");
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Added", "success");
    navigate('/admin/eventslist');

  }

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div  >
        <main className="main">
          <Header />
          <div className="container p-3 bg-white mt-3">
            <h3 className="text-center mb-4">Add New Event</h3>
            <Formik
              initialValues={{
                event_id: eventData?.id,
                event_name: eventData?.event_name || "",
                event_level: eventData?.event_level || "",
                no_of_participants: eventData?.no_of_participants || "",
                convener: eventData?.convener || "",
                event_date: eventData?.event_date || "",
                start_date: eventData?.start_date || "",
                venue: eventData?.venue || "",
                brochure_file: eventData?.brochure_file || "",
                report_hindi: eventData?.report_hindi || "",
                report_english: eventData?.report_english || "",
                last_date: eventData?.last_date || "",
                dept_id: eventData?.dept_id || "",
                photo_gallery_id: eventData?.photo_gallery_id || "",
                video_gallery_id: eventData?.video_gallery_id || "",
                event_mode: eventData?.event_mode || "",
                status: eventData?.status || "",
                thumbnail_image_url: eventData?.thumbnail_image_url || "",
                show_in_homepage: true,
              }}
              enableReinitialize
              validationSchema={validationSchemaAddEvent}
              onSubmit={(values) => {
                addEvent(values);
              }}
            >
              {({ setFieldValue, handleChange, values,errors }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="event_name"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="event_name">Event Name</label>
                        <ErrorMessage
                          name="event_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="event_level"
                          className="form-control"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("event_level", event.target.value)
                          }
                        />
                        <label htmlFor="event_level">Event Level</label>
                        <ErrorMessage
                          name="event_level"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="no_of_participants"
                          className="form-control"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue(
                              "no_of_participants",
                              event.target.value
                            )
                          }
                        />
                        <label htmlFor="no_of_participants">
                          No. of Participants
                        </label>
                        <ErrorMessage
                          name="no_of_participants"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="convener"
                          className="form-control"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("convener", event.target.value)
                          }
                        />
                        <label htmlFor="convener">Convener</label>
                        <ErrorMessage
                          name="convener"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="date"
                          name="event_date"
                          className="form-control"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("event_date", event.target.value)
                          }
                        />
                        <label htmlFor="event_date">Event Date</label>
                        <ErrorMessage
                          name="event_date"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="date"
                          name="start_date"
                          className="form-control"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("start_date", event.target.value)
                          }
                        />
                        <label htmlFor="start_date">Start Date</label>
                        <ErrorMessage
                          name="start_date"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="venue"
                          className="form-control"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("venue", event.target.value)
                          }
                        />
                        <label htmlFor="venue">Venue</label>
                        <ErrorMessage
                          name="venue"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="brochure_file">Brochure File</label>
                        <input

                          id="brochure_file"
                          name="brochure_file"
                          type="file"
                          className="form-control"
                          fileName={values.brochure_file || ""}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file.size <= fileSizeLimit) {
                              setFieldValue("brochure_file", file);
                            } else {
                              alert("File size exceeds 2MB limit");
                            }
                          }}
                        />
                        {values?.brochure_file && <a href={BASE_FILE_URL + values?.brochure_file} target="_blank" >View</a>}
                        <ErrorMessage
                          name="brochure_file"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="report_hindi">Report Hindi</label>
                        <input
                          multiple
                          id="report_hindi"
                          name="report_hindi"
                          type="file"
                          className="form-control"
                          fileName={values?.report_hindi || ""}
                          onChange={(event) => {
                            const file = event?.target?.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file?.size <= fileSizeLimit) {
                              setFieldValue("report_hindi", file);
                            } else {
                              alert("File size exceeds 2MB limit");
                            }
                          }}
                        />
                        {values?.report_hindi && <a href={BASE_FILE_URL + values?.report_hindi} target="_blank" >View</a>}
                        <ErrorMessage
                          name="report_hindi"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="report_english">Report English</label>
                        <input
                          multiple
                          id="report_english"
                          name="report_english"
                          type="file"
                          className="form-control"
                          fileName={values?.report_english || ""}
                          onChange={(event) => {
                            const file = event?.target?.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file?.size <= fileSizeLimit) {
                              setFieldValue("report_english", file);
                            } else {
                              alert("File size exceeds 2MB limit");
                            }
                          }}
                        />
                        {values?.report_english && <a href={BASE_FILE_URL + values?.report_english} target="_blank" >View</a>}
                        <ErrorMessage
                          name="report_english"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>


                    <div className="col-md-6 mt-4">
                      <div className="form-floating mb-3">
                        <Field
                          type="date"
                          name="last_date"
                          className="form-control"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("last_date", event.target.value)
                          }
                        />
                        <label htmlFor="last_date">
                          Last Date for Home Page
                        </label>
                        <ErrorMessage
                          name="last_date"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Select
                          value={departments?.find((x) => x.id === values.dept_id) || ""}
                          options={departments}
                          getOptionLabel={(option) => option.department_name}
                          getOptionValue={(option) => option.id}
                          onChange={(event) =>
                            setFieldValue("dept_id", event?.id)
                          }
                          placeholder="Select a department"
                          styles={{
                            control: (provided) => ({ ...provided, height: 58 }),
                            menu: (provided) => ({ ...provided, zIndex: 1000 }),
                          }}
                        />
                        <ErrorMessage
                          name="dept_id"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="photo_gallery_id"
                          className="form-select"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("photo_gallery_id", event.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </Field>
                        <label htmlFor="photo_gallery_id">
                          Photo Gallery Id
                        </label>
                        <ErrorMessage
                          name="photo_gallery_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="video_gallery_id"
                          className="form-select"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("video_gallery_id", event.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </Field>
                        <label htmlFor="video_gallery_id">
                          Video Gallery Id
                        </label>
                        <ErrorMessage
                          name="video_gallery_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="status"
                          className="form-select"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("status", event.target.value)
                          }
                        >
                          <option value="">not set</option>
                          <option value={true}>Active</option>
                          <option value={false}>In-Active</option>
                        </Field>
                        <label htmlFor="status">status</label>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="event_mode"
                          className="form-select"
                          placeholder=" "
                          onChange={(event) =>
                            setFieldValue("event_mode", event.target.value)
                          }
                        >
                          <option value="">Select mode</option>
                          <option value="true">Online</option>
                          <option value="false">Offline</option>
                        </Field>
                        <label htmlFor="event_mode">Event Mode</label>
                        <ErrorMessage
                          name="event_mode"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="thumbnail_image_url">Thumbnail Image File</label>
                        <input
                          multiple
                          id="thumbnail_image_url"
                          name="thumbnail_image_url"
                          type="file"
                          className="form-control"
                          fileName={values?.thumbnail_image_url || ""}
                          onChange={(event) => {
                            const file = event?.target?.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file?.size <= fileSizeLimit) {
                              setFieldValue("thumbnail_image_url", file);
                            } else {
                              alert("File size exceeds 2MB limit");
                            }
                          }}
                        />
                        {values?.thumbnail_image_url && <a href={BASE_FILE_URL + values?.thumbnail_image_url} target="_blank" >View</a>}
                        <ErrorMessage
                          name="thumbnail_image_url"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-check mb-3">
                        <Field
                          type="checkbox"
                          name="show_in_homepage"
                          className="form-check-input"
                          id="show_in_homepage"
                          onChange={(event) =>
                            setFieldValue("show_in_homepage", event.target.checked)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="show_in_homepage"
                        >
                          Show in Homepage
                        </label>
                        <ErrorMessage
                          name="show_in_homepage"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        {/* <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                    modules={modules}
                    formats={formats}
                  /> */}
                        <ErrorMessage
                          name="content"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row  justify-content-center">
                    <button className="btn btn-primary w-25"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>
  );
});

export default AddNewEvent;
