import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import CKEditor from "ckeditor4-react";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { BASE_FILE_URL } from "../../../api/config";
import { toast } from "react-toastify";
import Spinner from "../../../elements/Spinner";


const AddNewDepartment = observer(() => {
  const location = useLocation();
  const navigate = useNavigate()
  const [departmentList, setDepartmentList] = useState("");

  useEffect(() => {
    setDepartmentList(
      adminStore.data.departments?.find((x) => x.id === location?.state?.department_id)
    );
  }, [location?.state?.department_id]);

  const addNewDepartment = (values) => {

    if (!values.thumbnail_image && !values.id) {
      toast.warn("Please upload thumbnail image!")
      return
    }

    const formData = new FormData()
    formData.append('department_name', values.department_name)
    formData.append('slider_id', values.slider_id)
    formData.append('thumbnail_image', values.thumbnail_image)
    formData.append('department_code', values.department_code)
    formData.append('dept_type', values.dept_type)
    formData.append('about_content', values.about_content)
    formData.append('top_menu_content', values.top_menu_content)
    formData.append('status', values.status)
    if (!values.id) {
      adminStore.addNewDepartment(formData, navigationCallBack);
    } else {
      formData.append('id', values.id)
      adminStore.updateDepartment(formData, navigationCallBackUpdate);
    }
  };

  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/departments");
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Created", "success");
    navigate("/admin/departments");
  };

  const validationSchema = Yup.object({
    department_name: Yup.string().required("Required"),
    department_code: Yup.string().required("Required"),
    dept_type: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
  });

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div >
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mt-4">{departmentList?.id ? "Update Department" : "Add Department"}</h3>
            <Formik
              initialValues={{
                id: departmentList?.id,
                department_name: departmentList?.department_name || "",
                slider_id: departmentList?.slider_id || "",
                thumbnail_image: departmentList?.thumbnail_image || "",
                department_code: departmentList?.department_code || "",
                dept_type: departmentList?.dept_type || "",
                about_content: departmentList?.about_content || "",
                top_menu_content: departmentList?.top_menu_content || "",
                status: departmentList?.status || true
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addNewDepartment(values);
              }}
            >
              {({ setFieldValue, errors, handleChange, values }) => (
                <Form>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="thumbnail_image">Thumbnail</label>
                        <input
                          id="thumbnail_image"
                          name="thumbnail_image"
                          type="file"
                          className="form-control"
                          fileName={values.thumbnail_image || ""}
                          onChange={(event) => {
                            const file = event?.target?.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file?.size <= fileSizeLimit) {
                              setFieldValue("thumbnail_image", file);
                            } else {
                              alert("File size exceeds 2MB limit");
                            }
                          }}
                        />
                        {values.id && <a href={BASE_FILE_URL + values?.thumbnail_image} target="_blank" ><img className="img-fluid img-thumbnail" width={50} src={BASE_FILE_URL + values?.thumbnail_image} /></a>}
                        <ErrorMessage
                          name="thumbnail_image"
                          component="div"
                          className="text-danger"


                        />
                      </div>
                    </div>
                    <div className="col-md-6  mt-4">
                      <div className="form-floating">
                        <Field
                          as="select"
                          name="dept_type"
                          className="form-select"
                          placeholder=""
                          onChange={handleChange}
                        >
                          <option value="" disabled>Select Department</option>
                          <option value="department">Department</option>
                          <option value="cell">Cell</option>
                        </Field>
                        <label htmlFor="dept_type">Department Type</label>
                        <ErrorMessage
                          name="dept_type"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating ">
                        <Field
                          name="department_name"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="department_name">Department Name</label>
                        <ErrorMessage
                          name="department_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          name="department_code"
                          className="form-control"
                          onChange={handleChange}
                        />
                        <label htmlFor="department_code">Department Code</label>
                        <ErrorMessage
                          name="department_code"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating ">
                        <Field
                          name="slider_id"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="slider_id">Slider Id</label>
                        <ErrorMessage
                          name="slider_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="status"
                          className="form-select"
                          placeholder=" "
                          onChange={handleChange}
                        >
                          <option value=""></option>
                          <option value={false}>In-active</option>
                          <option value={true}>Active</option>
                        </Field>
                        <label htmlFor="status">Status</label>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="about_content">About Content</label>
                        <CKEditor
                          data={values?.about_content}
                          height="50px"
                          name="about_content"
                          config={{
                            extraAllowedContent: "*(*);*{*}",
                            allowedContent: true, // Allow all content (use cautiously)
                          }}
                          onChange={(event) => {
                            setFieldValue("about_content", event.editor.getData());

                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="top_menu_content">
                          Top Menu Content
                        </label>
                        <CKEditor
                          data={values?.top_menu_content}
                          name="top_menu_content"
                          config={

                            {
                              height: "100px",
                              extraAllowedContent: "*(*);*{*}",
                              allowedContent: true, // Allow all content (use cautiously)
                            }}
                          onChange={(event) => {
                            setFieldValue("top_menu_content", event.editor.getData());
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default AddNewDepartment;
