import React, { useEffect, useRef, useState } from "react";
import NewsTicker, { Directions } from "react-advanced-news-ticker";
import "font-awesome/css/font-awesome.min.css"; // Import Font Awesome CSS
import moment from "moment";
import { BASE_FILE_URL } from "../api/config";
const NewsTickerComponent = ({ newsData }) => {
  const tickerRef = useRef(null);
  const [key, setKey] = useState(2);
  useEffect(() => {
    // Update key to force re-render when newsData changes
    setKey((prevKey) => prevKey + 1);
  }, [newsData]);

  const handleMovePrev = () => {
    if (tickerRef.current) {
      tickerRef.current.movePrev();
      tickerRef.current.resetInterval();
    }
  };

  const handleMoveNext = () => {
    if (tickerRef.current) {
      tickerRef.current.moveNext();
      tickerRef.current.resetInterval();
    }
  };

  return (
    <div className="news-ticker-container">
      <i
        className="fa fa-arrow-up news-ticker-control pointer"
        onClick={handleMovePrev}
      />
      <div className="news-ticker-wrapper">
        <NewsTicker
          key={key}
          ref={tickerRef}
          id="nt-example1"
          rowHeight={30}
          maxRows={10}
          speed={600}
          duration={4000}
          direction={Directions.UP}
          autoStart={true}
          pauseOnHover={true}
          style={{ marginTop: 5 }}
        >
          {newsData?.length > 0 ? (
            newsData?.map((news, ind) => (
              <div className="news-item" key={ind + "news"}>
                <a target="blank" href={BASE_FILE_URL + news.notification_file}>
                  <div>
                    <b className="" style={{ color: "#030899" }}>
                      {moment(news.created_at).format("DD/MM/YYYY")}
                    </b>
                    <div style={{ color: "black" }}>{news.title}</div>
                  </div>
                </a>
              </div>
            ))
          ) : (
            <span>No New Notification!</span>
          )}
        </NewsTicker>
      </div>
      <i
        className="fa fa-arrow-down news-ticker-control pointer"
        onClick={handleMoveNext}
      />
    </div>
  );
};

export default NewsTickerComponent;
