import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { BASE_FILE_URL } from '../api/config';

const NewsCarousel = ({ items }) => {
    // Chunk the items array into groups of 2
    const chunkedItems = [];
    for (let i = 0; i < items.length; i += 2) {
        chunkedItems.push(items.slice(i, i + 2));
    }

    return (
        <div
            id="carouselNews"
            className="carousel carousel-dark slide w-100 mt-4"
            data-bs-ride="carousel"
        >
            <div className="carousel-inner">
                {chunkedItems.map((chunk, idx) => (
                    <div className={`carousel-item ${idx === 0 ? 'active' : ''}`} key={idx}>
                        <div className="container d-flex justify-content-around">
                            {chunk.map((item, itemIdx) => (
                                <div className="card" style={{ width: '18rem', height: 414 }} key={itemIdx}>
                                    <a target='blank' href={BASE_FILE_URL+ item.pdf_file} >
                                    <img
                                        src={`${BASE_FILE_URL}${item.thumbnail_image}`}
                                        className="card-img-top"
                                        alt={item.subject}
                                    />
                                    <div className="card-body">
                                        <h5 style={{color:"black"}} className="card-title text-truncate">{item.subject}</h5>
                                        <p style={{color:"black"}}>Date: {moment(item.created_at).format('DD/MM/YYYY')}</p>
                                    </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselNews"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselNews"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};

NewsCarousel.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            thumbnail_image: PropTypes.string.isRequired,
            subject: PropTypes.string.isRequired,
            created_at: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default NewsCarousel;
