import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import websiteStore from "../../store/WebsiteStore";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../api/config";

const VcProfile = observer(() => {
  return (
    <div>
      <Header />
      <section className="innerbanner" />
      <div style={{ width: "100%" }}>
        <div className="container-fluid">
          <div className="row2">
            {
              <div className="row1 w-100 mx-auto">
                <h3 className="main-head">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                  </svg>
                  Vice Chancellor's Profile
                </h3>
                <div className="container">
                  <div
                    className="profile-header text-center"
                    style={{ marginBottom: 20 }}
                  >
                    <img
                      src={`${BASE_FILE_URL}/uploads/media-files/1721282193462.png`}
                      alt="Professor Arun Diwaker Nath Bajpai"
                      style={{ borderRadius: "50%", width: 150, height: 150 }}
                    />
                    <h2
                      style={{
                        marginTop: 10,
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      Professor Arun Diwaker Nath Bajpai
                    </h2>
                    <h4
                      style={{
                        marginTop: 5,
                        fontSize: "1.2rem",
                        color: "gray",
                      }}
                    >
                      Vice-Chancellor, Atal Bihari Vajpayee University, Bilaspur
                      (Chhattisgarh)
                    </h4>
                  </div>
                  <div
                    className="profile-section"
                    style={{
                      marginBottom: 20,
                      border: "1px solid #e9e5e5",
                      padding: "10px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <h5
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        marginBottom: 10,
                      }}
                    >
                      Personal Information
                    </h5>
                    <p>
                      <strong>Name:</strong> Professor Arun Diwaker Nath Bajpai
                    </p>
                    <p>
                      <strong>Qualifications:</strong> M.A. (Economics), Ph.D.
                    </p>
                    <p>
                      <strong>Specialization:</strong> Economics Theory
                      Development, Economics; Spiritual Economics
                    </p>
                    <p>
                      <strong>Present position:</strong> Vice-Chancellor, Atal
                      Bihari Vajpayee University, Bilaspur (Chhattisgarh)
                    </p>
                  </div>
                  <div className="accordion ac1" id="profileAccordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingAchievements">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseAchievements"
                          aria-expanded="false"
                          aria-controls="collapseAchievements"
                        >
                          Notable Achievements
                        </button>
                      </h2>
                      <div
                        id="collapseAchievements"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingAchievements"
                        data-bs-parent="#profileAccordion"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li>
                              Vice-Chancellor, Atal Bihari Vajpayee University,
                              Bilaspur (Chhattisgarh) (from February 2021).
                            </li>
                            <li>
                              Vice-Chancellor, Himachal Pradesh University,
                              Shimla (2011-2017).
                            </li>
                            <li>
                              Vice-Chancellor, Awadhesh Pratap Singh University,
                              Rewa (2003-2007).
                            </li>
                            <li>
                              Vice-Chancellor, Mahatma Gandhi Gramodaya
                              Vishwavidyalaya, Chitrakoot (2003-2004).
                            </li>
                            <li>
                              President, Indian Economic Association, New Delhi
                              (2022).
                            </li>
                            <li>
                              Chairman, High Power Committee, National Education
                              Policy, Madhya Pradesh (from 2021).
                            </li>
                            <li>
                              Member, High Power Committee, National Education
                              Policy, Chhattisgarh (from 2021).
                            </li>
                            <li>
                              Member, Governing Council, Association of Indian
                              Universities (AIU), New Delhi (since 2022).
                            </li>
                            <li>
                              Member, Academic Council, National Defense
                              Academy, Pune, Maharashtra (since 2021).
                            </li>
                            <li>
                              Member, Academic Council, Patliputra University,
                              Patna, Bihar (since 2021).
                            </li>
                            <li>
                              Member, Academic Council, H.S. Gaur Central
                              University, Sagar, Madhya Pradesh (from 2021).
                            </li>
                            <li>
                              Member, International Advisory Committee, Guru
                              Nanak Dev Global University, Dallas America (since
                              2023).
                            </li>
                            <li>
                              Patron, Alumni Association, Himachal Pradesh
                              University, Shimla (from 2021).
                            </li>
                            <li>
                              Member, Linguistics Expert Committee, Ministry of
                              Culture, Government of India, New Delhi (since
                              2020).
                            </li>
                            <li>
                              President, Rashtriya Samaj Vigyan Parishad, New
                              Delhi (since 2018).
                            </li>
                            <li>
                              Member, Deen Dayal Upadhyaya Centenary
                              Celebrations Committee, New Delhi, Chaired by
                              Hon'ble Prime Minister of India (2017-2018).
                            </li>
                            <li>
                              Council Member, Association of Commonwealth
                              Universities (ACU), London (2015-2017).
                            </li>
                            <li>
                              Council Member, Australia-India Educational
                              Council (AIEC), Ministry of Human Resource
                              Development, Government of India (since 2015).
                            </li>
                            <li>
                              Secretary General, Association of Indian
                              Universities (AIU), New Delhi (Additional Charge)
                              (2011-2012).
                            </li>
                            <li>
                              Honorary Secretary and Treasurer, Indian Economic
                              Association (2000-2003).
                            </li>
                            <li>
                              Member, Central Advisory Board of Education,
                              University Reforms Committee, New Delhi, Chaired
                              by the Minister of Human Resource Development,
                              Government of India (2012-2013).
                            </li>
                            <li>
                              President, Madhya Pradesh Economic Association,
                              Bhopal, Madhya Pradesh (2008).
                            </li>
                            <li>
                              Member, Mahatma Gandhi 150th Anniversary
                              Celebrations Committee, Government of India, New
                              Delhi, Chairman, Hon'ble President of India
                              (2019-20).
                            </li>
                            <li>
                              Member, National Foundation for Communal Harmony,
                              Government of India, New Delhi, Chairman, Hon'ble
                              Union Home Minister Government of India (Since
                              2017).
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingExperience">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseExperience"
                          aria-expanded="false"
                          aria-controls="collapseExperience"
                        >
                          Experience
                        </button>
                      </h2>
                      <div
                        id="collapseExperience"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingExperience"
                        data-bs-parent="#profileAccordion"
                      >
                        <div className="accordion-body">
                          <p>
                            <strong>Academic:</strong>
                          </p>
                          <ul>
                            <li>Research: Since 1976 (about 47 years)</li>
                            <li>Lecturer: 1980 to 1988</li>
                            <li>Reader: 1988 to 1998</li>
                            <li>Professor: 1998 to 2021</li>
                          </ul>
                          <p>
                            <strong>Administrative:</strong>
                          </p>
                          <ul>
                            <li>
                              Head of Department: 1993 to 2003 (about 10 years)
                            </li>
                            <li>
                              Vice-Chancellor of various universities (about 12
                              years)
                            </li>
                            <li>
                              Director, Institute of Management (about 06
                              months)
                            </li>
                          </ul>
                          <p>
                            <strong>Research Supervision:</strong>
                          </p>
                          <ul>
                            <li>Ph.D. - 29</li>
                            <li>M. Phil. - 25</li>
                            <li>M.A. - 13</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="headingForeignVisits"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseForeignVisits"
                          aria-expanded="false"
                          aria-controls="collapseForeignVisits"
                        >
                          Foreign Visits
                        </button>
                      </h2>
                      <div
                        id="collapseForeignVisits"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingForeignVisits"
                        data-bs-parent="#profileAccordion"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li>
                              Fiji - Nadi (February 2023), Suva (May 2023)
                            </li>
                            <li>
                              United Kingdom - Liverpool (2017), London (2002,
                              2013, 2015, 2016, 2023), Oxford (2002, 2015),
                              Cambridge (2015, 2016)
                            </li>
                            <li>Ghana - Accra (2016)</li>
                            <li>South Africa - Durban (2011)</li>
                            <li>Kenya - Nairobi (2011)</li>
                            <li>Thailand - Bangkok (2016)</li>
                            <li>Portugal - Lisbon (2002)</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="headingVisitorsChancellorsNominee"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseVisitorsChancellorsNominee"
                          aria-expanded="false"
                          aria-controls="collapseVisitorsChancellorsNominee"
                        >
                          Visitors/Chancellors Nominee
                        </button>
                      </h2>
                      <div
                        id="collapseVisitorsChancellorsNominee"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingVisitorsChancellorsNominee"
                        data-bs-parent="#profileAccordion"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li>
                              Member: University Court, University of Delhi
                              (2017-2022).
                            </li>
                            <li>
                              Member: Board of Management, BBA, Central
                              University, Lucknow, Uttar Pradesh.
                            </li>
                            <li>
                              Member: Board of Management, Central University of
                              Punjab, Bathinda, Punjab.
                            </li>
                            <li>
                              Member: Selection Committee, Central University of
                              Haryana, Mahendergarh, Haryana.
                            </li>
                            <li>
                              Member: Board of Management, Sikkim Law
                              University, Sikkim.
                            </li>
                            <li>
                              Member: University Court, Guru Gobind Singh
                              Indrapasth University, New Delhi.
                            </li>
                            <li>
                              Member: Board of Management, Institute of Advanced
                              Education Studies, Sardarshahar, Rajasthan.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="headingSearchCumSelectionCommittee"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSearchCumSelectionCommittee"
                          aria-expanded="false"
                          aria-controls="collapseSearchCumSelectionCommittee"
                        >
                          Chairman/Member Vice Chancellor's Search Cum Selection
                          Committee
                        </button>
                      </h2>
                      <div
                        id="collapseSearchCumSelectionCommittee"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSearchCumSelectionCommittee"
                        data-bs-parent="#profileAccordion"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li>
                              Chairman: Vice-Chancellor’s Search Cum Selection
                              Committee, Ajmer University, Rajasthan.
                            </li>
                            <li>
                              Member: Vice-Chancellor’s Search Cum Selection
                              Committee, Central University of Kerala, Kerala.
                            </li>
                            <li>
                              Member: Vice-Chancellor’s Search Cum Selection
                              Committee, Central Institute of Higher Tibetan
                              Studies, Varanasi, Uttar Pradesh.
                            </li>
                            <li>
                              Member: Vice-Chancellor’s Search Cum Selection
                              Committee, A.K.S. University, Satna, Madhya
                              Pradesh.
                            </li>
                            <li>
                              Member: Vice-Chancellor’s Search Cum Selection
                              Committee, Guru Jambeshwar University, Rohtak,
                              Haryana.
                            </li>
                            <li>
                              Member: Vice-Chancellor’s Search Cum Selection
                              Committee, Ram Manohar Lohia, Avadh University,
                              Ayodhya, Uttar Pradesh.
                            </li>
                            <li>
                              Member: Director Search Cum Selection Committee,
                              North Central Zone Cultural Center Prayagraj,
                              Uttar Pradesh.
                            </li>
                            <li>
                              Member: Member Secretary Search Cum Selection
                              Committee, Indira Gandhi National Center for the
                              Arts, New Delhi.
                            </li>
                            <li>
                              Member: Vice-Chancellor’s Search Cum Selection
                              Committee, Baba Saheb Ambedkar Education
                              University, West Bengal.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="headingMajorScholarshipsAwards"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseMajorScholarshipsAwards"
                          aria-expanded="false"
                          aria-controls="collapseMajorScholarshipsAwards"
                        >
                          Major Scholarships and Awards
                        </button>
                      </h2>
                      <div
                        id="collapseMajorScholarshipsAwards"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingMajorScholarshipsAwards"
                        data-bs-parent="#profileAccordion"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li>
                              International Book of Honor, England Award (2023)
                            </li>
                            <li>
                              Rajmata Shrimati Shanti Devi Himachal Gaurav
                              Samman (2012).
                            </li>
                            <li>
                              Jeevan Sadhana Alankaran in the field of Education
                              by INH-24, Haribhoomi (2023).
                            </li>
                            <li>
                              Jain Mitra Award by Shri Digambar Jain Teerth
                              Kshetra, Nainagiri (2003).
                            </li>
                            <li>
                              Vidwat Samman by All India Jain Vidwat Parishad
                              (2022).
                            </li>
                            <li>
                              Life Time Achievement Award by Indian Association
                              of Commerce and Management (2012).
                            </li>
                            <li>
                              Vivekananda Seva Alankaran Samman by Bharat Vikas
                              Parishad (2019).
                            </li>
                            <li>
                              Guru Vashishtha Samman by Shree Narayana Sanskrit
                              Chetna Nyas, Noida (2018).
                            </li>
                            <li>
                              Amity Academic Excellence Award by Amity Education
                              Group India (2017).
                            </li>
                            <li>
                              Vishwa Hindi Seva Samman by Vishwa Hindi Manch,
                              Guangzhou (China) (2016).
                            </li>
                            <li>
                              Vishwa Tulsi Samman by Shri Tulsi Janmabhoomi Nyas
                              and Sanatan Dharma Parishad, Uttar Pradesh (2023).
                            </li>
                            <li>
                              Radhakrishnan International Award by World Peace
                              International Federation, Ahmedabad (2014).
                            </li>
                            <li>
                              Ambassador for Peace Award by Universal Peace
                              Federation (2014).
                            </li>
                            <li>
                              Kautilya Best Economist Award by Uttar Pradesh
                              Uttarakhand Economics Association.
                            </li>
                            <li>
                              Sadhak Shiromani Alankaran by Kashi Vidwat
                              Parishad, Varanasi.
                            </li>
                            <li>UGC Junior and Senior Fellowship 1976-1980.</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="headingLecturesDelivered"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseLecturesDelivered"
                          aria-expanded="false"
                          aria-controls="collapseLecturesDelivered"
                        >
                          Lectures Delivered
                        </button>
                      </h2>
                      <div
                        id="collapseLecturesDelivered"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingLecturesDelivered"
                        data-bs-parent="#profileAccordion"
                      >
                        <div className="accordion-body">
                          <p>
                            More than 800 lectures at national and international
                            levels. The subjects of these lectures range from
                            Vedic literature to the dimensions of modern
                            knowledge, science, social science, philosophy, and
                            spirituality.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingPublications">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsePublications"
                          aria-expanded="false"
                          aria-controls="collapsePublications"
                        >
                          Publications
                        </button>
                      </h2>
                      <div
                        id="collapsePublications"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingPublications"
                        data-bs-parent="#profileAccordion"
                      >
                        <div className="accordion-body">
                          <p>
                            13 books including Arun Satsai (2013), Main Tumhare Sath Bhi Hoon, Mai Tumhare Paas Bhi Hoon (2023), 11 monographs, 62
                            research papers (in journals), 35 research papers
                            (in national and international conferences), 20
                            editorial contributions, Research papers etc. in 24
                            edited books
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
});

export default VcProfile;
