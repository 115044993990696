import { configure, makeAutoObservable } from "mobx"
import { axiosInstanceInt } from "../api/axiosInstance"
import secureLocalStorage from "react-secure-storage";
class TokenStore {
  loading = false
  errors = {}
  constructor() {
    makeAutoObservable(this);
    configure({
      useProxies: "never"
    })
  }
  toggleLoading = (toggle) => {
    this.loading = toggle
  }
  
  async clearAllAsyncStore() {
    secureLocalStorage.removeItem("userData");
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("type");
    secureLocalStorage.removeItem("read");
    secureLocalStorage.removeItem("admin-token");
    secureLocalStorage.removeItem("adminData");
  }

//   async getAccessToken(email) {
//     this.toggleLoading(true);
//     try {
//       const response = await axiosInstanceInt.post('/accessToken', email)
//       if (response) {
//         return response.data.token;
//       } else {
//         console.log("Failed...");
//       }
//     }
//     catch (err) {
//       console.log("error", err);
//     }
//     finally {
//       this.toggleLoading(false);
//     }
//   }
}

export default new TokenStore()
