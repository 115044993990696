import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert";
import adminStore from "../../../store/AdminStore";
import Spinner from '../../../elements/Spinner';
const AddWhatNew = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [whatsNew, setWhatsNew] = useState("");

  useEffect(() => {
    setWhatsNew(
      adminStore.data.whatsNew?.find((x) => x.id === location?.state?.id)
    );
  }, [location?.state?.id]);

  const addWhatsNew = (values) => {
    const formData = new FormData();

    

    if (!values.id) {
      adminStore.addWhatsNew(values, navigationCallBack);
    } else {
      formData.append("id", values.id);
      adminStore.updateWhatsNew( values.id);
    }
  };
  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/whatsnew");
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Added", "success");
    navigate("/admin/whatsnew");
  };

  const validationSchema = Yup.object({
    subject: Yup.string().required("Required"),
    last_date_on_home_page: Yup.string().required("Required"),
   
    link: Yup.string().required("Required"),
    status: Yup.boolean().required("Required"),
   
  });

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">Upload What's New</h3>
            <Formik
              initialValues={{
                subject: "",

                link: "",
                status: true,
                show_in_home_page: false,
                last_date_on_home_page: "",
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addWhatsNew(values);
              }}
            >
              {({ isSubmitting, setFieldValue, handleChange, values,errors}) => (
                <Form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="subject">Subject</label>
                        <ErrorMessage
                          name="subject"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                     {console.log(errors)}
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="date"
                          name="last_date_on_home_page"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="notice_date">Last Date On Home Page </label>
                        <ErrorMessage
                          name="last_date_on_home_page"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                    </div>
                    
                    
                  </div>

                  <div className="row mb-3">
                  <div className="col-md-6">
                  <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="link"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="link">Link</label>
                        <ErrorMessage
                          name="link"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      </div>
                    
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <Field
                            as="select"
                            name="status"
                            className="form-select"
                            placeholder=" "
                            onChange={handleChange}
                          >
                            <option value="">Not Set</option>
                            <option value={true}>Active</option>
                            <option value={false}>In-active</option>
                          </Field>
                          <label htmlFor="status">Status</label>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    
                  </div>
                  <div className="col-md-12">
                      <div className="form-check mb-3">
                        <Field
                          type="checkbox"
                          name="show_in_home_page"
                          className="form-check-input"
                          id="show_in_home_page"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="show_in_home_page"
                        >
                          Show On Home Page
                        </label>
                        <ErrorMessage
                          name="show_in_homepage"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default AddWhatNew;
