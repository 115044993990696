
// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Swal from "sweetalert";
import websiteStore from '../../store/WebsiteStore';

const ComplaintForm = observer(() => {

  const addComplaint = (values) => {
    console.log(values);
    if (!values.id) {
      websiteStore.addComplaint(values, navigationCallBack)
    }
  }
  const navigationCallBack = () => {
    Swal("Success!", "Successfully Deleted", "success");

  };
  return (
    <div>
      <Header />
      <section className="innerbanner" />
      <div className="container-fluid">
        <div className="w-100 d-flex row2">
          <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light text-center">
            <div>
              <h4 className="text-center mt-3 pb-3">Admission</h4>
            </div>
           
           <a target='_blank' href="https://exam.bucgexam.in/public/home/admission"> <button  className="btn btn-outline-primary">Click Here To Apply Online</button></a>
          </div>
          <div className="row1 mx-auto">
            <h3 className="main-head">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
              </svg>
             Complaint Form
            </h3>
            <div>
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  phone_no: '',
                  subject: '',
                  department_name: '',
                  complaint_description: '',
                  dropdown: '',
                }}
                onSubmit={(values) => {
                    addComplaint(values);

                }}
              >
                {({ handleChange }) => (
                  <Form>
                    <div className="form-group">
                        <label htmlFor="dropdown">Type of Applicant:</label>
                        <Field as="select" className="form-control" id="dropdown" name="dropdown">
                            <option value="">Select an option</option>
                            <option value="student">Student</option>
                            <option value="Employee">Employee</option>
                            <option value="Officers">Officers</option>
                            <option value="others">others</option>
                        </Field>
                        <ErrorMessage name="dropdown" component="div" className="text-danger" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Name:</label>
                      <Field type="text" className="form-control" id="name" name="name" placeholder="Enter your name" />
                      <ErrorMessage name="name" component="div" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email Address:</label>
                      <Field type="email" className="form-control" id="email" name="email" placeholder="Enter your email" />
                      <ErrorMessage name="email" component="div" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone_no">Phone Number:</label>
                      <Field type="tel" className="form-control" id="phone_no" name="phone_no" placeholder="Enter your phone number" />
                      <ErrorMessage name="phone_no" component="div" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="subject">Subject:</label>
                      <Field type="tel" className="form-control" id="subject" name="subject" placeholder="Enter your Subject" />
                      <ErrorMessage name="subject" component="div" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="department_name">College/Department Name:</label>
                      <Field type="tel" className="form-control" id="department_name" name="department_name" placeholder="Enter your department name" />
                      <ErrorMessage name="department_name" component="div" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="complaint_description">Complaint Description:</label>
                      <Field component="textarea" className="form-control" id="complaint_description" name="complaint_description" placeholder="Enter your complaint description" />
                      <ErrorMessage name="complaint_description" component="div" />
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={handleChange}>
                      Send Message
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </div>
  );
}
);

export default ComplaintForm;