import { configure, makeAutoObservable, runInAction } from "mobx"
import { axiosInstance, axiosInstanceInt, setAuthToken, setAuthTokenInt } from "../api/axiosInstance"
import { toast } from 'react-toastify';
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert';

class AdminStore {
  loading = false
  data = {
    jobs: [],
    events: [],
    pages: [],
    results: [],
    notifications: [],
    admins: [],
    departments: [],
    settings: [],
    departmentPages: [],
    sliders: [],
    mediaFiles: [],
    whatsNew: [],
    newsData: [],
    galleries: [],
    notificationsType: [],
    alumnies: [],
    impwebData: [],
    contactData: [],
    masterTabs:[],
    coursesData:[],
    courses:[],
    complaintForm:[],
    syllabus:[]

  }
  errors = {}
  constructor() {
    makeAutoObservable(this);
    configure({
      useProxies: "never"
    })
  }
  toggleLoading = (toggle) => {
    this.loading = toggle
  }
  toggleLoadingApply = (toggle) => {
    this.loadingApply = toggle
  }

  async updateAsyncStore(data) {
    secureLocalStorage.removeItem('userData');
    secureLocalStorage.removeItem('token');
    secureLocalStorage.setItem("admin-token", data.token);
    secureLocalStorage.setItem("type", data.role);
    secureLocalStorage.setItem("adminData", JSON.stringify(data));
  }
  updateTokenFromStorage(data, token) {
    this.data.admintoken = token
    this.data.name = data.name
    this.data.email = data.email
    this.data.id = data.id
    this.data.role = data.role
  }

  async login(creds, navigationCallBack, data, token) {
    if (token) {
      // setAuthTokenIntAdmin(token)
      this.updateTokenFromStorage(data, token)
    } else {
      this.toggleLoading(true);
      try {
        const response = await axiosInstance.post('/auth/login', creds)
        this.updateAsyncStore(response.data)
        runInAction(() => {
          this.data.admintoken = response?.data?.token;
          this.data.email = response?.data?.email;
          this.data.username = response?.data.username;
          this.data.id = response?.data?.id;
          this.data.dept_id = response?.data?.dept_id;
        })
        navigationCallBack();
      }
      catch (err) {
        if (err?.response?.status != 500) {
          if (err?.response?.data?.status === 0) {
            Swal("Pending!", "Your account is under review. You will be notified via mail once activated.", "warning");
            return false
          }
          if (err?.response?.data?.status === 2) {
            Swal("Rejected!", "Your account verification is rejected. Please contact Staffers administrator", "error");
            return false
          }
          if (err?.response?.data?.status === 3) {
            Swal("Blocked!", "Your account has been temporarily disabled. Please contact the Administrator of Staffers.", "error");
            return false
          }
          if (!err?.response?.data?.status) {
            toast.error(err?.response?.data?.message);
          }
        } else {
          toast.error("Something went wrong!");
        }
      }
      finally {
        this.toggleLoading(false);
      }
    }
  }
  //EVENTS
  async getEvents() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/event")
      if (response) {
        runInAction(() => {
          this.data.events = response?.data?.event;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addEvent(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-event', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async deleteEvent(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-event/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateEvent(formData, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-event', formData)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //PAGES
  async getPages() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/pages")
      if (response) {
        runInAction(() => {
          this.data.pages = response?.data?.pages;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async createPage(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-page', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updatePage(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-page', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async deletePage(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-page/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //RESULTS

  async addResult(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-result', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getResults() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/results")
      if (response) {
        runInAction(() => {
          this.data.results = response?.data?.results;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async deleteResult(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-result/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateResult(formData, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-result', formData)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  // notification
  async getNotifications() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/notifications")
      if (response) {
        runInAction(() => {
          this.data.notifications = response?.data?.notifications;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addNotification(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-notification', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateNotification(formData, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('update-notification', formData)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async deleteNotification(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-notification/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }


  //Adminlist
  async getAdminList() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/admins")
      if (response) {
        runInAction(() => {
          this.data.admins = response?.data?.admins;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async addNewAdminList(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-admin', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async deleteAdmin(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-admin/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateAdminList(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-admin', id)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  //department

  async getDepartments() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/department")
      if (response) {
        runInAction(() => {
          this.data.departments = response?.data?.departments;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async addNewDepartment(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-department', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async deleteDepartment(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-department/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateDepartment(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-department', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  //Settings

  async getSettings() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/settings")
      if (response) {
        runInAction(() => {
          this.data.settings = response?.data?.settings;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async createSetting(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-setting', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateSetting(formData, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-setting', formData)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }



  // department pages

  async getDepartmentPages() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/department-pages")
      if (response) {
        runInAction(() => {
          this.data.departmentPages = response?.data?.department_pages;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }


  async deleteDepartmentPage(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-department-page/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }


  async addNewDepartmentPage(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-department-page', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateDepartmentPage(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('department-update-page', id)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }


  // Sliders

  async getSliders() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/sliders")
      if (response) {
        runInAction(() => {
          this.data.sliders = response?.data?.sliders;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async addSlider(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-slider', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async deleteSlider(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-slider/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateSlider(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-slider', id)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Media Files
  async addMediaFiles(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('add-media-files', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      console.log(err);
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getMediaFiles() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/get-media-files")
      if (response) {
        runInAction(() => {
          this.data.mediaFiles = response?.data?.media_files;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async deleteMediaFile(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-media-file/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //What's new
  async getWhatsNew() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/whats-new")
      if (response) {
        runInAction(() => {
          this.data.whatsNew = response?.data?.whats_new;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async addWhatsNew(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-whats-new', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      console.log(err);
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async deleteWhatsNew(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-whats-new/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //NEWS
  async addNews(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('add-news', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      console.log(err);
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getNews() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/news")
      if (response) {
        runInAction(() => {
          this.data.newsData = response?.data?.news;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async deleteNews(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-news/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateNews(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-news', id)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  //photo-video-gallery
  async getPhotoVideoGallery() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/photo-video")
      if (response) {
        runInAction(() => {
          this.data.galleries = response?.data?.photoVideo;
        })
      } else {
        toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }


  async addNewGallery(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('add-photo-video', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async deleteGallery(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-photo-video/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateDepartment(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-department', id)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getNotificationType() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/notice-type")
      if (response) {
        runInAction(() => {
          this.data.notificationsType = response?.data?.noticeType;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async deleteNotificationType(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-photo-video/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Alumnies
  async addAlumnies(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-alumni', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      console.log(err);
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateAlumnies(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-alumni', id)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async deleteAlumnies(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-alumni/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getAlumnies() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/alumnies")
      if (response) {
        runInAction(() => {
          this.data.alumnies = response?.data?.alumnies;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  //IMP Website
  async getImpWebsite() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("imp-web")
      if (response) {
        runInAction(() => {
          this.data.impwebData = response?.data?.impweb;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addURL(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('add-imp-web', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      console.log(err);
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }


  async deleteAddURL(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-imp-web/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateURL(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-url', id)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }


  // contacts

  async addContact(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('create-contact', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      console.log(err);
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateContact(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-contact', id)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async deleteContact(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/delete-contact/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getContacts() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/contacts")
      if (response) {
        runInAction(() => {
          this.data.contactData = response?.data?.contacts;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }


  // complaint

  async getComplaint() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/get-complaints")
      if (response) {
        runInAction(() => {
          this.data.complaintForm = response?.data?.complaint;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async complaintPost() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/add-complaint")
      if (response) {
        runInAction(() => {
          this.data.complaintForm = response?.data?.complaint;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  
  // get master tab

  async getMasterTabs() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/master")
      if (response) {
        runInAction(() => {
          this.data.masterTabs = response?.data?.master;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  // syllabus

  async getSyllabus() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/all-syllabus")
      if (response) {
        runInAction(() => {
          this.data.syllabus = response?.data?.syllabus;
        })
      } else {
        toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async deleteSyllabus(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/syllabus/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addSyllabus(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('add-syllabus', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      console.log(err);
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateSyllabus(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-syllabus', id)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  // Courses

  async getCourses() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/course-type")
      if (response) {
        runInAction(() => {
          this.data.courses = response?.data?.course;
        })
      } else {
        toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async deleteCourse(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/course-type/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addCourse(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('add-course-type', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      console.log(err);
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateCourse(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-course-type', id)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  // Courses

  async getCourseData() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/course-data")
      if (response) {
        runInAction(() => {
          this.data.coursesData = response?.data?.coursedata;
        })
      } else {
        toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async deleteCourseData(id, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.delete(`/course-data/${id}`)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addCourseData(param, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('add-course-data', param)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      console.log(err);
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateCourseData(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put('update-course-data', id)
      if (response) {
        navigationCallBack()
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
}
const adminStore = new AdminStore()
export default adminStore;