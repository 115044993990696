import React from 'react';
import PropTypes from 'prop-types';

const PlaceholderLoader = ({ width,height }) => {
  const loaderStyle = {
    width: width || '40vw', // Default to '40vw' if no width is provided
    height:height ||'10vw'
  };

  return (
    <div className="placeholder-loader" style={loaderStyle}>
      <div className="loader"></div>
      <div className="loader"></div>
      <div className="loader"></div>
      <div className="loader"></div>
    </div>
  );
};

PlaceholderLoader.propTypes = {
  width: PropTypes.string,
};

export default PlaceholderLoader;
