// src/pages/DashboardPage.js
import React, { useState } from "react";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

import { useEffect } from "react";
import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import { toJS } from "mobx";
import moment from "moment/moment";
import Swal from "sweetalert";
import { BASE_FILE_URL } from "../../../api/config";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../elements/Spinner";
const ResultList = observer(() => {
  const [resultList, setResultList] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    adminStore.getResults();
  }, []);
  useEffect(() => {
    setResultList(toJS(adminStore.data.results));
  }, [adminStore.data.results]);

  const handleEdit = (row) => {
    navigate('/admin/upload-result', { state: { id: row.id } })
  };

  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this page!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          adminStore.deleteResult(row.id, navigationCallBack)

        }
      });
  }
  const navigationCallBack = () => {
    Swal("success", "successfully", "success");
    adminStore.getResults();
  }
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: '60px',
    },
    {
      name: "Course Name",
      selector: (row) => row.course_name,
      sortable: true,

    },
    {
      name: "File",
      selector: (row) => (
        <a
          href={BASE_FILE_URL + row.notification_file}
          target="_blank"
          rel="noopener noreferrer"
        ><i class="fa-regular fa-folder-open pe-2 "></i><span className="text-uppercase">{row?.notification_file?.substring(row?.notification_file.lastIndexOf('.') + 1)}</span></a>
      ),
      sortable: true,
    },
    {
      name: "Creation Time",
      selector: (row) => moment(row.created_at).format('DD/MM/YYYY hh:mm A'),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={() => handleEdit(row)}
          >
            <i className="far fa-edit"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDelete(row)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div >
        <main className="main">
          <Header />
          <div>
            <div className="content-wrapper p-3 bg-white mt-3  d-flex justify-content-between">
              <h3 className="page-title">
                <span className="page-title-icon linear me-2">
                  <i className="fas fa-user-friends"></i>
                </span>{" "}
                Result Records
              </h3>
              <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
                <a
                  href="/admin/upload-result"
                  className="page-title signup-link fs-4 pointer"
                >
                  <span className="page-title-icon linear ">
                    <i className="fas fa-plus"></i>
                  </span>
                  Add New Result
                </a>
              </div>
            </div>
            <CustomDataTable columns={columns} data={resultList}  />
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default ResultList;
