


import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

import adminStore from '../../../store/AdminStore';
import Spinner from '../../../elements/Spinner';



const AddNoticeCategory = () => {
  const [content, setContent] = useState('');

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    notice_category: Yup.mixed().required('Required'),
  });

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div >
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">Upload Notice Category</h3>
            <Formik
              initialValues={{
                name: '',
                notice_category: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                values.content = content;
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field type="text" name="name" className="form-control" placeholder=" " />
                        <label htmlFor="name">Name</label>
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field as="select" name="notice_category" className="form-select" placeholder=" ">
                          <option value="">Select Notice Type</option>
                          <option value="draft">Draft</option>
                          <option value="published">Published</option>
                          <option value="archived">Archived</option>
                        </Field>
                        <label htmlFor="notice_category">Notice Type</label>
                        <ErrorMessage name="notice_category" component="div" className="text-danger" />
                      </div>
                    </div>

                  </div>

                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        {/* <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                    modules={modules}
                    formats={formats}
                  /> */}
                        <ErrorMessage name="content" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25" disabled={isSubmitting}>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
};

export default AddNoticeCategory;
