// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";

import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import { toJS } from "mobx";
import moment from "moment";
import Swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import CKEditor from 'ckeditor4-react';
import Sidebar from "../../admin/components/Sidebar";
import Header from "../../admin/components/Header";
import Spinner from "../../../elements/Spinner";
import secureLocalStorage from "react-secure-storage";


const DepartmentPages = observer(() => {
  const navigate = useNavigate()
  const [departmentPages, setDepartmentPages] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [deptId, setDeptId] = useState(null);

  useEffect(() => {
    adminStore.getDepartmentPages();
    adminStore.getDepartments();
    let userData = secureLocalStorage.getItem('adminData')
    if (userData) {
      setDeptId(JSON.parse(userData)?.dept_id)
    }
  }, []);

  useEffect(() => {
    if (!deptId) {
      setDepartmentPages(toJS(adminStore.data.departmentPages))
    } else {
      setDepartmentPages(toJS(adminStore.data.departmentPages)?.filter(x => x.dept_id == deptId))
    }
  }, [adminStore.data.departmentPages, deptId]);


  useEffect(() => {
    setDepartments(toJS(adminStore.data.departments));
  }, [adminStore.data.departments]);

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Deleted!", "success");
    adminStore.getDepartmentPages();
  }

  const handleEdit = (row) => {
    navigate('/admin/createdepartmentpage', { state: { id: row.id } });
  }

  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this page!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          adminStore.deleteDepartmentPage(row.id, navigationCallBack)

        }
      });
  }

  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      width: '60px',
    },
    {
      name: 'Department',
      selector: row => departments?.find(x => x.id === row.dept_id)?.department_name,
      sortable: true,
      width: '300px',
      wrap: true,
    },
    {
      name: 'Page Name',
      selector: row => row.page_name,
      sortable: true,

    },
    {
      name: 'Nav Tab Name',
      selector: row => row.tab_name,
      sortable: true,

    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      width: "100px",
    },
    {
      name: 'Creation Time',
      selector: row => moment(row.created_at).format('DD/MM/YYYY'),
      sortable: true,

    },
    {
      name: 'Action',
      cell: (row) => (
        <div className="d-flex align-items-center">
          <button className="btn btn-sm btn-outline-primary me-2" onClick={() => handleEdit(row)}><i className="far fa-edit"></i></button>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleDelete(row)}><i className="far fa-trash-alt"></i></button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div >
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Department Page
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a href="/admin/createdepartmentpage" className="page-title signup-link fs-4 pointer">
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Create Department Pages
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={departmentPages} />

        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>

  );
});

export default DepartmentPages;
