/* eslint-disable */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import BaseRouter from "./routes";
import { observer } from "mobx-react";
import websiteStore from "./store/WebsiteStore";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const App = observer(() => {
  // Initialize React Ga with your tracking ID

  useEffect(() => {
    websiteStore.getPages();
    websiteStore.getEvents();
    websiteStore.getResults();
    websiteStore.getNews();
    websiteStore.getNotifications();
    websiteStore.getWhatsnew();
    websiteStore.getSettings();
    websiteStore.getSlider();
    websiteStore.getGallery();
    websiteStore.getImpWebs();
    websiteStore.getSyllabus();

  }, []);

  return (
    <React.Fragment>
      <ToastContainer position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light" />
      <div className="social">

      <a href="https://www.youtube.com/@BilaspuruniversityAcInBSP" target="blank">
          <div className="social-btn color-youtube">
            <p className="order-1 google-font margin-telgram">Youtube</p>
            <i class="fa-brands fa-youtube"></i>
          </div>
        </a>

        
        <a href="https://whatsapp.com/channel/0029Vajsb2L9Bb5w2dNK1o27" target="blank">
          <div className="social-btn color-telegram">
            <p className="order-1 google-font margin-telgram">WhatsApp</p>
            <i class="fa-brands fa-whatsapp"></i>
          </div>
        </a>

        
        <a href="https://x.com/Abvvbilaspurcg?t=FbZcAtWGr5gc7kRX7_2mmg&s=08" target="blank">
          <div className="social-btn color-instagram">
            <p className="order-1 google-font margin-instagram">X.com</p>
            <i class="fa-brands fa-x-twitter text-light"></i>
          </div>
        </a>

        
        <a href="http://instagram.com/abvvbilaspur?utm_source=qr" target="blank">
          <div className="social-btn color-whatsapp">
            <p className="order-1 google-font margin-instagram">Instagram</p>
            <i class="fa-brands fa-instagram"></i>

          </div>
        </a>
      </div>




      <Router>
        <BaseRouter />
      </Router>
    </React.Fragment>
  );
});
export default App;

