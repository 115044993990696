import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { toJS } from "mobx";
import websiteStore from "../store/WebsiteStore";
import { BASE_FILE_URL } from "../api/config";
import moment from "moment";

const TableView = () => {
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    subject: "",
    noticeDate: "",
  });

  useEffect(() => {
    let data = [];
    switch (location?.state?.type) {
      case "notifications":
        data = toJS(websiteStore.data.notifications);
        break;

      case "results":
        data = toJS(websiteStore.data.results);
        break;
      case "DepartmentNotificatons":
        data = toJS(websiteStore.data.notifications)?.filter(
          (x) => x.dept_id === location?.state?.dept_id
        );
        break;
      case "Time Table":
        data = toJS(websiteStore.data.notifications)?.filter(
          (x) => x.notice_type === "Time Table"
        );
        break;
      case "Latest Notifications":
        data = toJS(websiteStore.data.notifications)?.filter(
          (x) => x.notice_type === "Latest Notification"
        );
        break;

      case "College Notifications":
        data = toJS(websiteStore.data.notifications)?.filter(
          (x) => x.notice_type === "College-Notification"
        );
        break;
      case "UTD Notice":
        data = toJS(websiteStore.data.notifications)?.filter(
          (x) =>
            x.notice_type === "UTD Notification"
        );
        break;
      case "Academic Calender":
        data = toJS(websiteStore.data.notifications)?.filter(
          (x) => x.notice_type === "Academic Calender"
        );
        break;
      case "Exam Notifications":
        data = toJS(websiteStore.data.notifications)?.filter(
          (x) => x.notice_type === "Exam Notifications"
        );
        break;
      case "Admission Notification":
        data = toJS(websiteStore.data.notifications)?.filter(
          (x) => x.notice_type === "Admission Notification"
        );
        break;
      case "Download":
        data = toJS(websiteStore.data.notifications)?.filter(
          (x) => x.notice_type === "Download"
        );
        break;
      case "NIRF":
        data = toJS(websiteStore.data.notifications)?.filter(
          (x) => x.notice_type === "NIRF"
        );
        break;
      case "Visitors View":
        data = toJS(websiteStore.data.notifications)?.filter(
          (x) => x.notice_type === "Visitors View"
        );
        break;
      case "Events":
        data = toJS(websiteStore.data.events);
        break;
      case "News":
        data = toJS(websiteStore.data.news);
        break;
      default:
      // handle default case if needed
    }
    setTableData(data);
    setFilteredData(data);
  }, [location?.state?.type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  useEffect(() => {
    const filtered = tableData.filter((item) => {
      const subjectMatch = (() => {
        switch (location?.state?.type) {
          case "results":
            return item.course_name
              .toLowerCase()
              .includes(filters.subject.toLowerCase());
          case "notifications":
          case "DepartmentNotificatons":
          case "Latest Notifications":
          case "College Notifications":
          case "Time Table":
          case "UTD Notice":
          case "Exam Notifications":
          case "Admission Notification":
          case "Download":
          case "NIRF":
          case "Visitors View":
            return item.title
              .toLowerCase()
              .includes(filters.subject.toLowerCase());
          case "Events":
            return item.event_name
              .toLowerCase()
              .includes(filters.subject.toLowerCase());
          case "News":
            return item.subject
              .toLowerCase()
              .includes(filters.subject.toLowerCase());
          default:
            return true;
        }
      })();
      const noticeDateMatch = filters.noticeDate
        ? moment(item.created_at).format("DD/MM/YYYY") === filters.noticeDate
        : true;
      return subjectMatch && noticeDateMatch;
    });
    setFilteredData(filtered);
  }, [filters, tableData, location?.state?.type]);

  return (
    <div>
      <Header />
      <div className="table-responsive">
        <div className="panel panel-primary filterable">
          <div className="panel-heading">
            <h3
              className="panel-title"
              style={{ textAlign: "center", fontFamily:"auto", fontWeight:"700", fontSize:"xx-large",backgroundColor:"#fff",padding:"10px" }}
            >
              {location?.state?.type.toUpperCase()==="DEPARTMENTNOTIFICATONS"?"DEPARTMENT NOTIFICATONS":location?.state?.type.toUpperCase()}
            </h3>
            {/* <div className="pull-right">
              <button
                className="btn btn-default btn-xs btn-filter"
                style={{ backgroundColor: "blue", color: "white" }}
              >
                <span className="glyphicon glyphicon-filter" /> Filter
              </button>
            </div> */}
          </div>
          <table className="table">
            <thead>
              <tr className="filters">
                <th style={{ width: "10%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="S No."
                    disabled=""
                  />
                </th>
                <th style={{ width: "60%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    name="subject"
                    value={filters.subject}
                    onChange={handleFilterChange}
                  />
                </th>
                <th style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Notice Date"
                    name="noticeDate"
                    value={filters.noticeDate}
                    onChange={handleFilterChange}
                  />
                </th>
                <th style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="View/Download"
                    disabled=""
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>
                    {(() => {
                      switch (location?.state?.type) {
                        case "results":
                          return item.course_name;
                        case "notifications":
                        case "Latest Notifications":
                        case "College Notifications":
                        case "DepartmentNotificatons":
                        case "Time Table":
                        case "UTD Notice":
                        case "Exam Notifications":
                        case "Admission Notification":
                        case "Download":
                        case "NIRF":
                        case "Visitors View":
                          return item.title;
                        case "Events":
                          return item.event_name;
                        case "News":
                          return item.subject;
                        default:
                          return "";
                      }
                    })()}
                  </td>
                  <td>{moment(item.notice_date).format("DD/MM/YYYY")}</td>
                  <td>
                    {(() => {
                      switch (location?.state?.type) {
                        case "results":
                        case "notifications":
                        case "Latest Notifications":
                        case "College Notifications":
                        case "DepartmentNotificatons":
                        case "Time Table":
                        case "UTD Notice":
                        case "Exam Notifications":
                        case "Download":
                        case "NIRF":
                        case "Visitors View":
                        case "Admission Notification":
                          return (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={BASE_FILE_URL + item.notification_file}
                            >
                              <button
                                className="btn btn-primary"
                                style={{ backgroundColor: "#0b559f" }}
                              >
                                {" "}
                                View/Download
                              </button>
                            </a>
                          );
                        case "Events":
                          return (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={BASE_FILE_URL + item.brochure_file}
                            >
                              <button
                                className="btn btn-primary"
                                style={{ backgroundColor: "#0b559f" }}
                              >
                                {" "}
                                View/Download
                              </button>
                            </a>
                          );
                        case "News":
                          return (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={BASE_FILE_URL + item.pdf_file}
                            >
                              <button
                                className="btn btn-primary"
                                style={{ backgroundColor: "#0b559f" }}
                              >
                                {" "}
                                View/Download
                              </button>
                            </a>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TableView;
