// utils/ContactUtils.js
import React from 'react';
import { components } from 'react-select';
import * as Yup from 'yup';

export const FloatingLabelOption = ({ children, ...props }) => (
  <components.Option {...props}>
    <span style={{ fontSize: 12, color: '#aaa', position: 'absolute', top: -10, left: 10 }}>
      {children}
    </span>
  </components.Option>
);


export const validationSchemaPage = Yup.object({
  page_name: Yup.string().required('Required'),
  page_data: Yup.string().required('Required'),
  tab_name: Yup.string().required('Required'),
  status: Yup.string().required('Required')
});

export const validationSchemaUploadResult = Yup.object({
  course_name: Yup.string().required('Required'),
  course_type: Yup.string().required('Required'),
  result_date: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
  file_type: Yup.string().required('Required'),
  notification_file: Yup.mixed().required('Required'),
  result_link: Yup.string().url('Invalid URL').required('Required'),
  reval_retot_link: Yup.string().url('Invalid URL').required('Required'),
  show_in_homepage: Yup.boolean().required('Required')
});


export const validationSchemaAddEvent = Yup.object({
  // event_name: Yup.string().required("Required"),
  // event_level: Yup.string().required("Required"),
  // no_of_participants: Yup.string().required("Required"),
  // convener: Yup.string().required("Required"),
  // event_date: Yup.string().required("Required"),
  // start_date: Yup.string().required("Required"),
  //  venue: Yup.string().required("Required"),
  // brochure_file: Yup.mixed().required("Required"),
  // report_hindi: Yup.mixed().required("Required"),
  // report_english: Yup.mixed().required("Required"),
  // last_date: Yup.string().required("Required"),
  // dept_id: Yup.string().required("Required"),
  // photo_gallery_id: Yup.string().required("Required"),
  // video_gallery_id: Yup.string().required("Required"),
  // thumbnail_image_url: Yup.mixed().required("Required"),
  // status: Yup.string().required("Required"),
  // event_mode: Yup.string().required("Required"),
  show_in_homepage: Yup.boolean().required("Required"),
});
export const validationSchemaAddAdmin = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  status: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  rights: Yup.array().min(1, 'At least one right must be selected').required('Required'),

});
export const validationSchemaUpdateAdmin = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  status: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  rights: Yup.array().min(1, 'At least one right must be selected').required('Required'),
});

export const adminTabs = [
  
  {
  "tab_name": "Dashboard",
  "tab_value": "dashboard"
},
{
  "tab_name": "Pages",
  "tab_value": "pages"
},
{
  "tab_name": "Results",
  "tab_value": "results"
},
{
  "tab_name": "Notice Type",
  "tab_value": "notice-type"
},
{
  "tab_name": " Notices",
  "tab_value": "notices"
},
{
  "tab_name": "Slider",
  "tab_value": "slider"
},
{
  "tab_name": "Photo Gallery",
  "tab_value": "PhotoGallery"
},
{
  "tab_name": "Media File",
  "tab_value": "mediafile"
},
{
  "tab_name": "Whats New",
  "tab_value": "whatsnew"
},
{
  "tab_name": "Departments",
  "tab_value": "departments"
},

{
  "tab_name": "Imp Website",
  "tab_value": "impwebsite"
},
{
  "tab_name": "News List",
  "tab_value": "newslist"
},
{
  "tab_name": "Events List",
  "tab_value": "eventslist"
},
{
  "tab_name": "Contact",
  "tab_value": "contact"
},
{
  "tab_name": "Feedback",
  "tab_value": "feedback"
},
{
  "tab_name": "Alumnies",
  "tab_value": "alumnies"
},
{
  "tab_name": "Admin List",
  "tab_value": "admin-list"
},

{
  "tab_name": "Settings",
  "tab_value": "settings"
},

{
  "tab_name": "Department Page",
  "tab_value": "department-page"
},



]