import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BASE_FILE_URL } from "../api/config";

const DeptCarousel = ({images,height, width}) => {
    console.log(images,"images");
  return (
    <Carousel
    swipeable={true}
    emulateTouch={true}
    stopOnHover={true}
    showThumbs={false}
    autoPlay
    interval="3000"
    transitionTime="300"
    infiniteLoop
  >
    {images?.map((item, index) => {
      return (
        <div key={index} style={{width:"100%", padding:"0px 22px"}}>
          <img
          style={{overflow:"hidden"}}
            height={height}
            width={width}
            src={item.url}
            alt={item.alt}
          />



          {/* { item.type === 'video' ? (
          <ReactPlayer 
            url={item.url} 
            width={width} 
            height={400}
            controls 
          />
        ) : null} */}
          {item.caption && <p className="legend">{item.caption}</p>}
        </div>
      );
    })}
    </Carousel>
  );
};

export default DeptCarousel;