import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import CKEditor from "ckeditor4-react";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { BASE_FILE_URL } from "../../../api/config";
import moment from "moment";
import Spinner from "../../../elements/Spinner";


const AddNewAlumnies = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [alumnies, setAlumnies] = useState("");

  useEffect(() => {
    setAlumnies(
      adminStore.data.alumnies?.find((x) => x.id === location?.state?.id)
    );
  }, [location?.state?.id]);

  const addNewAlumnies = (values) => {
    console.log(values);
    if (!values.id) {
      adminStore.addAlumnies(values, navigationCallBack);
    } else {
  
      adminStore.updateAlumnies(values, navigationCallBackUpdate);
    }
  };

  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/alumnies");
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Created", "success");
    navigate("/admin/alumnies");
  };

  const validationSchema = Yup.object({
    full_name: Yup.string().required("Required"),
    father_name: Yup.string().required("Required"),
    date_of_birth: Yup.mixed().required("Required"),
    contact_no: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    year_of_passing: Yup.string().required("Required"),
    department: Yup.string().required("Required"),
    course_completed: Yup.string().required("Required"),
    current_organization: Yup.string().required("Required"),
    current_designation: Yup.string().required("Required"),
    postal_address: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
  });

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">{alumnies?.id?"Update New Alumni":"Create Alumni"}</h3>
            <Formik
              initialValues={{
                id: alumnies?.id || null,
                full_name: alumnies?.full_name || "",
                father_name: alumnies?.father_name || "",
                date_of_birth: alumnies?.date_of_birth || "",
                contact_no: alumnies?.contact_no || "",
                email: alumnies?.email || "",
                year_of_passing: alumnies?.year_of_passing || "",
                department: alumnies?.department || "",
                course_completed: alumnies?.course_completed || "",
                current_organization: alumnies?.current_organization || "",
                current_designation: alumnies?.current_designation || "",
                postal_address: alumnies?.postal_address || "",
                status: alumnies?.status || "true",
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addNewAlumnies(values);
              }}
            >
              {({ setFieldValue, errors, handleChange, values }) => (
                <Form>
                  {console.log(errors)}

                  <div className="row mb-3">
                    <div className="col-md-6 mt-4">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="full_name"
                          className="form-control"
                          placeholder=" "
                        />
                        <label htmlFor="full_name">Full Name</label>
                        <ErrorMessage
                          name="full_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 mt-4">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="father_name"
                          className="form-control"
                          placeholder=" "
                        />
                        <label htmlFor="father_name">Father Name</label>
                        <ErrorMessage
                          name="father_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="department"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="department">Department</label>
                        <ErrorMessage
                          name="department"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="course_completed"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="course_completed">
                          Course Completed
                        </label>
                        <ErrorMessage
                          name="course_completed"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="contact_no"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="contact_no">Contact No</label>
                        <ErrorMessage
                          name="contact_no"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="postal_address"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="postal_address">Address</label>
                        <ErrorMessage
                          name="postal_address"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-floating mb-3">
                        <Field
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="email">Email ID</label>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="date"
                          name="date_of_birth"
                          className="form-control"
                          placeholder=" "
                          value={moment(values.date_of_birth).format("YYYY-MM-DD")} // Format the date value
                          
                          onChange={handleChange}
                        />
                        <label htmlFor="date_of_birth">Date Of Birth </label>
                        <ErrorMessage
                          name="date_of_birth"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="year"
                          name="year_of_passing"
                          className="form-control"
                          placeholder=" "
                          value={moment(values.year_of_passing).format("YYYY")} // Format the date value
                          onChange={handleChange}
                        />
                        <label htmlFor="year_of_passing">
                          Year Of Passing{" "}
                        </label>
                        <ErrorMessage
                          name="year_of_passing"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    {console.log(values)}
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="current_organization"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="current_organization">
                          Current Organization
                        </label>
                        <ErrorMessage
                          name="current_organization"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="current_designation"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="current_designation">Designation</label>
                        <ErrorMessage
                          name="current_designation"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <Field
                        as="select"
                        name="status"
                        className="form-select"
                        placeholder=" "
                        onChange={handleChange}
                      >
                        <option value="">Not Set</option>
                        <option value={true}>Active</option>
                        <option value={false}>In-active</option>
                      </Field>
                      <label htmlFor="status">Status</label>
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default AddNewAlumnies;
