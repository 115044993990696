// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import { toJS } from "mobx";
import Swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import CarouselModel from "../../../components/CarouselModel";
import Spinner from "../../../elements/Spinner";


const Sliders = observer(() => {
  const [sliderPage, setSliderPage] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();

  useEffect(() => {
    adminStore.getSliders();
  }, [])

  useEffect(() => {
    setSliderPage(toJS(adminStore.data.sliders));
  }, [adminStore.data.sliders])

  const handleEdit = (row) => {
    navigate('/admin/addslider', { state: { id: row.id } });

  }
  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this page!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          adminStore.deleteSlider(row.id, navigationCallBack)

        }
      });

  }
  const navigationCallBack = () => {
    Swal("success", "successfully deleted", "success");
    adminStore.getSliders();
  }


  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
      width: "60px"
    },
    {
      name: 'Title',
      selector: (row) => row.title,
      sortable: true,

    },
    {
      name: 'Images',
      selector: (row) => row.images.length,
      sortable: true,


    },
    {
      name: 'Action',
      cell: (row) => (
        <div className="d-flex align-items-center">
          {/* <button className="btn btn-sm btn-outline-success me-2" onClick={() => openModal(row)}><i className="far fa-eye"></i></button> */}
          <button className="btn btn-sm btn-outline-primary me-2" onClick={() => handleEdit(row)}><i className="far fa-edit"></i></button>
          {row?.id !== 4 && <button className="btn btn-sm btn-outline-danger" onClick={() => handleDelete(row)}><i className="far fa-trash-alt"></i></button>}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const openModal = (data) => {
    setShowModal(true);
    setModalData(data)
  };
  const onClose = () => {
    setShowModal(false);
    setModalData()
  }

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div  >
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Sliders
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a href="/admin/addslider" className="page-title signup-link fs-4 pointer">
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Sliders
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={sliderPage} />
          {/* <CarouselModel items={modalData} footer={
            <div className="d-flex align-items-center">
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          }
          onClose={onClose}/> */}
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>

  );
});

export default Sliders;
