import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

import adminStore from '../../../store/AdminStore';
import Spinner from '../../../elements/Spinner';


const AddMenuContent = () => {
  const [content, setContent] = useState('');

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    menu_content: Yup.string().required('Required'),
    show_in_homepage: Yup.boolean().required('Required')
  });

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
        <Header />
          <div className="mt-3 content-wrapper p-3 bg-white">
            <h3 className="text-center mb-4">Add New Left Menu</h3>
            <Formik
              initialValues={{
                name: '',
                menu_content: '',
                show_in_homepage: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                values.content = content;
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-floating mb-3">
                        <Field type="text" name="name" className="form-control" placeholder=" " />
                        <label htmlFor="name">Name</label>
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                  <div className="col-md-12">
                      <div className="form-floating mb-3">
                        <Field type="text" name="menu_content" className="form-control" placeholder=" " />
                        <label htmlFor="menu_content">Menu Content</label>
                        <ErrorMessage name="menu_content" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-check mb-3">
                        <Field type="checkbox" name="show_in_homepage" className="form-check-input" id="show_in_homepage" />
                        <label className="form-check-label" htmlFor="show_in_homepage">
                          Show in Homepage
                        </label>
                        <ErrorMessage name="show_in_homepage" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        {/* <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                    modules={modules}
                    formats={formats}
                  /> */}
                        <ErrorMessage name="content" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25" disabled={isSubmitting}>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
};

export default AddMenuContent;