import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NewsTickerComponent from "../components/NewsTickerComponent";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import EventCarousel from "../components/EventCarousel";
import websiteStore from "../store/WebsiteStore";
import NewsTickerFlashComponent from "../components/NewsTickerFlashComponent";
import ReusableModal from "../components/ReusableModal";
import ResultsNewsTickerComponentt from "../components/ResultsNewsTickerComponentt";
import NewsCarousel from "../components/NewsCarousel";
import NewsList from "./admin/news/NewsList";
import CommonCarousel from "../components/CommonCarousel";
import { BASE_FILE_URL } from "../api/config";
import { Link, useNavigate } from "react-router-dom";
import PlaceholderLoader from "../elements/PlaceholderLoader";
// import Confetti from "react-confetti";
// import { useSpring, animated } from "react-spring";
// import { useWindowSize } from "react-use";

const HomePage = observer(() => {
  const navigate = useNavigate();
  const [eventsData, setEventsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [resultesData, setResultsData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [flashData, setFlashData] = useState([]);
  const [noticeData, setNoticeData] = useState([]);
  const [homeSlider, setHomeSlider] = useState([]);
  const [homeSettings, setHomeSettings] = useState([]);
  const [impWebs, setImpWebs] = useState([]);
  const [isCurtainOpen, setIsCurtainOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true); // State to control confetti
  // const { width, height } = useWindowSize();

  // Curtain animation
  // const leftCurtainStyles = useSpring({
  //   transform: isCurtainOpen ? "translateX(-100%)" : "translateX(0%)",
  //   config: { mass: 1, tension: 50, friction: 100 },

  // });

  // const rightCurtainStyles = useSpring({
  //   transform: isCurtainOpen ? "translateX(100%)" : "translateX(0%)",
  //   config: { mass: 1, tension: 50, friction: 100 },
  // });

  useEffect(() => {
    const curtainTimer = setTimeout(() => {
      setIsCurtainOpen(true); // Open the curtain after 2 seconds
    }, 2000);
    const confettiTimer = setTimeout(() => {
      setShowConfetti(false); // Stop the confetti after 5 seconds
    }, 7000); // 2 seconds for curtain open + 5 seconds for confetti

    return () => {
      clearTimeout(curtainTimer); // Cleanup curtain timer
      clearTimeout(confettiTimer); // Cleanup confetti timer
    };
  }, []);

  useEffect(() => {
    setHomeSettings(
      websiteStore.data.settings?.length > 0
        ? toJS(websiteStore.data.settings[0])
        : ""
    );
  }, [websiteStore.data.settings]);

  useEffect(() => {
    setEventsData(toJS(websiteStore.data.events));
  }, [websiteStore.data.events]);

  useEffect(() => {
    setImpWebs(toJS(websiteStore.data.impWebs));
  }, [websiteStore.data.impWebs]);

  useEffect(() => {
    setHomeSlider(toJS(websiteStore.data.webSliders));
  }, [websiteStore.data.webSliders]);

  useEffect(() => {
    const news = toJS(websiteStore.data.news).filter(
      (news) =>
        news.type === "university_in_news" || news.type === "atal_drishti"
    );
    setNewsData(news);
  }, [websiteStore.data.news]);

  useEffect(() => {
    setNotificationData(toJS(websiteStore.data.notifications));
  }, [websiteStore.data.notifications]);

  useEffect(() => {
    setFlashData(toJS(websiteStore.data.whatsnew));
  }, [websiteStore.data.whatsnew]);

  useEffect(() => {
    setNoticeData(toJS(websiteStore.data.notifications));
  }, [websiteStore.data.notifications]);
  useEffect(() => {
    setResultsData(toJS(websiteStore.data.results));
  }, [websiteStore.data.results]);

  const openModal = (data) => {
    setShowModal(true);
    setModalData(data);
  };
  const onClose = () => {
    setShowModal(false);
    setModalData();
  };

  const goToNotice = () => {
    navigate("/table-view", { state: { type: "notifications" } });
  };
  const goToLatestNotice = () => {
    navigate("/table-view", { state: { type: "Latest Notifications" } });
  };
  const goToCollegeNotice = () => {
    navigate("/table-view", { state: { type: "College Notifications" } });
  };
  const goToUTDNotice = () => {
    navigate("/table-view", { state: { type: "UTD Notice" } });
  };
  const goToResult = () => {
    navigate("/table-view", { state: { type: "results" } });
  };

  const goToTimeTable = () => {
    navigate("/table-view", { state: { type: "Time Table" } });
  };
  const goToEvents = () => {
    navigate("/table-view", { state: { type: "Events" } });
  };
  const goToNews = () => {
    navigate("/table-view", { state: { type: "News" } });
  };
  const goToAcademicCalender = () => {
    navigate("/table-view", { state: { type: "Academic Calender" } });
  };


  // let counter_numbers_arr = ;

  // console.log(homeSettings?.counter_numbers?.split(","),"homeSettings");

  // console.log(newsData,"Newsdata");

  const handleClose = () => {
    websiteStore.data.popModal = false
  };

  const [activeTab, setActiveTab] = useState("home");

  return (
    <div style={{ position: 'relative', overflowX: 'hidden' }}>
      {/* Curtain */}
      {/* {websiteStore.data.popModal && <> <animated.div
        style={{
          ...leftCurtainStyles,
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "50%",
          zIndex: 9999,
          background: "linear-gradient(90deg, #8B0000, #FF6347)", // Red curtain
          transition: 'clip-path 2s ease',
        }}
      />
        <animated.div
          style={{
            ...rightCurtainStyles,
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "50%",
            zIndex: 9999,
            background: "linear-gradient(270deg, #8B0000, #FF6347)", // Red curtain
            transition: 'clip-path 2s ease',
          }}
        />
        {showConfetti && <Confetti style={{ zIndex: 999, width: "100vw" }} />}</>} */}


      {/* Page Content */}
      <div
        style={{
          zIndex: 5,
          position: "relative",
          // textAlign: "center",
          color: "#fff",
        }}
      >
        <div>
          <Header />
          <main id="main-content">
            <div className="hero py-2 w-100" style={{ backgroundColor: "#fff" }}>
              <div
                className="sidesection p-2 mx-2"
                style={{
                  width: "25%",
                  height: "100%",
                  border: "1px solid rgb(239, 239, 239)",
                }}
              >
                <p className="d-flex flex-column gap-3">
                  <a
                    href="/nep"
                    target="_blank"
                    className="btn btn-light p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28px"
                      viewBox="0 0 576 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM288 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm211.3-43.3c-6.2-6.2-16.4-6.2-22.6 0L416 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z" />
                    </svg>
                    &nbsp;<a href="/nep"> NEP(National Education Policy)</a>
                  </a>
                  <a
                    type="button"
                    href="https://exam.bucgexam.in/"
                    target="_blank"
                    className="btn btn-light p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      width="34px"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" />
                    </svg>
                    &nbsp;
                    <a target="blank" href="https://exam.bucgexam.in/">
                      {" "}
                      Admission
                    </a>
                  </a>

                  <a
                    target="_blank"
                    href="https://exam.bucgexam.in/"
                    type="button"
                    className="btn btn-light  p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="28px"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM325.8 139.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-21.4 21.4-71-71 21.4-21.4c15.6-15.6 40.9-15.6 56.6 0zM119.9 289L225.1 183.8l71 71L190.9 359.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" />
                    </svg>
                    &nbsp;
                    <a
                      target="_blank"
                      href="https://exam.bucgexam.in/public/registratin/new_reg_admitted"
                    >
                      {" "}
                      Exam Form
                    </a>
                  </a>

                  <a
                    type="button"
                    onClick={() => goToTimeTable()}
                    target="_blank"
                    className="btn btn-light  p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="28px"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z" />
                    </svg>
                    &nbsp;
                    <a target="_blank" onClick={() => goToTimeTable()}>
                      {" "}
                      Time Table
                    </a>
                  </a>

                  <a
                    type="button"
                    href="/research"
                    className="btn btn-light  p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      width="34px"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" />
                    </svg>
                    &nbsp;
                    <a href="/research" target="_blank">
                      {" "}
                      Research
                    </a>
                  </a>

                  <a
                    type="button"
                    onClick={() => goToLatestNotice()}
                    className="btn btn-light  p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="28px"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192 160 64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V352l8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V300.4c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4V32zm-64 76.7V240 371.3C357.2 317.8 280.5 288 200.7 288H192V192h8.7c79.8 0 156.5-29.8 215.3-83.3z" />
                    </svg>
                    &nbsp;
                    <a onClick={() => goToLatestNotice()}> Latest Notification</a>
                  </a>
                </p>
              </div>
              <div
                id="carouselExampleRide"
                className="media-lenth"
                style={{ width: "50%" }}
              >
                {homeSlider?.find((x) => x.id == 4) ? <CommonCarousel
                  media={homeSlider?.find((x) => x.id == 4)}
                  height="500px"
                  width="100%"
                /> : <PlaceholderLoader width="100%" height={500} />}
              </div>
              <div
                className="sidesection p-2 mx-2"
                style={{
                  width: "25%",
                  height: "100%",
                  border: "1px solid rgb(239, 239, 239)",
                }}
              >
                <p className="d-flex flex-column gap-3">
                  <a
                    type="button"
                    href="https://rajbhavancg.gov.in/"
                    target="_blank"
                    className="btn btn-light p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="28px"
                    >
                      <path d="M240.1 4.2c9.8-5.6 21.9-5.6 31.8 0l171.8 98.1L448 104l0 .9 47.9 27.4c12.6 7.2 18.8 22 15.1 36s-16.4 23.8-30.9 23.8H32c-14.5 0-27.2-9.8-30.9-23.8s2.5-28.8 15.1-36L64 104.9V104l4.4-1.6L240.1 4.2zM64 224h64V416h40V224h64V416h48V224h64V416h40V224h64V420.3c.6 .3 1.2 .7 1.8 1.1l48 32c11.7 7.8 17 22.4 12.9 35.9S494.1 512 480 512H32c-14.1 0-26.5-9.2-30.6-22.7s1.1-28.1 12.9-35.9l48-32c.6-.4 1.2-.7 1.8-1.1V224z" />
                    </svg>
                    &nbsp;{" "}
                    <a href="https://rajbhavancg.gov.in/" target="blank">
                      Raj Bhavan Chhattisgarh
                    </a>
                  </a>

                  <a
                    type="button"
                    href="https://digilocker.meripehchaan.gov.in/signin/oauth_partner/%252Foauth2%252F1%252Fconsent%253Flogo%253D%2526amr%253Dpan%252Bdriving_licence%252Baadhaar%2526response_type%253Dcode%2526client_id%253D08627FE5%2526state%253D102%2526redirect_uri%253Dhttps%25253A%25252F%25252Fabc.digilocker.gov.in%25252Fsignup%25252Fcallback_data%2526scope%253Dopenid%252Bpicture%2526orgid%253D002585%2526txn%253D665ae4af17fadoauth21717232815%2526hashkey%253Db266c29576967f7deef98ab3d108bee6e03d7ceae0e944c8c33372e4def45074%2526requst_pdf%253DY%2526app_name%253DQWNhZGVtaWMgQmFuayBvZiBDcmVkaXRz%2526signup%253Dsignup"
                    target="_blank"
                    className="btn btn-light p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      width="28px"
                    >
                      <path d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z" />
                    </svg>
                    &nbsp;{" "}
                    <a
                      href="https://digilocker.meripehchaan.gov.in/signin/oauth_partner/%252Foauth2%252F1%252Fconsent%253Flogo%253D%2526amr%253Dpan%252Bdriving_licence%252Baadhaar%2526response_type%253Dcode%2526client_id%253D08627FE5%2526state%253D102%2526redirect_uri%253Dhttps%25253A%25252F%25252Fabc.digilocker.gov.in%25252Fsignup%25252Fcallback_data%2526scope%253Dopenid%252Bpicture%2526orgid%253D002585%2526txn%253D665ae4af17fadoauth21717232815%2526hashkey%253Db266c29576967f7deef98ab3d108bee6e03d7ceae0e944c8c33372e4def45074%2526requst_pdf%253DY%2526app_name%253DQWNhZGVtaWMgQmFuayBvZiBDcmVkaXRz%2526signup%253Dsignup"
                      target="_blank"
                    >
                      ABC ID / Digilocker
                    </a>
                  </a>

                  <a
                    type="button"
                    onClick={() => goToCollegeNotice()}
                    className="btn btn-light  p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="28px"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 0v64h64V96H64zm384 0H192v64H448V96zM64 224v64h64V224H64zm384 0H192v64H448V224zM64 352v64h64V352H64zm384 0H192v64H448V352z" />
                    </svg>
                    &nbsp;{" "}
                    <a onClick={() => goToCollegeNotice()}>
                      College Notifications{" "}
                    </a>
                  </a>

                  <a
                    type="button"
                    onClick={() => goToUTDNotice()}
                    target="_blank"
                    className="btn btn-light  p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="28px"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z" />
                    </svg>
                    &nbsp;
                    <a onClick={() => goToUTDNotice()} target="_blank">
                      {" "}
                      UTD Notifications
                    </a>
                  </a>

                  <a
                    type="button"
                    target="_blank"
                    onClick={() => goToAcademicCalender()}
                    className="btn btn-light  p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="28px"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z" />
                    </svg>
                    &nbsp;
                    <a target="_blank" onClick={() => goToAcademicCalender()}>
                      {" "}
                      Academic Calendar
                    </a>
                  </a>

                  <a
                    type="button"
                    onClick={() => goToResult()}
                    className="btn btn-light  p-3 fs-5 text-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28px"
                      viewBox="0 0 448 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z" />
                    </svg>
                    &nbsp;<a onClick={() => goToResult()}> Result</a>
                  </a>
                </p>
              </div>
            </div>
            {/* ======new tab start======= */}
            <div className="d-flex inline-tabs justify-content-around align-item-center mt-2 bg-white p-2 for-media">
              <Link

                to={"/student-corner?id=157"}
                className="btn btn-light  p-3 fs-5 text-start"
              >
                <i class="fa-brands fa-guilded"></i>

                <Link

                  to={"/student-corner?id=157"}
                >
                  {" "}
                  Eligilibility Certificate Form/Guide Line
                </Link>
              </Link>
              <a
                target="_blank"
                href="/recruiment"
                type="button"
                className="btn btn-light  p-3 fs-5 text-start"
              >
                <i class="fa-solid fa-asterisk"></i>

                <a
                  target="_blank"
                  href="/recruiment"
                >
                  {" "}
                  Recruitment
                </a>
              </a>
              <a
                target="_blank"
                href="/syllabus"
                type="button"
                className="btn btn-light  p-3 fs-5 text-start"
              >
                <i class="fa-solid fa-book-open"></i>

                <a
                  target="_blank"
                  href="/syllabus"
                >
                  {" "}
                  Syllabus
                </a>
              </a>
              <a
                target="_blank"
                href="/online-services"
                type="button"
                className="btn btn-light  p-3 fs-5 text-start"
              >
                <i class="fa-solid fa-globe"></i>

                <a
                  target="_blank"
                  href="/online-services"
                >
                  {" "}
                  Online Services
                </a>
              </a>


            </div>

            <div className="d-flex inline-tabs justify-content-around align-item-center bg-white p-2 for-media">
              <a
                target="_blank"
                href="https://e-atalgyansangum.ac.in/"
                type="button"
                className="btn btn-light  p-3 fs-5 text-start"
              >
                <i class="fa-solid fa-person-chalkboard"></i>

                <a
                  target="_blank"
                  href="https://e-atalgyansangum.ac.in/"
                >
                  {" "}
                  E-atal Gyan Sangam
                </a>
              </a>
              <Link
                to={"/student-corner?id=156"}
                className="btn btn-light  p-3 fs-5 text-start"
              >
                <i class="fa-solid fa-chalkboard"></i>

                <Link
                  to={"/student-corner?id=156"}
                >
                  {" "}
                  E-resouces for Teaching Learning
                </Link>
              </Link>
              <Link
                to={"/student-corner?id=158"}
                className="btn btn-light  p-3 fs-5 text-start"
              >
                <i class="fa-solid fa-graduation-cap"></i>

                <Link
                  to={"/student-corner?id=158"}
                >
                  {" "}
                  Convocation

                </Link>
              </Link>
              <Link
                to={"/student-corner?id=69"}
                className="btn btn-light  p-3 fs-5 text-start"
              >
                <i class="fa-solid fa-download"></i>

                <Link
                  to={"/student-corner?id=69"}
                >
                  {" "}
                  Download
                </Link>
              </Link>
            </div>
            {/* ======new tab end========== */}
            <div className="hero1 p-2 w-100">
              <div
                className="sidesection leader"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid rgb(239, 239, 239)",
                }}
              >
                <ul>
                  <li className="col">
                    <img
                      src={`${BASE_FILE_URL}uploads/media-files/1722244987844.jpg`}
                      alt="Hon'ble Chancellor"
                      title="Hon'ble Chancellor"
                    />
                    <h4>Shri Ramen Deka</h4>
                    <p>
                      Hon'ble
                      {'\n'}
                      Chancellor, <br /> ABVV, Bilaspur
                      <br />
                      <br />


                    </p>
                    <ul className="msg-link">
                      <li>
                        <a href="https://rajbhavancg.gov.in/" target="_blank">
                          Raj Bhavan
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://rajbhavancg.gov.in/honorable-governors/honorable-governors.php"
                          target="_blank"
                        >
                          Message
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="col">
                    <img src="image/CM.jpg" alt="cm" title="cm" />
                    <h4>Shri Vishnu Deo Sai</h4>
                    <p className="vcheight">
                      Hon'ble Chief Minister
                      <br />
                      Chhattisgarh
                      <br />
                      <br />
                    </p>
                    <ul id="vcLinks" className="msg-link">
                      <li>
                        <a
                          onClick={() =>
                            openModal({
                              type: "profile",
                              image: "image/CM.jpg",
                              name: "Shri Vishnu Deo Sai",
                              designation:
                                " Hon'ble Chief Minister, \n Chhattisgarh",
                              message: "",
                              href: "https://cmo.cg.gov.in/",
                            })
                          }
                        >
                          Profile
                        </a>
                      </li>
                      <li>
                        <a href="https://cmo.cg.gov.in/" target="blank">
                          Message
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="col">
                    <img
                      src="image/Prof-ADN-Bajpai_200x200.png"
                      alt="Vice-Chancellor"
                      title="Vice-Chancellor"
                    />
                    <h4>Prof. ADN Bajpai</h4>
                    <p className="vcheight">
                      Hon'ble Vice-Chancellor
                      <br />
                      ABVV, Bilaspur
                      <br />
                      <br />
                    </p>
                    <ul id="vcLinks" className="msg-link">
                      <li>
                        <a
                          onClick={() =>
                            openModal({
                              type: "profile",
                              image: "image/Prof-ADN-Bajpai_200x200.png",
                              name: "Prof. ADN Bajpai",
                              designation:
                                "Hon'ble Vice-Chancellor, \nABVV, Chhattisgarh",
                              message: "",
                              href: "/about-us?id=150",
                            })
                          }
                        >
                          Profile
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            openModal({
                              type: "message",
                              image: "image/Prof-ADN-Bajpai_200x200.png",
                              name: "Prof. ADN Bajpai",
                              designation:
                                "Hon'ble Vice-Chancellor, \nABVV, Chhattisgarh",
                              href: "/vc-profile",
                              message:
                                "To attain the knowledge in real sense is the first and last goal of human life but it is the difficult task to define the knowledge it has so many approaches. Bilaspur University has accepted a new vision to define and identify the knowledge that skill of each and every work will automatically realize the sense of knowledge, it means if we do our prescribed work with honestly and proper skill knowledge will nationally take a shape and a man will find it easily. Basically in Gita lord krishan suggested Arjun as a disciple that skillful action is itself a form of Yoga.It means Yoga does not mean ankle exercises of body or a physical practice but of Yoga doing a work skillfully is also a form of Yoga. actually doing a work with skill and sense of honesty is real attainment of knowledge which is ever pious.Skill of work contains so many elements in itself pure concentration and realless efforts can be counted its permanent components. Symbol of University is constituted to promote the real skill of work among the region of chhattisgarh. And ultimate goal of University is to creat the real skill in natives of chhattisgarh. University also wants to spread this immortal message that pious knowledge can be attained with a practice of skillful action.",
                            })
                          }
                        >
                          Message
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="hero2">
              <div className="w-100">
                <div className="col-md-12">
                  <div id="latestnews">
                    <div className="breaking-news-ticker bn-effect-scroll bn-direction-ltr">
                      <div className="bn-label" id="whatnew">
                        <span style={{ color: "white" }}>What's New</span>&nbsp;{" "}
                      </div>
                      <div className=" ">
                        <NewsTickerFlashComponent flashNews={flashData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hero3 p-2 w-100 d-flex gap-2"
              style={{ backgroundColor: "#fff" }}
            >
              <div style={{ width: "25%" }} className="sidesection">
                <div className="panel panel-default boxed">
                  <div className="panel-heading">
                    <h3 className="text-primary-emphasis text-center d-flex justify-content-center gap-2">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="28px"
                        fill="#03356E"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                      </svg>{" "}
                      Notifications
                    </h3>
                  </div>
                  <div>
                    <NewsTickerComponent newsData={noticeData} />
                  </div>
                  {/* {console.log(noticeData, "subhi")} */}

                  <div className="panel-footer my-3">
                    <a
                      className="btn btn-infobtn btn-info"
                      role="button"
                      onClick={() => goToNotice()}
                    >
                      View All Notices
                    </a>
                    {/* <section class="clearfix"></section> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 center-section">
                <div className="boxed">
                  {/* <iframe
                width="100%"
                height="100%"
                src={homeSettings?.youtube_url}
                title="Home Video"
                allowFullScreen=""
              /> */}
                  <iframe
                    width="100%"
                    height="100%"
                    src={homeSettings?.youtube_url}
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen=""
                  />
                </div>
              </div>
              <div className="sidesection" style={{ width: "25%" }}>
                <div className="panel panel-default boxed">
                  <div className="panel-heading">
                    <h3 className="text-primary-emphasis text-center d-flex justify-content-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="28px"
                        fill="#03356E"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M211 7.3C205 1 196-1.4 187.6 .8s-14.9 8.9-17.1 17.3L154.7 80.6l-62-17.5c-8.4-2.4-17.4 0-23.5 6.1s-8.5 15.1-6.1 23.5l17.5 62L18.1 170.6c-8.4 2.1-15 8.7-17.3 17.1S1 205 7.3 211l46.2 45L7.3 301C1 307-1.4 316 .8 324.4s8.9 14.9 17.3 17.1l62.5 15.8-17.5 62c-2.4 8.4 0 17.4 6.1 23.5s15.1 8.5 23.5 6.1l62-17.5 15.8 62.5c2.1 8.4 8.7 15 17.1 17.3s17.3-.2 23.4-6.4l45-46.2 45 46.2c6.1 6.2 15 8.7 23.4 6.4s14.9-8.9 17.1-17.3l15.8-62.5 62 17.5c8.4 2.4 17.4 0 23.5-6.1s8.5-15.1 6.1-23.5l-17.5-62 62.5-15.8c8.4-2.1 15-8.7 17.3-17.1s-.2-17.4-6.4-23.4l-46.2-45 46.2-45c6.2-6.1 8.7-15 6.4-23.4s-8.9-14.9-17.3-17.1l-62.5-15.8 17.5-62c2.4-8.4 0-17.4-6.1-23.5s-15.1-8.5-23.5-6.1l-62 17.5L341.4 18.1c-2.1-8.4-8.7-15-17.1-17.3S307 1 301 7.3L256 53.5 211 7.3z" />
                      </svg>
                      Results
                    </h3>
                  </div>
                  <ResultsNewsTickerComponentt results={resultesData} />
                  <div className="panel-footer my-3">
                    <a
                      className="btn btn-infobtn btn-info"
                      role="button"
                      onClick={() => goToResult()}
                    >
                      View All Results
                    </a>
                    {/* <section class="clearfix"></section> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hero3 p-2 w-100 d-flex gap-2"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="col-md-6 center-section ">
                <div className="boxed ">
                  <h3 className="text-primary-emphasis text-center d-flex align-items-center justify-content-center gap-2">
                    <i className="far fa-calendar-alt"></i>
                    Events
                    <a
                      className="btn btn-infobtn btn-info"
                      role="button"
                      onClick={() => goToEvents()}
                      style={{ position: "absolute", right: 5, color: "white" }}
                    >
                      View All Events
                    </a>
                  </h3>
                  <EventCarousel items={eventsData} />
                </div>
              </div>
              <div className="col-md-6 center-section">
                <div className="boxed">
                  <h3 className="text-primary-emphasis text-center d-flex justify-content-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="24.5px"
                      fill="#03356E"
                    >
                      <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z" />
                    </svg>
                    Recent Activity / News
                    <a
                      className="btn btn-infobtn btn-info"
                      role="button"
                      onClick={() => goToNews()}
                      style={{ position: "absolute", right: 5, color: "white" }}
                    >
                      View All News
                    </a>
                  </h3>
                  <NewsCarousel items={newsData} />
                </div>
              </div>
            </div>
            <div className="hero2 p-3 w-100">
              <div className="row stat">
                <div className="col-md-3">
                  <div className="boxgreen text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="34px"
                      fill="#fff"
                      style={{ marginTop: "-18px" }}
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                    </svg>
                    &nbsp;
                    <span
                      className="timer"
                      data-from={0}
                      data-to={homeSettings?.counter_numbers?.split(",")[0]}
                      data-speed={5000}
                      data-refresh-interval={50}
                      style={{ color: "white" }}
                    >
                      {homeSettings?.counter_numbers?.split(",")[0]}
                    </span>
                    <br />
                    <small > Number of Colleges</small>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="boxorange text-center ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      width="44px"
                      fill="#fff"
                      style={{ marginTop: "-18px" }}
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" />
                    </svg>
                    &nbsp;
                    <span
                      className="timer"
                      data-from={0}
                      data-to={homeSettings?.counter_numbers?.split(",")[1]}
                      data-speed={5000}
                      data-refresh-interval={50}
                      style={{ color: "white" }}
                    >
                      {homeSettings?.counter_numbers?.split(",")[1]}
                    </span>
                    <br />
                    <small>Number of Programs</small>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="boxblue text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="28px"
                      fill="#fff"
                      style={{ marginTop: "-18px" }}
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                    </svg>
                    &nbsp;
                    <span
                      className="timer"
                      data-from={0}
                      data-to={homeSettings?.counter_numbers?.split(",")[2]}
                      data-speed={4000}
                      data-refresh-interval={50}
                      style={{ color: "white" }}
                    >
                      {homeSettings?.counter_numbers?.split(",")[2]}
                    </span>
                    <br />
                    <small>Number of Seats</small>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="boxteal text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      width="38px"
                      fill="#fff"
                      style={{ marginTop: "-18px" }}
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                    </svg>
                    &nbsp;
                    <span
                      className="timer"
                      data-from={0}
                      data-to={homeSettings?.counter_numbers?.split(",")[3]}
                      data-speed={4000}
                      data-refresh-interval={50}
                      style={{ color: "white" }}
                    >
                      {homeSettings?.counter_numbers?.split(",")[3]}
                    </span>
                    <br />
                    <small>Number of Students</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero4">
              <div className="slider1">
                <h3
                  className="text-center d-flex justify-content-center gap-2"
                  style={{
                    borderBottom: "1px solid #03356E",
                    padding: "0 0 10px",
                    color: "#03356E",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="28px"
                    fill=" #03356E"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" />
                  </svg>
                  Important Websites
                </h3>
                <br />
                {/* {console.log(impWebs, "impweb")} */}
                <div className="slide-track1 w-100">
                  {impWebs.map((item, index) => (
                    <div key={index} className="slide1">
                      <a href={item.impweb_url} target="_blank">
                        <img src={BASE_FILE_URL + item.thumbnail_img} />
                      </a>
                    </div>
                  ))}
                  {impWebs.map((item, index) => (
                    <div key={index} className="slide1">
                      <a href={item.impweb_url} target="_blank">
                        <img src={BASE_FILE_URL + item.thumbnail_img} />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </main>
          <Footer />
          {showModal && (
            <ReusableModal
              title={modalData?.type}
              body={modalData}
              footer={
                <button
                  type="button"
                  onClick={onClose}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              }
              onClose={onClose}
            />
          )}

          {websiteStore.data.popModal && homeSettings?.show_notice && (
            <div
              className="modal fade show d-block"
              id="exampleModalCenter"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
              onClick={handleClose} // Close modal when clicking on the background
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} // Set dark background with opacity
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
                onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside the modal
                style={{ maxWidth: '50%' }}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      {homeSettings.popup_message_title}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    ></button>
                  </div>
                  <div className="modal-body">
                    {homeSettings.popup_image && (
                      <img
                        src={BASE_FILE_URL + homeSettings.popup_image}
                        className="img-fluid"
                        alt="Popup"
                      />
                    )}
                    {homeSettings.popup_page_content && (
                      <p className="mt-2">{homeSettings.popup_page_content}</p>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}



        </div>
      </div>
    </div>
  );
});
export default HomePage;
