// ReusableDataTable.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
const customStyles = {

  header: {
    style: {
      minHeight: '30px',
    },
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: '#0b559f',
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#0b559f',
      },
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#0b559f',
      },
    },
  },
};

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      type="text"
      placeholder="Filter"
      value={filterText}
      onChange={onFilter}
      className="form-control"
    />
    <button className='btn btn-sm btn-primary' onClick={onClear}>Clear</button>
  </div>
);

const CustomDataTable = ({ columns, data }) => {
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = data?.filter(item => {
    // Check if item exists and if any property value contains filterText (case insensitive)
    if (!item) return false; // Handle null or undefined item

    // Iterate over each property of the item
    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        // Check if the property value contains filterText (case insensitive)
        if (item[key] && item[key].toString().toLowerCase().includes(filterText.toLowerCase())) {
          return true; // Return true if found
        }
      }
    }
    return false; // Return false if not found in any property
  });


  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div style={{
      width: '100%', overflowX: 'auto', background: '#f8f9fa', scrollbarColor: 'lightgrey #f8f9fa',
      scrollbarWidth: 'thin',
    }}>
      <div style={{ minWidth: '900px' }}>
        <DataTable
          subHeaderWrap
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          pagination
          paginationPerPage={20}
          paginationRowsPerPageOptions={[25, 50, 100, 150]}
          highlightOnHover
          subHeaderAlign="right"
          responsive
          fixedHeader
          dense
        />
      </div>
    </div>
  );

};

CustomDataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      selector: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CustomDataTable;
