// src/pages/Home.js
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import websiteStore from "../../store/WebsiteStore";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../api/config";
import moment from "moment";

const CommitteeCell = observer(() => {
  const location = useLocation();

  const [selectorTab, setSelectorTab] = useState();
  const [committeesCells, setCommitteesCells] = useState([]);
  const [committeeCellData, setCommitteeCellData] = useState();
  const params = new URLSearchParams(location.search);
  const [filterTabName, setFilterTabName] = useState("");

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "tabName") setFilterTabName(value); // Handle tab name filter change
  };

  useEffect(() => {
    setCommitteeCellData(websiteStore.data.notifications);
  }, [websiteStore.data.notifications]);

  useEffect(() => {
    setCommitteesCells(
      toJS(
        websiteStore.data.pages?.filter((x) => x.tab_name === "committees-cell")
      )?.sort((a, b) => a.order - b.order)
    );
    if (
      websiteStore.data.pages?.length !== 0 &&
      !websiteStore.data.selectedSubTab && !params.get("id")
    ) {
      websiteStore.data.selectedSubTab = websiteStore.data.pages
        ?.filter((x) => x.tab_name === "committees-cell")
        ?.sort((a, b) => a.order - b.order)[0];
    }else{
      if (!websiteStore.data.selectedSubTab) {
        websiteStore.data.selectedSubTab = websiteStore.data.pages
        ?.filter((x) => x.tab_name === "committees-cell")
        ?.sort((a, b) => a.order - b.order)?.find(x => x.id == params.get("id"));

      }
    }
  }, [websiteStore.data.pages]);
  useEffect(() => {
    setSelectorTab(websiteStore.data.selectedSubTab);
  }, [websiteStore.data.selectedSubTab]);

  return (
    <div>
      <Header />
      <>
        <section className="innerbanner" />
        <div style={{ width: "100%" }}>
          <div className="container-fluid">
            <div className="row2">
              <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
                <div className=" d-flex flex-column align-items-start  px-3 pt-2 text-white">
                  <h3 className="main-head w-100">Committee Cell</h3>
                  <div className="form-group mb-2 w-100 d-flex" style={{gap:"15px"}}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Filter"
                    value={filterTabName}
                    name="tabName"
                    
                    onChange={handleFilterChange}
                    
                    
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" style={{width:"20px", opacity:"0.5"}} viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                </div>
                  {committeesCells?.length > 0 && (
                    <ul
                      className="nav  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
                      id="menu"
                    >
                      {committeesCells
                      .filter((item) =>
                        item.page_name
                          .toLowerCase()
                          .includes(filterTabName.toLowerCase())
                      )?.map((item, ind) => {
                        return (
                          <li key={ind} className="w-100">
                            <a
                              onClick={() => setSelectorTab(item)}
                              className={
                                selectorTab?.id === item.id
                                  ? "active-tab nav-link align-middle px-2"
                                  : "nav-link align-middle px-2"
                              }
                            >
                              <i className="fs-4 bi-house" />{" "}
                              <span className="d-sm-inline gd active-text">
                                {item?.page_name}
                              </span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <hr />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              {
                <div className="row1 mx-auto">
                  <h3 className="main-head">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                    </svg>
                    {selectorTab?.page_name}
                  </h3>
                  <div>
                    {selectorTab?.page_name ==
                    "Academic Calender" ? (
                      <div>
                        
                      </div>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectorTab?.page_data,
                        }}
                      />
                    )}
                    {selectorTab?.page_name ==
                      "Career Counseling And Placement" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Career Counseling And Placement"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Grievance Redressal Cell" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Students Grievance Redressal"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Academic Activity" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Academic Activity Cell"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Atal Bihari Vajpayee Vyakhyan Mala" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Atal Bihari Vajpayee Vyakhyan Mala"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Research Project" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Research Project"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Conference and Seminar" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Conference and Seminar"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Institutional Animal Ethics and Dissection Monitoring" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Institutional Animal Ethics and Dissection Monitoring"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Skill Development and Entrepreneurship" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Skill Development and Entrepreneurship"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Anti-Ragging" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Anti-Ragging"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Cultural Committee" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Cultural Committee"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Psychological Advisory" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Psychological Advisory"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Media Cell" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Media Cell"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Research Promotion" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Research Promotion"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Internal Complain Committee" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Internal Complain Committee"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Alumni Association" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Alumni Association"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Gender Sensitization and Women Welfare" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Gender Sensitization and Women Welfare"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Intellectual Property Rights" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Intellectual Property Rights"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Equal Opportunity Cell" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Equal Opportunity Cell"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "RTI Cell" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "RTI Cell"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "Minority Cell" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "Minority Cell"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                    {selectorTab?.page_name ==
                      "ST/SC/OBC Cell" ? (
                      <div>
                        <>
                          <table className="table table-hover table-striped" style={{marginTop:"20px"}}>
                            <thead>
                              <tr className="table-primary">
                                <th style={{width:"10%"}}>S No.</th>
                                <th style={{width:"50%"}}>Subject</th>
                                <th>Notice Date</th>
                                <th>View/Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {committeeCellData
                                ?.filter(
                                  (item) =>
                                    item.notice_type ===
                                    "ST/SC/OBC Cell"
                                )
                                .map((item, ind) => {
                                  return (
                                    <tr>
                                      <td style={{textAlign:"center"}}>{ind + 1}</td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <b>{item.title}</b>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        <b>{moment(item.notice_date).format("DD/MM/YY")} </b>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            BASE_FILE_URL +
                                            item.notification_file
                                          }
                                          target="_blank"
                                        >
                                          <button className="btn btn-primary w-80" style={{color:"white",backgroundColor:"#0b559f"}}>View/Download</button>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      </div>
                    ) : (
                      <div/>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </>
      <Footer />
    </div>
  );
});

export default CommitteeCell;
