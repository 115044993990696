// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import adminStore from "../../../store/AdminStore";
import { toJS } from "mobx";
import Swal from "sweetalert";
import { BASE_FILE_URL } from "../../../api/config";
import Spinner from "../../../elements/Spinner";

const PhotoGallery = observer(() => {
  const [galleryPage, setGalleryPage] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    adminStore.getPhotoVideoGallery();
  }, [])

  useEffect(() => {
    setGalleryPage(toJS(adminStore.data.galleries));
  }, [adminStore.data.galleries])
  // console.log(galleryPage,);

  const handleEdit = () => {
    // navigate('/admin/gallery', { state: { gallery_id: row.id } });
  };
  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this page!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {     
          adminStore.deleteGallery(row.id, navigationCallBack)
        }
      });

  };
  const navigationCallBack = () => {
    Swal("success", "successfully deleted", "success");
    adminStore.getPhotoVideoGallery();
  }

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Url",
      selector: (row) => row.video_url,
      sortable: true,
    },
    {
      name: "Images",
      selector: (row) => {
        // console.log(JSON.parse(row?.image_paths))
        return row?.image_paths ?  JSON.parse(row?.image_paths)?.map((image,index)=>{
          return <img className="rounded" height={50} width={50} src={BASE_FILE_URL + image} alt={image} />
        }):null
      },
      sortable: true,
    },
    {
      name: "Place",
      selector: (row) => row.place,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status === "true" ?"Active":"in-Active",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          {/* <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={() => handleEdit(row)}
          >
            <i className="far fa-edit"></i>
          </button> */}
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDelete(row)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div >
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Photo Video Gallery
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a
                href="/admin/addimages"
                className="page-title signup-link fs-4 pointer"
              >
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Photo Video Gallery
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={galleryPage} />
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default PhotoGallery;
