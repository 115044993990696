// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import { observer } from "mobx-react";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Department from "../../department/Department";
import Swal from "sweetalert";
import adminStore from "../../../store/AdminStore";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import { BASE_FILE_URL } from "../../../api/config";
import Spinner from "../../../elements/Spinner";
import secureLocalStorage from "react-secure-storage";

const Departments = observer(() => {
  const [department, setDepartment] = useState([]);
  const navigate = useNavigate();
  const [deptId, setDeptId] = useState(null);

  useEffect(() => {
    adminStore.getDepartments();
    let userData = secureLocalStorage.getItem('adminData')
    if (userData) {
      setDeptId(JSON.parse(userData)?.dept_id)
    }
  }, []);

  useEffect(() => {
    if (!deptId) {
      setDepartment(toJS(adminStore.data.departments));
    } else {
      setDepartment(toJS(adminStore.data.departments)?.filter(x => x.id == deptId))
    }
  }, [adminStore.data.departments, deptId]);

  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Department!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        adminStore.deleteDepartment(row.id, navigationCallBack);
      }
    });
  };

  const handleEdit = (row) => {
    navigate("/admin/addnewdepartment", { state: { department_id: row.id } });
  };

  const navigationCallBack = () => {
    Swal("success", "successfully deleted", "success");
    adminStore.getDepartments();
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "60px",
    },
    {
      name: "Image",
      selector: (row) => (
        <a href={BASE_FILE_URL + row.thumbnail_image} target="_blank">
          <img width={50} src={BASE_FILE_URL + row.thumbnail_image} />
        </a>
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Department",
      selector: (row) => row.department_name,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.department_code,
      sortable: true,
    },

    {
      name: "Slider Id",
      selector: (row) => row.slider_id || "No Slider",
      sortable: true,
      width: "100px",
    },
    {
      name: "Department Type",
      selector: (row) => row.dept_type,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.status === true ? "Active" : "In-active"),
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={() => handleEdit(row)}
          >
            <i className="far fa-edit"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDelete(row)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Department
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a
                href="/admin/addnewdepartment"
                className="page-title signup-link fs-4 pointer"
              >
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Department
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={department} />
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>
  );
});

export default Departments;
