// src/pages/Footer.jsx
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import websiteStore from '../store/WebsiteStore';
import { toJS } from 'mobx';
import { BASE_FILE_URL } from '../api/config';
import { useNavigate } from 'react-router-dom';

const Footer = observer(() => {
  const [footerSettings, setFooterSettings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setFooterSettings(websiteStore.data.settings?.length > 0 && toJS(websiteStore.data.settings[0]))
  }, [websiteStore.data.settings]);

  const goToExamNotice = () => {
    navigate("/table-view", { state: { type: "Exam Notifications" } });
  };
  const goToAdmissionNotice = () => {
    navigate("/table-view", { state: { type: "Admission Notification" } });
  };

  const goToTimeTable = () => {
    navigate("/table-view", { state: { type: "Time Table" } });
  };

  const goToResult = () => {
    navigate("/table-view", { state: { type: "results" } });
  };

  

  return (
    <div>
      <div className="dep-footer">
        <section
          className="d-flex justify-content-between p-4"
          style={{ backgroundColor: "#03356E" }}
        >
          {/* Left */}
          <div className="me-5">
            <span style={{ color: "#fff" }}>
              Get connected with us on social networks:
            </span>
          </div>
          {/* Left */}
          {/* Right */}
          <div>
            <a
              href={footerSettings?.fb_url}
              className="text-white me-4"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                fill="#fff"
                width="10px"
              >
                {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
              </svg>

            </a>
            <a
              href={footerSettings?.twitter_url}
              className="text-white me-4"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="#fff"
                width="16px"
              >
                {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                <path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
              </svg>
            </a>
            <a
              href={footerSettings?.youtube_url}
              className="text-white me-4"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                fill="#fff"
                width="18px"
              >
                {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                <path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
              </svg>
            </a>
          </div>
          {/* Right */}
        </section>
        <div className="footer-link">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-6 fbox border0">
                <h4>Address</h4>
                <div className="footer-section">
                  <div className="row">
                    <div className="col-md-1 col-sm-1 col-xs-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        width="12px"
                        fill="#fff"
                      >
                        <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                      </svg>
                    </div>
                    <div className="col-md-10 col-sm-10 col-xs-10">
                      <address>
                        {footerSettings?.university_address}
                      </address>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-1 col-sm-1 col-xs-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="#fff"
                      >
                        <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                      </svg>
                    </div>
                    <div className="col-md-10 col-sm-10 col-xs-10">
                      <a className="pointer text-white" href="tel:8889928648 ">+91 {footerSettings?.official_contact}</a>

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-1 col-sm-1 col-xs-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="#fff"
                      >
                        <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                      </svg>
                    </div>
                    <div className="col-md-10 col-sm-10 col-xs-10">


                      <a className="pointer text-white" href="mailto:info@bvvjdp.ac.com">{footerSettings?.official_email} </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-4 text-center hidden-xs address">
                <img
                  src={BASE_FILE_URL + footerSettings?.university_logo}
                  alt="logo"
                  className="img-responsive"
                  width={120}
                  style={{ borderRadius: "50%" }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <img
                  src="image/PM-UShA Logo-01.png"
                  className="img-responsive"
                  width={200}
                  style={{ marginTop: 20 }}
                />
                <p style={{color:"white"}}>
                  <strong>Atal Bihari Vajpayee Vishwavidyalaya</strong>
                  <br />© ABVV, Bilaspur (C.G.) - 495001, India
                </p>
              </div>
              <div className="col-md-2 col-sm-4 col-xs-6 fbox border0">
                <h4>Terms &amp; Policies</h4>
                <ul id="TermsAndPolicy" className="TermsAndPolicy">
                  <li className="">
                    <a className="" href="/disclaimer">
                      Disclaimer
                    </a>
                  </li>
                  <li className="">
                    <a className="" href="/privacy-policy">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="">
                    <a className="" href="/Copyright">
                      Copyright Policy
                    </a>
                  </li>
                  <li className="">
                    <a className="" href="terms-and-condition">
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li className="">
                    <a className="" href="/complaint-form">
                      Register Complaint
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 col-sm-4 col-xs-6 fbox border0 m-top-20">
                <h4>Useful Links</h4>
                <ul id="media" className="media d-flex flex-column">
                  <li className="">
                    <a className="" onClick={()=>goToAdmissionNotice()}>
                      Admission Notifications
                    </a>
                  </li>
                  <li className="">
                    <a className="" href="/syllabus">
                      Syllabus
                    </a>
                  </li>
                  <li className="">
                    <a className="" onClick={()=>goToExamNotice()}>
                      Exam Notifications
                    </a>
                  </li>
                  <li className="">
                    <a className="" onClick={()=>goToTimeTable()}>
                      Time Table
                    </a>
                  </li>
                  <li className="">
                    <a className=""onClick={()=>goToResult()}>
                      Results
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom external-url">
          <div className="container-fluid">
            <div className="row">
              <div className="text-center p-3" style={{ fontSize: 16}}>
                © 2024 Copyright:
                <a className="text-white" href="#">
                  Atal Bihari Vajpayee Vishwavidyalaya, Bilaspur
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
);

export default Footer;