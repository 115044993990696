import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { observer } from "mobx-react";
import websiteStore from "../../store/WebsiteStore";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../api/config";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

const VacanciesOfColleges = observer(() => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [filterTabName, setFilterTabName] = useState("");

  const [recruiment, setRecruiment] = useState([]);
  const [selectorTab, setSelectorTab] = useState();
  const [recruimentNotice, setRecruimentNotice] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20); // Default to 20 items per page

  // Filter state
  const [filters, setFilters] = useState({
    subject: "",
    month: "0",
    year: "0",
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "tabName") setFilterTabName(value); // Handle tab name filter change
  };

  useEffect(() => {
    setRecruimentNotice(toJS(websiteStore.data.notifications));
  }, [websiteStore.data.notifications]);

  useEffect(() => {
    const sortedSubPages = toJS(
      websiteStore.data.pages?.filter((x) => x.tab_name === "recruiment")
    )?.sort((a, b) => a.order - b.order);
    setRecruiment(sortedSubPages);
    if (
      websiteStore.data.pages?.length !== 0 &&
      !websiteStore.data.selectedSubTab && !params.get("id")
    ) {
      websiteStore.data.selectedSubTab = sortedSubPages[0];
    } else {
      if (!websiteStore.data.selectedSubTab) {
        websiteStore.data.selectedSubTab = sortedSubPages?.find(x => x.id == params.get("id"));
      }
    }
  }, [websiteStore.data.pages]);

  useEffect(() => {
    setSelectorTab(websiteStore.data.selectedSubTab);
  }, [websiteStore.data.selectedSubTab]);

  // Filter and paginate the notices
  const filteredNotices = recruimentNotice
    ?.filter((item) => item.notice_type === (selectorTab?.page_name || ""))
    .filter((item) =>
      item.title.toLowerCase().includes(filters.subject.toLowerCase())
    )
    .filter(
      (item) =>
        filters.month === "0" ||
        moment(item.notice_date).month() + 1 === parseInt(filters.month)
    )
    .filter(
      (item) =>
        filters.year === "0" ||
        moment(item.notice_date).year() === parseInt(filters.year)
    );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNotices?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredNotices?.length / itemsPerPage);

  return (
    <div>
      <Header />

      <section className="innerbanner" />
      <div style={{ width: "100%" }}>
        <div className="container-fluid">
          <div className="row2">
            <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light">
              <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <h3 className="main-head w-100">Recruitment</h3>
                <div className="form-group mb-2 w-100 d-flex" style={{gap:"15px"}}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Filter"
                    value={filterTabName}
                    name="tabName"
                    
                    onChange={handleFilterChange}
                    
                    
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" style={{width:"20px", opacity:"0.5"}} viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                </div>
                {recruiment?.length > 0 && (
                  <ul
                    className="nav mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
                    id="menu"
                  >
                    {recruiment
                      .filter((item) =>
                        item.page_name
                          .toLowerCase()
                          .includes(filterTabName.toLowerCase())
                      )?.map((item, ind) => (
                      <li key={ind} className="w-100">
                        <a
                          onClick={() => setSelectorTab(item)}
                          className={
                            selectorTab?.id === item.id
                              ? "active-tab nav-link align-middle px-2"
                              : "nav-link align-middle px-2"
                          }
                        >
                          <i className="fs-4 bi-house" />{" "}
                          <span className="d-sm-inline gd active-text">
                            {item?.page_name}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
                <hr />
              </div>
            </div>
            <div className="row1 mx-auto">
              <h3 className="main-head">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  {/* Font Awesome SVG Path */}
                  <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                </svg>
                {selectorTab?.page_name}
              </h3>
              <div>
                {selectorTab?.page_name === "Vacancies in University" && (
                  <div>
                    <form action="#">
                      <table className="table table-primary">
                        <tbody>
                          <tr>
                            <td>
                              <div className="form-group">
                                <label htmlFor="pageSize">
                                  Display Per Page
                                </label>
                                <select
                                  className="form-control"
                                  id="pagesize"
                                  value={itemsPerPage}
                                  onChange={(e) =>
                                    setItemsPerPage(
                                      parseInt(e.target.value, 10)
                                    )
                                  }
                                >
                                  {[
                                    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                                    60, 65, 70, 75, 80, 85, 90, 95, 100,
                                  ].map((size) => (
                                    <option key={size} value={size}>
                                      {size}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="name">Subject</label>
                                <input
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  placeholder="Subject"
                                  type="text"
                                  value={filters.subject}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      subject: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="month">Month</label>
                                <select
                                  className="form-control"
                                  id="month"
                                  name="month"
                                  value={filters.month}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      month: e.target.value,
                                    })
                                  }
                                >
                                  <option value="0">All</option>
                                  {[...Array(12).keys()].map((i) => (
                                    <option key={i + 1} value={i + 1}>
                                      {moment().month(i).format("MMMM")}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="year">Year</label>
                                <select
                                  className="form-control"
                                  id="year"
                                  name="year"
                                  value={filters.year}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      year: e.target.value,
                                    })
                                  }
                                >
                                  <option value="0">All</option>
                                  {[2024, 2023, 2022, 2021, 2020].map(
                                    (year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </td>
                            <td className="align-middle">
                              <div className="form-group">
                                <button
                                  className="btn btn-primary mt-4"
                                  style={{
                                    color: "white",
                                    backgroundColor: "#0b559f",
                                  }}
                                  type="button"
                                  onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                >
                                  Search!
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                    <table
                      className="table table-hover table-striped"
                      style={{ marginTop: "20px" }}
                    >
                      <thead>
                        <tr className="table-primary">
                          <th className="col-md-1">S No.</th>
                          <th className="col-md-7">Subject</th>
                          <th className="col-md-2">Notice Date</th>
                          <th>View/Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((item, ind) => (
                          <tr key={ind}>
                            <td>
                              {(currentPage - 1) * itemsPerPage + ind + 1}
                            </td>
                            <td>
                              <a
                                href={BASE_FILE_URL + item.notification_file}
                                target="_blank"
                              >
                                <b>{item.title}</b>
                              </a>
                            </td>
                            <td>
                              <b>
                                {moment(item.notice_date).format("DD/MM/YY")}
                              </b>
                            </td>
                            <td>
                              <a
                                href={BASE_FILE_URL + item.notification_file}
                                target="_blank"
                              >
                                <button
                                  className="btn btn-primary w-80"
                                  style={{
                                    color: "white",
                                    backgroundColor: "#0b559f",
                                  }}
                                >
                                  View/Download
                                </button>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <nav aria-label="Page navigation">
                      <ul className="pagination">
                        {[...Array(totalPages).keys()].map((page) => (
                          <li
                            key={page}
                            className={`page-item ${currentPage === page + 1 ? "active" : ""
                              }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => setCurrentPage(page + 1)}
                            >
                              {page + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                )}
                {selectorTab?.page_name === "Vacancies in Colleges" && (
                  <div>
                    <form action="#">
                      <table className="table table-primary">
                        <tbody>
                          <tr>
                            <td>
                              <div className="form-group">
                                <label htmlFor="pageSize">
                                  Display Per Page
                                </label>
                                <select
                                  className="form-control"
                                  id="pagesize"
                                  value={itemsPerPage}
                                  onChange={(e) =>
                                    setItemsPerPage(
                                      parseInt(e.target.value, 10)
                                    )
                                  }
                                >
                                  {[
                                    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                                    60, 65, 70, 75, 80, 85, 90, 95, 100,
                                  ].map((size) => (
                                    <option key={size} value={size}>
                                      {size}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="name">Subject</label>
                                <input
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  placeholder="Subject"
                                  type="text"
                                  value={filters.subject}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      subject: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="month">Month</label>
                                <select
                                  className="form-control"
                                  id="month"
                                  name="month"
                                  value={filters.month}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      month: e.target.value,
                                    })
                                  }
                                >
                                  <option value="0">All</option>
                                  {[...Array(12).keys()].map((i) => (
                                    <option key={i + 1} value={i + 1}>
                                      {moment().month(i).format("MMMM")}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="year">Year</label>
                                <select
                                  className="form-control"
                                  id="year"
                                  name="year"
                                  value={filters.year}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      year: e.target.value,
                                    })
                                  }
                                >
                                  <option value="0">All</option>
                                  {[2024, 2023, 2022, 2021, 2020].map(
                                    (year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </td>
                            <td className="align-middle">
                              <div className="form-group">
                                <button
                                  className="btn btn-primary mt-4"
                                  style={{
                                    color: "white",
                                    backgroundColor: "#0b559f",
                                  }}
                                  type="button"
                                  onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                >
                                  Search!
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                    <table
                      className="table table-hover table-striped"
                      style={{ marginTop: "20px" }}
                    >
                      <thead>
                        <tr className="table-primary">
                          <th className="col-md-1">S No.</th>
                          <th className="col-md-7">Subject</th>
                          <th className="col-md-2">Notice Date</th>
                          <th>View/Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((item, ind) => (
                          <tr key={ind}>
                            <td>
                              {(currentPage - 1) * itemsPerPage + ind + 1}
                            </td>
                            <td>
                              <a
                                href={BASE_FILE_URL + item.notification_file}
                                target="_blank"
                              >
                                <b>{item.title}</b>
                              </a>
                            </td>
                            <td>
                              <b>
                                {moment(item.notice_date).format("DD/MM/YY")}
                              </b>
                            </td>
                            <td>
                              <a
                                href={BASE_FILE_URL + item.notification_file}
                                target="_blank"
                              >
                                <button
                                  className="btn btn-primary w-80"
                                  style={{
                                    color: "white",
                                    backgroundColor: "#0b559f",
                                  }}
                                >
                                  View/Download
                                </button>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <nav aria-label="Page navigation">
                      <ul className="pagination">
                        {[...Array(totalPages).keys()].map((page) => (
                          <li
                            key={page}
                            className={`page-item ${currentPage === page + 1 ? "active" : ""
                              }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => setCurrentPage(page + 1)}
                            >
                              {page + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default VacanciesOfColleges;
