

export const BASE_API_URL = "https://abvv.ac.in/api/api/" //TEST
export const BASE_FILE_URL = "https://abvv.ac.in/api/" //TEST



// export const BASE_API_URL = "https://66-55-67-76.cloud-xip.com/api/" //TEST
// export const BASE_FILE_URL = "https://66-55-67-76.cloud-xip.com/" //TEST
//  export const BASE_API_URL = "http://192.168.29.49:8080/api/"  //TEST
//  export const BASE_FILE_URL = "http://192.168.29.49:8080/" //TEST

// export const BASE_API_URL = "http://192.168.29.88:8080/api/"  //TEST
// export const BASE_FILE_URL = "http://192.168.29.88:8080/" //TEST


