import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import Select from "react-select";
import Spinner from "../../../elements/Spinner";
import { BASE_FILE_URL } from "../../../api/config";

const AddNews = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [addNews, setAddNews] = useState(null);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    setAddNews(
      adminStore.data.newsData?.find((x) => x.id === location?.state?.id)
    );
  }, [location?.state?.id]);

  const createNews = (values) => {
    const formData = new FormData();
    formData.append("thumbnail_image", values.thumbnail_image);
    formData.append("type", values.type);
    formData.append("subject", values.subject);
    formData.append("dept_id", values.dept_id);
    formData.append("pdf_file", values.pdf_file);
    formData.append("expiry_date", values.expiry_date);
    formData.append("status", values.status);

    if (!values.id) {
      adminStore.addNews(formData, navigationCallBack);
    } else {
      formData.append("id", values.id);
      adminStore.updateNews(formData, navigationCallBackUpdate);
    }
    console.log(values,"values");
  };

  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/newslist");
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Added", "success");
    navigate("/admin/newslist");
  };

  // const [content, setContent] = useState("");
  const validationSchema = Yup.object({
    type: Yup.string().required("Required"),
    subject: Yup.string().required("Required"),
    // dept_id: Yup.string().required("Required"),
    pdf_file: Yup.string().required("Required"),
    expiry_date: Yup.string().required("Required"),
    status: Yup.boolean().required("Required"),
  });

  useEffect(() => {
    adminStore.getDepartments();
  }, []);

  useEffect(() => {
    setDepartments(toJS(adminStore.data.departments));
  }, [adminStore.data.departments]);



  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">Upload News</h3>
            <Formik
              initialValues={{
                id: addNews?.id,
                type: addNews?.type || "",
                subject: addNews?.subject || "",
                dept_id: addNews?.dept_id || "",
                pdf_file: addNews?.pdf_file || "",
                expiry_date: addNews?.expiry_date || "",
                status: addNews?.status || true,
                thumbnail_image:addNews?.thumbnail_image || "",
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                createNews(values);
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="thumbnail_image">Thumbnail</label>
                        <input
                          id="thumbnail_image"
                          name="thumbnail_image"
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file.size <= fileSizeLimit) {
                              setFieldValue("thumbnail_image", file);
                            } else {
                              alert("File size exceeds 2MB limit");
                            }
                          }}
                        />
                        <a href={BASE_FILE_URL + values?.thumbnail_image} target="_blank" >View</a>
                        <ErrorMessage
                          name="thumbnail_image"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="pdf_file">
                          Select File(pdf,png,jpg)
                        </label>
                        <input
                          multiple
                          id="pdf_file"
                          name="pdf_file"
                          type="file"
                          className="form-control"
                          onChange={(event) => {
                            setFieldValue(
                              "pdf_file",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        <a href={BASE_FILE_URL + values?.pdf_file} target="_blank" >View</a>
                        <ErrorMessage
                          name="pdf_file"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="type"
                          className="form-select"
                          placeholder=" "
                        >
                          <option value="">Select Type</option>
                          <option value="department_newsletter">
                            Department Newsletter
                          </option>
                          <option value="annual_report">Annual Report</option>
                          <option value="university_newsletter">
                            University Newsletter
                          </option>
                          <option value="university_in_news">
                            University in News
                          </option>
                          <option value="journal_and_proceeding">
                            Journal and Proceeding
                          </option>
                          <option value="nss_newsletter">NSS Newsletter</option>
                          <option value="atal_drishti">Atal Drishti</option>
                        </Field>
                        <label htmlFor="type">Type</label>
                        <ErrorMessage
                          name="type"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder=" "
                        />
                        <label htmlFor="subject">Subject</label>
                        <ErrorMessage
                          name="subject"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Select
                          value={
                            departments?.find((x) => x.id === values.dept_id) ||
                            ""
                          }
                          options={departments}
                          getOptionLabel={(option) => option.department_name}
                          getOptionValue={(option) => option.id}
                          onChange={(event) =>
                            setFieldValue("dept_id", event?.id)
                          }
                          placeholder="Select a department"
                          styles={{
                            control: (provided) => ({ ...provided, height: 58 }),
                            menu: (provided) => ({ ...provided, zIndex: 1000 }),
                          }}
                        />
                        <ErrorMessage
                          name="dept_id"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="date"
                          min={new Date()}
                          name="expiry_date"
                          className="form-control"
                          placeholder=" "
                        />
                        <label htmlFor="expiry_date">Expiry Date</label>
                        <ErrorMessage
                          name="expiry_date"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="status"
                          className="form-select"
                          placeholder=" "
                        >
                          <option value="">Not Set</option>
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </Field>
                        <label htmlFor="status">Status</label>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-check mb-3">
                        <Field
                          type="checkbox"
                          name="show_in_homepage"
                          className="form-check-input"
                          id="show_in_homepage"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="show_in_homepage"
                        >
                          Show in Homepage
                        </label>
                        <ErrorMessage
                          name="show_in_homepage"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>
  );
});

export default AddNews;
