// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import { toJS } from "mobx";
import moment from "moment/moment";
import { BASE_FILE_URL } from "../../../api/config";
import { toast } from "react-toastify";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Swal from 'sweetalert';
import Spinner from "../../../elements/Spinner";

const MediaFile = observer(() => {
  const [mediaFiles, setMediaFiles] = useState([]);

  useEffect(() => {
    adminStore.getMediaFiles();
  }, []);

  useEffect(() => {
    setMediaFiles(toJS(adminStore.data.mediaFiles));
  }, [adminStore.data.mediaFiles]);

  const onCopy = () => {
    toast.success("URL Copied")
  }
  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        adminStore.deleteMediaFile(row.id, navigationCallBack)
      }
    });
  }

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Deleted", "success");
    adminStore.getMediaFiles();
  }
  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      width: "60px",
    },
    {
      name: 'File',
      selector: row => row.filename ?

        (
          ['jpg', 'png', 'jpeg', 'gif'].includes(row.filename?.split('.').pop()) ? (
            <img
              className="rounded"
              height={50}
              width={50}
              src={BASE_FILE_URL + row.filename}
              alt={<a>{BASE_FILE_URL + row.filename}</a>}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <a href={BASE_FILE_URL + row.filename} target='_blank'>  <button className="btn btn-sm btn-success">View</button></a>
          )
        )

        : "No File",
      sortable: true,
      width: "100px",

    },
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,

    },

    {
      name: 'Status',
      selector: row => row.status ? "Active" : "In-active",
      sortable: true,
      width: "150px"

    },
    {
      name: 'Creation Time',
      selector: row => moment(row.created_at).format("DD/MM/YYYY hh:mm A"),
      sortable: true,

    },
    {
      name: 'Action',
      cell: (row) => (
        <div className="d-flex align-items-center">
          <CopyToClipboard onCopy={() => onCopy()} text={BASE_FILE_URL + row.filename}>
            <button className="btn btn-sm btn-outline-primary me-2"><i className="far fa-copy"></i></button>
          </CopyToClipboard>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleDelete(row)}><i className="far fa-trash-alt"></i></button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div  >
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Media Files
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a href="/admin/uploadmedia" className="page-title signup-link fs-4 pointer">
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add File
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={mediaFiles} />
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>

  );
});

export default MediaFile;
