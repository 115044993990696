// src/pages/Home.js
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import TopHeader from '../elements/TopHeader';
import LogoHeader from '../elements/LogoHeader';
import websiteStore from '../store/WebsiteStore';
import { Link, useNavigate } from 'react-router-dom';


const Header = observer(() => {
  const navigate = useNavigate()
  const [aboutSubPages, setAboutSubPages] = useState([]);
  const [administrations, setAdministrations] = useState([]);
  const [department, setDepartment] = useState([]);
  const [academics, setAcademics] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [studentCorner, setStudentCorner] = useState([]);
  const [centralLibrary, setCentralLibrary] = useState([]);
  const [media, setMedia] = useState([]);
  const [committeesCells, setCommitteesCells] = useState([]);
  const [IQAC, setIQAC] = useState([]);
  const [recruiment, setRecruiment] = useState([]);

  const [contactUs, setContactUs] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  // const [syllabusPage, setSyllabusPage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setAboutSubPages(websiteStore.data.pages?.filter(x => x.tab_name === 'about-us'))
    setAdministrations(websiteStore.data.pages?.filter(x => x.tab_name === 'administrations'))
    setAcademics(websiteStore.data.pages?.filter(x => x.tab_name === 'academics'))
    setAmenities(websiteStore.data.pages?.filter(x => x.tab_name === 'amenities'))
    setDepartment(websiteStore.data.pages?.filter(x => x.tab_name === 'department'))
    setStudentCorner(websiteStore.data.pages?.filter(x => x.tab_name === 'student-corner'))
    setCentralLibrary(websiteStore.data.pages?.filter(x => x.tab_name === 'central-library'))
    setMedia(websiteStore.data.pages?.filter(x => x.tab_name === 'media'))
    setCommitteesCells(websiteStore.data.pages?.filter(x => x.tab_name === 'committees-cell'))
    setIQAC(websiteStore.data.pages?.filter(x => x.tab_name === 'IQAC'))
    setRecruiment(websiteStore.data.pages?.filter(x => x.tab_name === 'recruiment'))

    setContactUs(websiteStore.data.pages?.filter(x => x.tab_name === 'contact-us'))
    // setSyllabusPage(websiteStore.data.pages?.filter(x => x.tab_name === 'syllabus'))
  }, [websiteStore.data.pages]);


  const loadSelectedData = (item, type) => {
    websiteStore.data.selectedSubTab = item;
    switch (type) {
      case 'about-us':
        websiteStore.data.selectedSubTab = item;
        navigate("/about-us");
        break;
      case 'administrations':
        websiteStore.data.selectedSubTab = item;
        navigate("/administrations");
        break;

      case 'academics':
        websiteStore.data.selectedSubTab = item;
        navigate("/academics");
        break;
      case 'amenities':
        websiteStore.data.selectedSubTab = item;
        navigate("/amenities");
        break;
      case 'student-corner':
        websiteStore.data.selectedSubTab = item;
        navigate("/student-corner");
        break;
      case 'central-library':
        websiteStore.data.selectedSubTab = item;
        navigate("/central-library");
        break;
      case 'media':
        websiteStore.data.selectedSubTab = item;
        navigate("/media");
        break;
      case 'recruiment':
        websiteStore.data.selectedSubTab = item;
        navigate("/recruiment");
        break;
      case 'committees-cells':
        websiteStore.data.selectedSubTab = item;
        navigate("/committees-cells");
        break;
      case 'department':
        websiteStore.data.selectedSubTab = item;
        navigate("/department");
        break;
      case 'IQAC':
        websiteStore.data.selectedSubTab = item;
        navigate("/IQAC");
        break;
      case 'onlineServices':
        websiteStore.data.selectedSubTab = item;
        navigate("/online-services");
        break;
      // case 'syllabus':
      //   websiteStore.data.selectedSubTab = item;
      //   navigate("/syllabus");
      //   break;
      case 'contactUs':
        websiteStore.data.selectedSubTab = item;
        navigate("/contact-us");
        break;
      default:
        websiteStore.data.selectedSubTab = "";
        console.log(`Unknown sub tab: ${item}`);
    }
  }

  const gotoHome = () => {
    navigate("/");
  }

  return (
    <div>
      <div className="w-100">
        <TopHeader />
        <LogoHeader />
        <div className={`main-nav w-100 ${isSticky ? 'sticky' : ''}`}>
          <nav className="navbar navbar-expand-lg navbar-dark gradient-custom">
            <div className="container-fluid w-100">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link text-white" aria-current="page" href="#" onClick={gotoHome}>
                      Home
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-white"
                      href={"#"}

                      aria-expanded="false"
                    >
                      About Us
                    </a>
                    {aboutSubPages?.length > 0 && <ul className="dropdown-menu">
                      {aboutSubPages?.slice(0, 5)?.map((item, ind) => {
                        return (
                          <li key={(ind)}>
                            <a className="dropdown-item py-2 " onClick={() => loadSelectedData(item, 'about-us')}>
                              {item?.page_name}
                            </a>
                          </li>
                        )
                      })}
                      {aboutSubPages?.length > 2 && (
                        <li>
                          <a href="/about-us" className="dropdown-item py-2 text-decoration-underline">
                            Show More
                          </a>
                        </li>
                      )}
                    </ul>}
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-white"
                      href="#"
                      aria-expanded="false"
                    >
                      Administration
                    </a>
                    {administrations?.length > 0 && <ul className="dropdown-menu">
                      {administrations?.sort((a, b) => a.order - b.order)?.slice(0, 4)?.map((item, ind) => {
                        return (
                          <li key={(ind)}>
                            <a className="dropdown-item py-2 " onClick={() => loadSelectedData(item, 'administrations')}>
                              {item?.page_name}
                            </a>
                          </li>
                        )
                      })}
                      {administrations?.length > 2 && (
                        <li>
                          <a href="/administrations" className="dropdown-item py-2 text-decoration-underline">
                            Show More
                          </a>
                        </li>
                      )}
                    </ul>}
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-white"
                      href="#"

                      aria-expanded="false"
                    >
                      Academics
                    </a>

                    {academics?.length > 0 && <ul className="dropdown-menu">
                      {academics?.slice(0, 4)?.map((item, ind) => {
                        return (
                          <li key={(ind)}>
                            <a className="dropdown-item py-2 " onClick={() => loadSelectedData(item, 'academics')}>
                              {item?.page_name}
                            </a>
                          </li>
                        )
                      })}
                      {academics?.length > 2 && (
                        <li>
                          <a href="/academics" className="dropdown-item py-2 text-decoration-underline">
                            Show More
                          </a>
                        </li>
                      )}
                    </ul>}
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-white"
                      href="#"

                      aria-expanded="false"
                    >
                      Amenities
                    </a>
                    {amenities?.length > 0 && <ul className="dropdown-menu">
                      {amenities?.slice(0, 4)?.map((item, ind) => {
                        return (
                          <li key={(ind)}>
                            <a className="dropdown-item py-2 " onClick={() => loadSelectedData(item, 'amenities')}>
                              {item?.page_name}
                            </a>
                          </li>
                        )
                      })}
                      {amenities?.length > 2 && (
                        <li>
                          <a href="/amenities" className="dropdown-item py-2 text-decoration-underline">
                            Show More
                          </a>
                        </li>
                      )}
                    </ul>}
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white" to={"/department"}>
                      Department
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-white"
                      href="#"

                      aria-expanded="false"
                    >
                      Central Library
                    </a>
                    {centralLibrary?.length > 0 && <ul className="dropdown-menu">
                      {centralLibrary?.slice(0, 4)?.map((item, ind) => {
                        return (
                          <li key={(ind)}>
                            <a className="dropdown-item py-2 " onClick={() => loadSelectedData(item, 'central-library')}>
                              {item?.page_name}
                            </a>
                          </li>
                        )
                      })}
                      {centralLibrary?.length > 2 && (
                        <li>
                          <a href="/central-library" className="dropdown-item py-2 text-decoration-underline">
                            Show More
                          </a>
                        </li>
                      )}
                    </ul>}
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-white"
                      href="#"

                      aria-expanded="false"
                    >
                      Student's Corner
                    </a>
                    {studentCorner?.length > 0 && <ul className="dropdown-menu">
                      {studentCorner?.slice(0, 4)?.map((item, ind) => {
                        return (
                          <li key={(ind)}>
                            <a className="dropdown-item py-2 " onClick={() => loadSelectedData(item, 'student-corner')}>
                              {item?.page_name}
                            </a>
                          </li>
                        )
                      })}
                      {studentCorner?.length > 2 && (
                        <li>
                          <a href="/student-corner" className="dropdown-item py-2 text-decoration-underline">
                            Show More
                          </a>
                        </li>
                      )}
                    </ul>}
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-white"
                      href="#"

                      aria-expanded="false"
                    >
                      Media
                    </a>
                    {media?.length > 0 && <ul className="dropdown-menu">
                      {media?.slice(0, 4)?.map((item, ind) => {
                        return (
                          <li key={(ind)}>
                            <a className="dropdown-item py-2 " onClick={() => loadSelectedData(item, 'media')}>
                              {item?.page_name}
                            </a>
                          </li>
                        )
                      })}
                      {media?.length > 2 && (
                        <li>
                          <a href="/media" className="dropdown-item py-2 text-decoration-underline">
                            Show More
                          </a>
                        </li>
                      )}
                    </ul>}
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-white"
                      href="#"

                      aria-expanded="false"
                    >
                      Committees/Cells
                    </a>
                    {committeesCells?.length > 0 && <ul className="dropdown-menu">
                      {committeesCells?.slice(0, 4)?.map((item, ind) => {
                        return (
                          <li key={(ind)}>
                            <a className="dropdown-item py-2 " onClick={() => loadSelectedData(item, 'committees-cells')}>
                              {item?.page_name}
                            </a>
                          </li>
                        )
                      })}
                      {committeesCells?.length > 2 && (
                        <li>
                          <a href="/committees-cells" className="dropdown-item py-2 text-decoration-underline">
                            Show More
                          </a>
                        </li>
                      )}
                    </ul>}
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link text-white" to={"/IQAC"}>
                      IQAC
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-white"
                      href="#"

                      aria-expanded="false"
                    >
                      Recruiment
                    </a>
                    {recruiment?.length > 0 && <ul className="dropdown-menu">
                      {recruiment.map((item, ind) => {
                        return (
                          <li key={(ind)}>
                            <a className="dropdown-item py-2 " onClick={() => loadSelectedData(item, 'recruiment')}>
                              {item?.page_name}
                            </a>
                          </li>
                        )
                      })}
                      {recruiment?.length > 2 && (
                        <li>
                          <a href="/recruiment" className="dropdown-item py-2 text-decoration-underline">
                            Show More
                          </a>
                        </li>
                      )}
                    </ul>}
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link text-white" to={"/contact-us"}>
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
);

export default Header;