// src/pages/Home.js
import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import DHeader from '../../../components/DHeader';
import Footer from '../../../components/Footer';
import { observer } from 'mobx-react';
import websiteStore from '../../../store/WebsiteStore';
import { useLocation, useNavigate } from 'react-router-dom';

const DepartmentSubPages = observer(() => {
  const [deptPageData, setDeptPageData] = useState();
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentId, setDepartmentId] = useState(null);
  const [activitySubPages, setActivitySubPages] = useState([]);
  const [deptName, setDeptName] = useState([]);

  useEffect(()=>{
    setDeptName(websiteStore.data.departments)
  },[websiteStore.data.deptPages, websiteStore.data.departments])

  const renderDepartmentContent = () => {
    if(deptPageData?.dept_id=="1"){
      return <p>Department of Computer Science</p>
    }
    if(deptPageData?.dept_id=="2"){
      return <p>Department of Commerce & Financial Studies</p>
    }
    if(deptPageData?.dept_id=="3"){
      return <p>Department of Food Processing & Technology</p>
    }
    if(deptPageData?.dept_id=="4"){
      return <p>Department of Hotel Management & Hospitality</p>
    }
    if(deptPageData?.dept_id=="5"){
      return <p>Department of Microbiology & Bio-informatics</p>
    }
    if(deptPageData?.dept_id=="6"){
      return <p>Department of Yoga</p>
    }
  };

  console.log(departmentId,"anik")

  const location = useLocation();
  useEffect(() => {
    setDepartmentData(
      websiteStore.data.departments?.find((x) => x.id === departmentId)
    );
  }, [websiteStore.data.departments, departmentId]);

  // useEffect(()=>{
  //   websiteStore.getDeparments();
  // },[])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setDepartmentId(params.get("department_id"));
    if (websiteStore.data.departments?.length === 0) {
      websiteStore.getDeparments();
    }
    websiteStore.getDepartmentPages({ dept_id: params.get("department_id") });
  }, []);

  useEffect(() => {
    setDeptPageData(websiteStore.data.deptPages?.find(x => x.id === websiteStore.data.selectedDeptSubTab?.id))
    setActivitySubPages(websiteStore.data.deptPages?.filter(x => x.tab_name === 'activity'));
  }, [websiteStore.data.deptPages, websiteStore.data.selectedDeptSubTab]);
console.log(deptPageData,"departmentData");
  return (
    <div>
      <Header />
      <DHeader />
      <div>
        <h3
          style={{
            backgroundColor: "white",
            position: "relative",
            borderBottom: "2px solid #03356E",
            padding: 10,
          }}
          className="text-primary-emphasis text-center d-flex justify-content-start gap-2"
        >
          {deptName && 
          <div >
            {renderDepartmentContent()}
          </div>
        } / {deptPageData?.page_name}
        </h3>
        {deptPageData?.page_data && <div dangerouslySetInnerHTML={{ __html: deptPageData?.page_data }} />}
      </div>
      <Footer />
    </div>
  );
});

export default DepartmentSubPages;