import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { observer } from "mobx-react";
import websiteStore from "../../store/WebsiteStore";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../api/config";
import moment from "moment";

const Eresource = observer(() => {
 

  return (
    <div>
      <Header />
      <section className="innerbanner" />
      <div style={{ width: "100%" }}>
      <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h4>Enter keyword to search</h4>
                    <input type="text" className="form-control" placeholder="" />
                </div>
            </div>
            <div className="row table-container mt-3">
                <div className="col-md-12">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Website</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>COURSERA</td>
                                <td><a href="http://coursera.org">http://coursera.org</a></td>
                            </tr>
                            <tr>
                                <td>E-YATRA</td>
                                <td><a href="http://e-yatra.org">http://e-yatra.org</a></td>
                            </tr>
                            <tr>
                                <td>SPOKEN TUTORIAL IIT MUMBAI</td>
                                <td><a href="http://spoken-tutorial.org">http://spoken-tutorial.org</a></td>
                            </tr>
                            <tr>
                                <td>SWAYAM</td>
                                <td><a href="https://swayam.gov.in/">https://swayam.gov.in/</a></td>
                            </tr>
                            <tr>
                                <td>NIELIT</td>
                                <td><a href="https://www.nielit.in/elearning.aspx">www.nielit.in/elearning.aspx</a></td>
                            </tr>
                            <tr>
                                <td>E-LEARNING</td>
                                <td><a href="https://www.elearning.iirs.gov.in/">WWW.elearning.iirs.gov.in/</a></td>
                            </tr>
                            <tr>
                                <td>WEBLEARNING</td>
                                <td><a href="https://weblearning.nic.in/about.html">weblearning.nic.in/about.html</a></td>
                            </tr>
                            <tr>
                                <td>INFORMATION AND LIBRARY NETWORK</td>
                                <td><a href="https://www.inflibnet.ac.in/">www.inflibnet.ac.in/</a></td>
                            </tr>
                            <tr>
                                <td>E-PG PATHSHALA</td>
                                <td><a href="#">e-PG Pathshala</a></td>
                            </tr>
                            <tr>
                                <td>COURSERA</td>
                                <td><a href="https://www.coursera.org/">www.coursera.org/</a></td>
                            </tr>
                            <tr>
                                <td>KHAN ACADEMY</td>
                                <td><a href="https://www.khanacademy.org/">www.khanacademy.org/</a></td>
                            </tr>
                            <tr>
                                <td>EDX</td>
                                <td><a href="https://www.edx.org/">www.edx.org</a></td>
                            </tr>
                            <tr>
                                <td>NATIONAL PROGRAMME ON TECHNOLOGY ENHANCED LEARNING</td>
                                <td><a href="https://www.nptel.ac.in">www.nptel.ac.in</a></td>
                            </tr>
                            <tr>
                                <td>FIND TOP COLLEGES IN INDIA</td>
                                <td><a href="https://www.meracollege.net">www.meracollege.net</a></td>
                            </tr>
                            <tr>
                                <td>CENTRAL INFORMATION COMMISSION</td>
                                <td><a href="https://www.cic.gov.in">www.cic.gov.in</a></td>
                            </tr>
                            <tr>
                                <td>RIGHT TO INFORMATION ACT</td>
                                <td><a href="https://www.rti.gov.in">www.rti.gov.in</a></td>
                            </tr>
                            <tr>
                                <td>CONSORTIUM FOR EDUCATIONAL COMMUNICATION</td>
                                <td><a href="https://cec.nic.in">http://cec.nic.in/</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default Eresource;
