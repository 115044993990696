// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import adminStore from "../../../store/AdminStore";
import { toJS } from "mobx";
import Swal from "sweetalert";
import moment from "moment";
import Spinner from "../../../elements/Spinner";

const WhatsNew = observer(() => {
  const [whatsNew, setWhatsnew] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    adminStore.getWhatsNew();
  }, []);

  useEffect(() => {
    setWhatsnew(toJS(adminStore.data.whatsNew));
  }, [adminStore.data.whatsNew]);

  
  const handleDelete = (row) => {
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this whats new!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        adminStore.deleteWhatsNew(row.id, navigationCallBack);
      }
    });

  };
  const handleEdit = (row) => {
    navigate('/admin/whats-new"', { state: { whatsNew_id: row.id } });

  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Deleted", "success");
    adminStore.getWhatsNew();
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width:"60px"
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
      wrap: true,
      width:"200px"
    },
    {
      name: "Link",
      selector: (row) => row.link,
      sortable: true,
      width:"200px"
      
    },
    {
      name: "Last Date On Home Page",
      selector: (row) => moment(row.last_date_on_home_page).format("DD/MM/YYYY hh:mm A"),
      sortable: true,
      width:"200px"

    },
    {
      name: "Status",
      selector: (row) => row.status?"Active":"In-active",
      sortable: true,
      width:"200px"
    },
    {
      name: "Show On Home Page",
      selector: (row) => row.show_on_home_page?"Yes":"No",
      sortable: true,
      width:"200px"
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          {/* <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={() => handleEdit(row)}
          >
            <i className="far fa-edit"></i>
          </button> */}
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDelete(row)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
 
  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div  >
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              What's New
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a
                href="/admin/addwhatsnew"
                className="page-title signup-link fs-4 pointer"
              >
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New What'sNew
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={whatsNew} />
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default WhatsNew;
