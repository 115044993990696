import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert";
import adminStore from "../../../store/AdminStore";
import Spinner from "../../../elements/Spinner";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../../api/config";

const AddCourseData = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [addCourseData, setCourseData] = useState("");
  const [courseID, setCourseID] = useState([]);
  const [syllabusID, setSyllabusID] = useState([]);

  useEffect(() => {
    setCourseID(toJS(adminStore.data.courses));
  }, [adminStore.data.courses]);

  useEffect(() => {
    setSyllabusID(toJS(adminStore.data.syllabus));
  }, [adminStore.data.syllabus]);

  useEffect(() => {
    adminStore.getCourses();
    adminStore.getSyllabus();
  }, []);

  useEffect(() => {
    setCourseData(
      adminStore.data.coursesData?.find((x) => x.id === location?.state?.id)
    );
  }, [location?.state?.id]);

  const addNewCourseData = (values) => {
    const formData = new FormData();
    formData.append("course_id", values.course_id);
    formData.append("syllabus_id", values.syllabus_id);
    formData.append("course_name", values.course_name);
    formData.append("subject", values.subject);
    formData.append("published", values.published);
    formData.append("hindi_file_url", values.hindi_file_url);
    formData.append("eng_file_url", values.eng_file_url);
    formData.append("status", values.status);

    if (!values.id) {
      adminStore.addCourseData(formData, navigationCallBack);
    } else {
      formData.append("id", values.id);
      adminStore.updateCourseData(formData, navigationCallBackUpdate);
    }
  };

  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/course-data");
  };

  const navigationCallBack = () => {
    Swal("Success!", "Successfully Added", "success");
    navigate("/admin/course-data");
  };

  const validationSchema = Yup.object({
    course_id: Yup.string().required("Required"),
    syllabus_id: Yup.string().required("Required"),
    course_name: Yup.string().required("Required"),
    subject: Yup.string().required("Required"), 
    published: Yup.string().required("Required"),
    // hindi_file_url: Yup.mixed().required("Required"),
    // eng_file_url: Yup.mixed().required("Required"),
    status: Yup.boolean().required("Required"),
  });

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">{addCourseData?.id ?"Update Course Data" : "Add New Course Data"}</h3>
            <Formik
              initialValues={{
                id: addCourseData?.id,
                course_id: addCourseData?.course_id || "",
                course_name: addCourseData?.course_name || "",
                subject: addCourseData?.subject || "",
                published: addCourseData?.published || "",
                hindi_file_url: addCourseData?.hindi_file_url || "",
                eng_file_url: addCourseData?.eng_file_url || "",
                status: addCourseData?.status || true,
                syllabus_id: addCourseData?.syllabus_id || "",
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addNewCourseData(values);
              }}
            >
              {({ setFieldValue, handleChange, values, errors }) => (
                <Form>
                  <div className="row mb-3">
                    {console.log(errors)}
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="syllabus_id"
                          className="form-select"
                          placeholder=" "
                          onChange={(e) => {
                            const selectedSyllabusId = e.target.value;
                            setFieldValue("syllabus_id", selectedSyllabusId);
                            // Filter courseID based on selected syllabus_id
                            const filteredCourses = adminStore.data.courses?.filter(
                              (course) =>
                                course.syllabus_id === parseInt(selectedSyllabusId)
                            );
                            setCourseID(filteredCourses);
                            setFieldValue("course_id", ""); // Reset course_id value
                          }}
                        >
                          <option value="">Select Syllabus ID</option>
                          {syllabusID.map((syllabus) => (
                            <option key={syllabus.id} value={syllabus.id}>
                              {syllabus.syllabus_title}
                            </option>
                          ))}
                        </Field>
                        <label htmlFor="syllabus_id">Syllabus ID</label>
                        <ErrorMessage
                          name="syllabus_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="course_id"
                          className="form-select"
                          placeholder=" "
                          onChange={handleChange}
                        >
                          <option value="">Select Course ID</option>
                          {courseID.map((course) => (
                            <option key={course.id} value={course.id}>
                              {course.course_name}
                            </option>
                          ))}
                        </Field>
                        <label htmlFor="course_id">Course ID</label>
                        <ErrorMessage
                          name="course_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                  </div>
                  <div className="row mb-3">


                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="course_name"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="course_name">Course Name</label>
                        <ErrorMessage
                          name="course_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="status"
                          className="form-select"
                          placeholder=" "
                          onChange={handleChange}
                        >
                          <option value="">Not Set</option>
                          <option value={true}>Active</option>
                          <option value={false}>In-active</option>
                        </Field>
                        <label htmlFor="status">Status</label>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="subject">Subject</label>
                        <ErrorMessage
                          name="subject"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="date"
                          name="published"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="published">Published Date</label>
                        <ErrorMessage
                          name="published"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="hindi_file_url">Hindi Pdf</label>
                        <input
                          id="hindi_file_url"
                          name="hindi_file_url"
                          type="file"
                          className="form-control"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file.size <= fileSizeLimit) {
                              setFieldValue("hindi_file_url", file);
                            } else {
                              alert("File size exceeds 2MB limit");
                            }
                          }}
                        />
                        <a href={BASE_FILE_URL + values?.hindi_file_url} target="_blank" >{values.hindi_file_url ? "View" : ""}</a>
                        <ErrorMessage
                          name="hindi_file_url"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="eng_file_url">English Pdf</label>
                        <input
                          id="eng_file_url"
                          name="eng_file_url"
                          type="file"
                          className="form-control"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file.size <= fileSizeLimit) {
                              setFieldValue("eng_file_url", file);
                            } else {
                              alert("File size exceeds 2MB limit");
                            }
                          }}
                        />
                      
                        <a href={BASE_FILE_URL + values?.eng_file_url} target="_blank" >{values.eng_file_url ? "View" : ""}</a>
                        <ErrorMessage
                          name="eng_file_url"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>
  );
});

export default AddCourseData;
