import React from 'react'

const Disclaimer=() =>{
  return (
    <div>
    <div className="row">
<div className="col text-center">
<h1>Atal Bihari Vajpayee Vishwavidyalaya, Bilaspur</h1>
<p className="lead">
  (Established by Chhattisgarh Government Act 07/2012, formerly known as
  Bilaspur University)
</p>
<p>
  Welcome to Atal Bihari Vajpayee Vishwavidyalaya, a premier institution
  dedicated to excellence in education and research. Our university,
  established by the Chhattisgarh Government under Act 07/2012, continues
  the legacy of the former Bilaspur University.
</p>
<p>
  We offer a diverse range of undergraduate, postgraduate, and doctoral
  programs across various disciplines. Our mission is to nurture talent,
  foster innovative thinking, and contribute to the intellectual and
  professional growth of our students.
</p>
<p>
  At ABVV, we believe in the holistic development of our students, equipping
  them with the knowledge and skills necessary to excel in their chosen
  fields. Our faculty comprises esteemed educators and industry experts
  dedicated to providing a world-class education.
</p>
<p>
  Our university is committed to creating a vibrant academic environment
  that encourages critical thinking, creativity, and entrepreneurship. We
  strive to develop responsible citizens who can make a positive impact in
  society.
</p>
<p>
  With a strong focus on research and innovation, we aim to address the
  challenges of the 21st century and contribute to the betterment of our
  nation and the world at large.
</p>
<p>
  Our campus is equipped with state-of-the-art infrastructure, including
  modern classrooms, laboratories, and libraries, providing students with an
  ideal environment to learn and grow.
</p>
<p>
  We have a diverse student body from all over the country, creating a
  unique cultural and intellectual melting pot that enriches the learning
  experience.
</p>
<p>
  At ABVV, we are dedicated to providing a supportive and inclusive
  environment that fosters academic excellence, personal growth, and social
  responsibility.
</p>
<p>
  Join us on this exciting journey of discovery and growth, and become a
  part of the ABVV family!
</p>
<footer className="mt-4">
  <p>© ABVV, Bilaspur (C.G.) - 495001, India</p>
  <p>
    <strong>Disclaimer</strong>
  </p>
</footer>
</div>
</div>

</div>  )
}

export default Disclaimer