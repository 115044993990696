import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select, { components } from "react-select";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import { toast } from "react-toastify";
import Spinner from "../../../elements/Spinner";
import secureLocalStorage from "react-secure-storage";


const UploadNotice = observer(() => {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [noticeType, setNoticeType] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [deptId, setDeptId] = useState(null);
  const [noticeData, setNoticeData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    adminStore.getDepartments();

    adminStore.getNotificationType();
    let userData = secureLocalStorage.getItem('adminData')
    if (userData) {
      setDeptId(JSON.parse(userData)?.dept_id)
    }
  }, []);

  useEffect(() => {
    setNoticeData(
      adminStore.data.notifications?.find((x) => x.id === location?.state?.id)
    );
  }, [location?.state?.notifications])

  useEffect(() => {
    if (!deptId) {
      setDepartments(toJS(adminStore.data.departments));
    } else {
      setDepartments(toJS(adminStore.data.departments)?.filter(x => x.id == deptId))
    }
  }, [adminStore.data.departments, deptId]);

  useEffect(() => {
    setNoticeType(toJS(adminStore.data.notificationsType));
  }, [adminStore.data.notificationsType]);


  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    notice_type: Yup.string().required("Notice type is required"),
    notice_date: Yup.string().required("Notice Date is required"),
    last_date_display: Yup.string().required("Last date display is required"),
    url: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
  });
  const addNotification = (values) => {

    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("notice_type", values.notice_type);
    formData.append("notification_file", values.notification_file);
    formData.append("url", values.url);
    formData.append("show_in_home", values.show_in_home);
    formData.append("created_at", values.created_at);
    formData.append("notice_date", values.notice_date);
    formData.append("last_date_display", values.last_date_display);
    formData.append("dept_id", values.dept_id);
    formData.append("dept_name", values.dept_name);
    formData.append("status", values.status);
    if (!values.id) {
      adminStore.addNotification(formData, navigationCallBack);
    } else {
      formData.append("id", values.id);
      adminStore.updateNotification(formData, navigationCallBackUpdate);
    }
  };
  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/notices");
  };

  const navigationCallBack = () => {
    Swal("success", "successfully Uploded", "success");
    navigate("/admin/notices");
  };

  const handleClear = () => {
    fileInputRef.current.value = '';
  };
  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container p-3 bg-white mt-3">
            <h3 className="text-center mb-4">Upload Notice</h3>
            <Formik
              initialValues={{
                id: noticeData?.id,
                title: noticeData?.title || "",
                notice_type: noticeData?.notice_type || "",
                dept_id: noticeData?.dept_id || "",
                dept_name: noticeData?.dept_name || "",
                url: noticeData?.url || "",
                notification_file: noticeData?.notification_file || "",
                show_in_home: noticeData?.show_in_home || true,
                created_at: noticeData?.created_at || "",
                notice_date: noticeData?.notice_date || "",
                last_date_display: noticeData?.last_date_display || "",
                status: noticeData?.status || true,
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => addNotification(values)}
            >
              {({ setFieldValue, handleChange, values, errors }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="title"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="title">Title</label>
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Select
                          value={noticeType?.find((x) => x.title == values.notice_type) || ""}
                          options={noticeType}
                          getOptionLabel={(option) => option.title}
                          getOptionValue={(option) => option.title}
                          onChange={(event) =>
                            setFieldValue("notice_type", event?.title)
                          }
                          placeholder="Select Notice Type"
                          styles={{
                            control: (provided) => ({ ...provided, height: 58 }),
                            menu: (provided) => ({ ...provided, zIndex: 1000 }),
                          }}
                        />

                        <ErrorMessage
                          name="notice_type"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Select
                          value={
                            [{ id: "", department_name: "None" }, ...departments]?.find(
                              (x) => x.id == values.dept_id
                            ) || ""
                          }
                          options={[{ id: "", department_name: "None" }, ...departments]}
                          getOptionLabel={(option) => option.department_name}
                          getOptionValue={(option) => option.id}
                          onChange={(event) => {
                            if (event?.department_name === "None") {
                              setFieldValue("dept_id", "");
                              setFieldValue("dept_name", "");
                            } else {
                              setFieldValue("dept_id", event?.id);
                              setFieldValue("dept_name", event?.department_name);
                            }
                          }}
                          placeholder="Select Department"
                          styles={{
                            control: (provided) => ({ ...provided, height: 58 }),
                            menu: (provided) => ({ ...provided, zIndex: 1000 }),
                          }}
                        />


                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="date"
                          name="notice_date"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="notice_date">Notice Date</label>
                        <ErrorMessage
                          name="notice_date"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-4">
                      <div className="form-floating">
                        <Field
                          type="text"
                          name="url"
                          className="form-control"
                          placeholder=" "
                        />
                        <label htmlFor="url">URL</label>
                        <ErrorMessage
                          name="url"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="notification_file">
                          Notification File
                        </label>
                        <input
                          id="notification_file"
                          name="notification_file"
                          type="file"
                          className="form-control"
                          fileName={values?.notification_file || ""}
                          onChange={(event) => {
                            const file = event?.target?.files[0];
                            const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                            if (file?.size <= fileSizeLimit) {
                              setFieldValue
                                ("notification_file", file);
                            } else {
                              setFieldValue
                                ("notification_file", null);
                              // handleClear()
                              toast.warn("File size exceeds 10MB limit");
                            }
                          }}
                        />
                        <ErrorMessage
                          name="notification_file"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          as="select"
                          name="status"
                          className="form-select"
                          placeholder=" "
                          onChange={handleChange}
                        >
                          <option value="" disabled>Select Status</option>
                          <option value={true}>Active</option>
                          <option value={false}>In-active</option>
                        </Field>
                        <label htmlFor="enabled">Status</label>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="date"
                          name="last_date_display"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="last_date_display">Last Date Display</label>
                        <ErrorMessage
                          name="last_date_display"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-check mb-3">
                        <Field
                          type="checkbox"
                          name="show_in_home"
                          className="form-check-input"
                          id="show_in_home"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="show_in_home"
                        >
                          Show In Home
                        </label>
                        <ErrorMessage
                          name="show_in_home"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>
  );
});

export default UploadNotice;
