// src/pages/Home.js
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import { observer } from "mobx-react";
import Header from "../../components/Header";
import websiteStore from "../../store/WebsiteStore";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../api/config";
import moment from "moment";
import { useLocation, useNavigate , useParams} from "react-router-dom";
import ReactDatatable from '@mkikets/react-datatable';

const AffiliatedColleges = observer(() => {
  const location = useLocation();
  const [academics, setAcademics] = useState([]);
  const [selectorTab, setSelectorTab] = useState();
  const [academicData, setAcademicData] = useState();
  const params = new URLSearchParams(location.search);
  const [syllabusData, setSyllabusData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [courseData, setCourseData] = useState([]);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20); // Default to 20 items per page
  const [filterTabName, setFilterTabName] = useState("");
  

  // Filter state
  const [filters, setFilters] = useState({
    subject: "",
    month: "0",
    year: "0",
  });


  useEffect(() => {
    setAcademicData(toJS(websiteStore.data.notifications));
  }, [websiteStore.data.notifications]);
  useEffect(() => {
    setSyllabusData(toJS(websiteStore.data.syllabus));
  }, [websiteStore.data.syllabus]);

  useEffect(() => {
    setCourseData(toJS(websiteStore.data?.courseData?.map(record => ({ ...record, hindi_file_url: record.hindi_file_url ? (BASE_FILE_URL + record.hindi_file_url) : null, eng_file_url: record.eng_file_url ? (BASE_FILE_URL + record.eng_file_url) : null }))));
  }, [websiteStore.data.courseData]);
  const onCourseSelect = (course) => {
    websiteStore.getCourseData(course?.id);
    setSelectedCourse(course)
  }


  useEffect(() => {
    const sortedSubPages = toJS(
      websiteStore.data.pages?.filter((x) => x.tab_name === "academics")
    )?.sort((a, b) => a.order - b.order);
  
    setAcademics(sortedSubPages);
  
    const selectedId = params.get("id");
  
    if (sortedSubPages?.length) {
      if (selectedId) {
        // Set the tab matching the param `id`
        websiteStore.data.selectedSubTab =
          sortedSubPages.find((x) => x.id.toString() === selectedId) || null;
      }
  
      if (!websiteStore.data.selectedSubTab) {
        // Default to the first tab if no valid `id` is found in params
        websiteStore.data.selectedSubTab = sortedSubPages[0];
      }
    }
  }, [websiteStore.data.pages]);
  



  // useEffect(() => {
  //   setAcademics(toJS(
  //     websiteStore.data.pages?.filter((x) => x.tab_name === "academics")
  //   )?.sort((a, b) => a.order - b.order));
  //   if (websiteStore.data.pages?.length !== 0 && !websiteStore.data.selectedSubTab && !params.get("id")) {
  //     websiteStore.data.selectedSubTab = websiteStore.data.pages?.filter((x) => x.tab_name === "academics")?.sort((a, b) => a.order - b.order)[0]
  //   } else {
  //     if (!websiteStore.data.selectedSubTab) {
  //       websiteStore.data.selectedSubTab = websiteStore.data.pages?.filter((x) => x.tab_name === "academics")?.sort((a, b) => a.order - b.order)?.find(x => x.id == params.get("id"))
  //     }
  //   }
  // }, [websiteStore.data.pages]);

  useEffect(() => {
    setSelectorTab(websiteStore.data.selectedSubTab);
  }, [websiteStore.data.selectedSubTab]);

  useEffect(() => {
    setSelectorTab(websiteStore.data.selectedSubTab);
  }, [websiteStore.data.selectedSubTab]);

  // Filter and paginate the notices
  const filteredNotices = academicData
    ?.filter((item) => item.notice_type === (selectorTab?.page_name || ""))
    .filter((item) =>
      item.title.toLowerCase().includes(filters.subject.toLowerCase())
    )
    .filter(
      (item) =>
        filters.month === "0" ||
        moment(item.notice_date).month() + 1 === parseInt(filters.month)
    )
    .filter(
      (item) =>
        filters.year === "0" ||
        moment(item.notice_date).year() === parseInt(filters.year)
    );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNotices?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredNotices?.length / itemsPerPage);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "tabName") setFilterTabName(value); // Handle tab name filter change
  };

  const columns = [
    {
      key: "course_name",
      text: "Course Name",
      align: "left",
      sortable: true,
    },
    {
      key: "subject",
      text: "Subject",
      align: "left",
      sortable: true
    },
    {
      key: "published",
      text: "Published Date",
      sortable: true,
      cell: record => (
        <>{moment(record.published).format("DD/MM/YYYY")}</>
      )
    },
    {
      key: "hindi_file_url",
      text: "Hindi URL",
      align: "left",
      sortable: true,
      cell: record => (
        record.hindi_file_url ? (
          <a href={record.hindi_file_url} target="_blank" rel="noopener noreferrer">
            <button className="btn btn-info btn-sm">View Hindi File</button>
          </a>
        ) : (
          <span></span>
        )
      )
    },
    {
      key: "eng_file_url",
      text: "English File URl",
      sortable: true,
      align: "left",
      cell: record => (
        record.eng_file_url ? (
          <a href={record.eng_file_url} target="_blank" rel="noopener noreferrer">
            <button className="btn btn-info btn-sm">View English File</button>
          </a>
        ) : (
          <span></span>
        )
      )
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    // Add column visibility management
    responsive: true, // Hypothetical option for responsiveness
    // Manage column visibility
  };

  console.log(selectorTab, "aaaaa");

  return (
    <div>
      <Header />
      <section className="innerbanner" />
      <div style={{ width: "100%" }}>
        <div className="container-fluid">
          <div className="row2">
            <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
              <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <h3 className="main-head w-100">Academics</h3>
                <li className="w-100 px-2" style={{marginTop:"-35px"}}>
                      <div className="d-flex align-items-center">
                      <i class="fas fa-thumbtack text-dark me-2 flip-horizontal"></i>
                        <span className="d-sm-inline gd active-text">Syllabus</span>
                        {/* Add any additional elements or text here for the header */}
                      </div>
                      <div id="accordionParent" className="mx-3 my-2">
                        {syllabusData?.map((item, ind) => (
                          <div key={item?.id} id={`accordion-${item?.id}`} className="">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id={`heading-${item?.id}`}>
                                <a
                                  className="accordion-button collapsed nav-link align-middle px-2 py-2"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse-${item?.id}`}
                                  aria-expanded="false"
                                  aria-controls={`collapse-${item?.id}`}
                                  data-bs-parent="#accordionParent"
                                >
                                  {item?.syllabus_title}
                                </a>
                              </h2>
                              <div
                                id={`collapse-${item?.id}`}
                                className="accordion-collapse collapse"
                                aria-labelledby={`heading-${item?.id}`}
                              >
                                <div className="accordion-body">
                                  <div className="list-group">
                                    {item?.courses?.map((course, index) => (
                                      <button
                                        key={index}
                                        onClick={() => { onCourseSelect(course); setSelectorTab(); }}
                                        type="button"
                                        className="list-group-item list-group-item-action"
                                      >
                                        {course?.course_name}
                                      </button>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </li>
                <div className="form-group mb-2 w-100 d-flex" style={{gap:"15px"}}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Filter"
                    value={filterTabName}
                    name="tabName"
                    
                    onChange={handleFilterChange}
                    
                    
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" style={{width:"20px", opacity:"0.5"}} viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                </div>
                {academics?.length > 0 && (
                  <ul
                    className="nav  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
                    id="menu"
                  >
                    
                    {academics
                      .filter((item) =>
                        item.page_name
                          .toLowerCase()
                          .includes(filterTabName.toLowerCase())
                      )?.map((item, ind) => {
                      return (
                        <li key={ind} className="w-100">
                          <a
                            onClick={() => { setSelectorTab(item); setCourseData(); setSelectedCourse() }}
                            className={
                              selectorTab?.id === item.id
                                ? "active-tab nav-link align-middle px-2"
                                : "nav-link align-middle px-2"
                            }
                          >
                            <i className="fs-4 bi-house" />{" "}
                            <span className="d-sm-inline gd active-text">
                              {item?.page_name}
                            </span>
                          </a>
                        </li>
                      );
                    })}
                    

                  </ul>
                )}
                <hr />
              </div>
            </div>

            {selectorTab &&
              <div className="row1 mx-auto">
                <h3 className="main-head">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                  </svg>
                  {selectorTab?.page_name}
                </h3>
                <div>
                  {selectorTab?.page_name ==
                    "Pravesh Margdarshika" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          id="body-table"
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectorTab?.page_data,
                      }}
                    />
                  )}
                  {selectorTab?.page_name ==
                    "Colleges with Subjects and Intake Capacity" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectorTab?.page_name ==
                    "Seniority List" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectorTab?.page_name ==
                    "Research Projects in University" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          id="body-table"
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectorTab?.page_name ==
                    "Research Facilities in University" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          id="body-table"
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectorTab?.page_name ==
                    "Research Fellowship" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectorTab?.page_name ==
                    "Startup Atal University" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectorTab?.page_name ==
                    "MoUs" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectorTab?.page_name ==
                    "Shodhpeeth" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YYYY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectorTab?.page_name ==
                    "Awards and Honours" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectorTab?.page_name ==
                    "Ordinance" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          id="body-table"
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectorTab?.page_name ==
                    "Regulation" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectorTab?.page_name ==
                    "Guidelines" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                    >
                                      {[
                                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
                                        55, 60, 65, 70, 75, 80, 85, 90, 95,
                                        100,
                                      ].map((size) => (
                                        <option key={size} value={size}>
                                          {size}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          month: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          year: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          id="body-table"
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + ind + 1}
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn-primary w-80"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                    >
                                      View/Download
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                          <ul className="pagination">
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page}
                                className={`page-item ${currentPage === page + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            }


            {selectedCourse && <div className="row1 mx-auto">
              <h3 className="main-head">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                </svg>
                {selectedCourse?.course_name}
              </h3>
              <div>
                <ReactDatatable
                  config={config}
                  records={courseData?.length > 0 ? courseData : []}
                  columns={columns}
                />
              </div>

            </div>}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default AffiliatedColleges;
