import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import websiteStore from "../store/WebsiteStore";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";

const TopHeader = observer(() => {
    const [quickLinkPage, setQuickLinkPage] = useState([]);
    const [onlineServices, setOnlineServices] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setQuickLinkPage(
            websiteStore.data.pages?.filter((x) => x?.tab_name === "quick-links")
        );
        setOnlineServices(websiteStore.data.pages?.filter(x => x.tab_name === 'online-services'));
    }, [websiteStore.data.pages]);

    const loadSelectedData = (item, type) => {
        websiteStore.data.selectedSubTab = item;
        switch (type) {
            case 'quick-links':
                navigate("/quick-links");
                break;
            default:
                websiteStore.data.selectedSubTab = "";
                console.log(`Unknown sub tab: ${item}`);
        }
    };

    const goToTimeTable = () => {
        navigate('/table-view', { state: { type: "TimeTable", notice_type: "TimeTable" } });
    };

    const goToVisitorsView = () => {
        navigate('/table-view', { state: { type: "VisitorsView", notice_type: "Visitors View" } });
    };

    const goToNirf = () => {
        navigate('/table-view', { state: { type: "NIRF", notice_type: "NIRF" } });
    };

    const skipToMainContent = () => {
        document.getElementById("main-content")?.scrollIntoView({ behavior: "smooth" });
    };

    const handleScreenReaderAccess = () => {
        alert("Screen Reader Access function triggered.");
    };

    const handleSitemap = () => {
        alert("Sitemap function triggered.");
    };

    const handleFontResize = (action) => {
        const root = document.documentElement;
        let currentFontSize = parseFloat(getComputedStyle(root).fontSize);

        if (action === "increase") {
            root.style.fontSize = `${currentFontSize + 1}px`;
        } else if (action === "decrease") {
            root.style.fontSize = `${currentFontSize - 1}px`;
        } else if (action === "reset") {
            root.style.fontSize = "16px";
        }
    };

    const handleThemeChange = (theme) => {
        if (theme === "default") {
            document.body.classList.remove("black-theme");
        } else if (theme === "black") {
            document.body.classList.add("black-theme");
        }
    };

    return (
        <div className="top-nav external-url">
            <nav className="navbar navbar-expand-lg navbar-light bg-dark-subtle py-0">
                <div className="container-fluid">
                    <button
                        className="navbar-toggler collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarText"
                        aria-controls="navbarText"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="navbar-collapse collapse" id="navbarText" style={{}}>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Quick Links
                                </a>
                                {quickLinkPage?.length > 0 && (
                                    <ul className="dropdown-menu">
                                        {quickLinkPage?.map((item, ind) => (
                                            <li key={ind}>
                                                <a className="dropdown-item py-2" onClick={() => loadSelectedData(item, 'quick-links')}>
                                                    {item?.page_name}
                                                </a>
                                            </li>
                                        ))}
                                        {quickLinkPage?.length > 2 && (
                                            <li>
                                                <a href="/quick-links" className="dropdown-item py-2 text-decoration-underline">
                                                    Show More
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" target='_blank' href="https://bilaspuruniversity.samarth.ac.in/index.php/site/login">
                                    Samarth Portal
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://exam.bucgexam.in/" target="_blank">
                                    Admission
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://abvvbilaspur.com/default.aspx" target="_blank">
                                    Pre Ph.D. Online Form
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={goToNirf}>
                                    NIRF 2023
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/online-services" target="_blank">
                                    Online Services
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={goToVisitorsView}>
                                    <b>Visitors View</b>
                                </a>
                            </li>
                        </ul>
                        <ul
                            id="textresizer"
                            className="top_menu nav nav-pills d-flex align-items-center pull-right"
                        >
                            <li>
                                <a
                                    className="skiper local"
                                    title="Skip to Main Content"
                                    href="#main-content"
                                    tabIndex={1}
                                    onClick={skipToMainContent}
                                >
                                    <i className="fa fa-arrow-down" />
                                </a>
                            </li>
                            <li>
                                <a
                                    title="Screen Reader Access"
                                    href="#"
                                    className="external"
                                    tabIndex={2}
                                    onClick={handleScreenReaderAccess}
                                >
                                    <i className="fa fa-volume-up" />
                                </a>
                            </li>
                            <li>
                                <a
                                    title="Sitemap"
                                    href="#"
                                    className="external"
                                    tabIndex={3}
                                    onClick={handleSitemap}
                                >
                                    <i className="fa fa-sitemap" />
                                </a>
                            </li>
                            <li className="textresizer">
                                <a
                                    id="decreaseFont"
                                    className="decreaseFont"
                                    title="Smaller Font"
                                    href="#"
                                    tabIndex={4}
                                    onClick={() => handleFontResize("decrease")}
                                >
                                    A-
                                </a>
                            </li>
                            <li className="textresizer">
                                <a
                                    id="resetFont"
                                    className="resetFont"
                                    title="Medium Font"
                                    href="#"
                                    tabIndex={5}
                                    onClick={() => handleFontResize("reset")}
                                >
                                    A
                                </a>
                            </li>
                            <li className="textresizer">
                                <a
                                    id="increaseFont"
                                    className="increaseFont"
                                    title="Larger Font"
                                    href="#"
                                    tabIndex={6}
                                    onClick={() => handleFontResize("increase")}
                                >
                                    A+
                                </a>
                            </li>
                            <li>
                                <a
                                    className="theame defTheme default external"
                                    title="Default Theme"
                                    href="#"
                                    tabIndex={7}
                                    onClick={() => handleThemeChange("default")}
                                >
                                    {" "}
                                    T{" "}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="theame defTheme contrast external"
                                    title="Black Theme"
                                    href="#"
                                    tabIndex={8}
                                    onClick={() => handleThemeChange("black")}
                                >
                                    {" "}
                                    T{" "}
                                </a>
                            </li>
                            <li className="search">
                                <span className="search-open">
                                    {" "}
                                    <i className="fa fa-search" />{" "}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
});

export default TopHeader;
