// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import adminStore from "../../../store/AdminStore";
import Swal from "sweetalert";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../../api/config";
import Spinner from "../../../elements/Spinner";
import moment from "moment";


const CourseData = observer(() => {
  const [coursesData, setCourseData] = useState([]);
  const [courseID, setCourseID] = useState([]);
  const navigate = useNavigate()
  
  useEffect(() => {
    setCourseID(toJS(adminStore.data.courses));
  }, [adminStore.data.courses]);

  useEffect(() => {
    adminStore.getCourseData();
  }, []);

  useEffect(() => {
    setCourseData(toJS(adminStore.data.coursesData));
  }, [adminStore.data.coursesData]);

  const handleEdit = (row) => {
    navigate("/admin/add-course-data", { state: { id: row.id } });

  };

//   console.log(courses,"courses");
 
  const handleDelete = (row) => { 
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imp website!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          adminStore.deleteCourseData(row.id, navigationCallBack)
        }
      });
  };

  const navigationCallBack = () => {
    Swal("success", "successfully deleted", "success");
    adminStore.getCourseData();
  }

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width:"60px"
    },
    {
      name: "Course Name",
      selector: (row) => row.course_name,
      sortable: true,
    },
    {
      name: "Course Id",
      selector: (row) => {
        const course = courseID.find((s) => s.id === row.course_id);
        return course ? course.course_name : "";
      },
      sortable: true,
      width:"105px",
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
      width:"100px",
    },
    {
      name: "Published Date",
      selector: (row) => moment(row.published).format("DD/MM/YYYY hh:mm A"),
      sortable: true,
    },
    {
      name: "Hindi Pdf File",
      selector: (row) => (<a
        href={BASE_FILE_URL + row.hindi_file_url}
        target="_blank"
        rel="noopener noreferrer"
      ><i class="fa-regular fa-folder-open pe-2 "></i><span className="text-uppercase">{row?.pdf_file?.substring(row?.pdf_file.lastIndexOf('.') + 1)}</span>File</a>),
      sortable: true,
    },
    {
      name: "English Pdf File",
      selector: (row) => (<a
        href={BASE_FILE_URL + row.eng_file_url}
        target="_blank"
        rel="noopener noreferrer"
      ><i class="fa-regular fa-folder-open pe-2 "></i><span className="text-uppercase">{row?.pdf_file?.substring(row?.pdf_file.lastIndexOf('.') + 1)}</span>File</a>),
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status?"Active":"In-Active",
      sortable: true,

    },

    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={() => handleEdit(row)}
          >
            <i className="far fa-edit"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDelete(row)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div  >
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Courses Data
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a
                href="/admin/add-course-data"
                className="page-title signup-link fs-4 pointer"
              >
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Course Data
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={coursesData} />
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default CourseData
