// Carousel.js
import React from 'react';
import PropTypes from 'prop-types';
// or if using ES6 modules
import moment from 'moment';
import { BASE_FILE_URL } from '../api/config';
const EventCarousel = ({ items }) => {
    const chunkedItems = [];
    for (let i = 0; i < items.length; i += 2) {
        chunkedItems.push(items.slice(i, i + 2));
    }

    return (
        <div
            id="carouselExampleControls"
            className="carousel carousel-dark slide w-100 mt-4"
            data-bs-ride="carousel"
        >
            <div className="carousel-inner">
                {chunkedItems.map((chunk, idx) => (
                    <div className={`carousel-item ${idx === 0 ? 'active' : ''}`} key={idx}>
                        <div className="container d-flex justify-content-around">
                            {chunk.map((item, itemIdx) => (
                                <div className="card" style={{ width: '18rem', height: 414 }} key={itemIdx}>
                                    <a target='blank' href={BASE_FILE_URL + item.brochure_file}>
                                        <img src={item.thumbnail_image_url ? (BASE_FILE_URL + item.thumbnail_image_url) : `${BASE_FILE_URL}/uploads/media-files/1721468369081.jpg`} className="card-img-top" alt={item.event_name} />
                                        <div className="card-body">
                                            <h5 className="card-title fs-6 text-dark text-truncate">{item.event_name}</h5>
                                            <p className='text-dark'>Date: {moment(item.event_date).format('DD/MM/YYYY')}</p>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};

EventCarousel.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            thumbnail_image_url: PropTypes.string.isRequired,
            event_name: PropTypes.string.isRequired,
            event_date: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default EventCarousel;
