import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import websiteStore from '../../store/WebsiteStore';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { toJS } from 'mobx';
import ReactDatatable from '@mkikets/react-datatable';
import { BASE_FILE_URL } from '../../api/config';
import moment from 'moment';
const Syllabus = observer(() => {
  const [syllabusData, setSyllabusData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [courseData, setCourseData] = useState([]);
  const [filterTabName, setFilterTabName] = useState("");

  useEffect(() => {
    setSyllabusData(toJS(websiteStore.data.syllabus));
  }, [websiteStore.data.syllabus]);
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "tabName") setFilterTabName(value); // Handle tab name filter change
  };

  useEffect(() => {
    setCourseData(toJS(websiteStore.data.courseData.map(record => ({ ...record, hindi_file_url: record.hindi_file_url ? (BASE_FILE_URL + record.hindi_file_url) : record.eng_file_url ? record.eng_file_url : null, eng_file_url: record.eng_file_url?.includes("uploads") ? (BASE_FILE_URL + record.eng_file_url) : record.eng_file_url ? record.eng_file_url : null }))));
  }, [websiteStore.data.courseData]);

  const onCourseSelect = (course) => {
    websiteStore.getCourseData(course?.id);
    setSelectedCourse(course)
  }


  console.log(courseData,"courseDatacourseDatacourseDatacourseData");
  
  const columns = [
    {
      key: "course_name",
      text: "Course Name",
      align: "left",
      sortable: true,
    },
    // {
    //   key: "subject",
    //   text: "Subject",
    //   align: "left",
    //   sortable: true
    // },
    // {
    //   key: "published",
    //   text: "Published Date",
    //   sortable: true,
    //   cell: record => (
    //     <span>{moment(record).format('DD/MM/YYYY')}</span>
    //   )
    // },
    // {
    //   key: "hindi_file_url",
    //   text: "Hindi URL",
    //   align: "left",
    //   sortable: true,
    //   cell: record => (
    //     record.hindi_file_url ? (
    //       <a href={record.hindi_file_url} target="_blank" rel="noopener noreferrer">
    //         <button className="btn btn-info btn-sm">View Hindi File</button>
    //       </a>
    //     ) : (
    //       <span></span>
    //     )
    //   )
    // },
    {
      key: "eng_file_url",
      text: "Download",
      sortable: true,
      align: "left",
      cell: record => (
        record.eng_file_url ? (
          <a href={record.eng_file_url} target="_blank" rel="noopener noreferrer">
            <button className="btn btn-info btn-sm">View</button>
          </a>
        ) : (
          <span></span>
        )
      )
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    // Add column visibility management
    responsive: true, // Hypothetical option for responsiveness
    // Manage column visibility
  };

  return (
    <div>
      <Header />
      <section className="innerbanner" />
      <div style={{ width: "100%" }}>
        <div className="container-fluid">
          <div className="row2">
            <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light" style={{ minHeight: 500, backgroundColor: "white" }}>
              <div className="d-flex flex-column align-items-start px-3 pt-2 text-white">
                <h3 className="main-head w-100">Syllabus</h3>

              </div>
              <div className="form-group mb-2 w-100 d-flex" style={{ gap: "15px" }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter"
                  value={filterTabName}
                  name="tabName"

                  onChange={handleFilterChange}


                />
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "20px", opacity: "0.5" }} viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
              </div>
              <div id="accordionParent">
                {
                  syllabusData?.map((item, ind) => (
                    <div className="accordion" key={item?.id} id={`accordion-${item?.id}`}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id={`heading-${item?.id}`}>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${item?.id}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${item?.id}`}
                            data-bs-parent="#accordionParent"
                          >

                            {item?.syllabus_title}
                          </button>
                        </h2>
                        <div
                          id={`collapse-${item?.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading-${item?.id}`}
                        >
                          <div className="accordion-body">
                            <div className="list-group">
                              {item?.courses
                                ?.filter(course => !course?.deleted_at) // Filter out courses where deleted_at is not null
                                ?.map((course, index) => (
                                  <button
                                    key={index}
                                    onClick={() => onCourseSelect(course)}
                                    type="button"
                                    className="list-group-item list-group-item-action"
                                  >
                                    {course?.course_name}
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            {<div className="row1 mx-auto">
              <h3 className="main-head">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                </svg>
                {selectedCourse?.course_name}
              </h3>
              <div>
                <ReactDatatable
                  config={config}
                  records={courseData?.length > 0 ? courseData : []}
                  columns={columns}
                />
              </div>

            </div>}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
})

export default Syllabus