// src/pages/Home.js
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { observer } from "mobx-react";
import websiteStore from "../../store/WebsiteStore";
import PageViewModal from "../../components/PageViewModal";

import GalleryCarosel from "../../components/GalleryCarosel";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../api/config";
import moment from "moment";

const PhotoGallary = observer(() => {
  const [media, setMedia] = useState([]);
  const [selectorTab, setSelectorTab] = useState();
  const [showModal, setShowModal] = useState();
  const [modalData, setModalData] = useState();
  const [galleryData, setGalleryData] = useState();
  const [newsLetter, setNewsLetter] = useState();
  const [mediaNotification, setMediaNotification] = useState();
  const [filterTabName, setFilterTabName] = useState("");

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "tabName") setFilterTabName(value); // Handle tab name filter change
  };

  useEffect(()=>{
    setMediaNotification(toJS(websiteStore.data.notifications))
  },[websiteStore.data.notifications]);

  useEffect(() => {
    setNewsLetter(toJS(websiteStore.data.news));
  }, [websiteStore.data.news]);

  useEffect(() => {
    setMedia(toJS(websiteStore.data.pages?.filter(x => x.tab_name === 'media'))?.sort((a, b) => a.order - b.order));
    if (websiteStore.data.pages?.length !== 0 && !websiteStore.data.selectedSubTab) {
      websiteStore.data.selectedSubTab = websiteStore.data.pages?.filter((x) => x.tab_name === "media")[0]
    }
  }, [websiteStore.data.pages]);

  useEffect(() => {
    setSelectorTab(websiteStore.data?.selectedSubTab);
  }, [websiteStore.data.selectedSubTab]);

  useEffect(() => {
    setGalleryData(
      toJS(websiteStore.data.galleries?.filter((x) => x.image_paths !== "[]"))
    );
  }, [websiteStore.data.galleries]);

  const openModal = (data) => {
    setShowModal(true);
    setModalData(data);
  };

  const onClose = () => {
    setShowModal(false);
    setModalData();
  };

  return (
    <div>
      <Header />
      <>
        <section className="innerbanner" />
        <div style={{ width: "100%" }}>
          <div className="container-fluid">
            <div className="row2">
              <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
                <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                  <h3 className="main-head w-100">Media</h3>
                  <div className="form-group mb-2 w-100 d-flex" style={{gap:"15px"}}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Filter"
                    value={filterTabName}
                    name="tabName"
                    
                    onChange={handleFilterChange}
                    
                    
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" style={{width:"20px", opacity:"0.5"}} viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                </div>

                  {media?.length > 0 && (
                    <ul
                      className="nav  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
                      id="menu"
                    >
                      {media
                      .filter((item) =>
                        item.page_name
                          .toLowerCase()
                          .includes(filterTabName.toLowerCase())
                      )?.map((item, ind) => {
                        return (
                          <li key={ind} className="w-100">
                            <a
                              onClick={() => setSelectorTab(item)}
                              className={
                                selectorTab?.id === item.id
                                  ? "active-tab nav-link align-middle px-2"
                                  : "nav-link align-middle px-2"
                              }
                            >
                              <i className="fs-4 bi-house" />{" "}
                              <span className="d-sm-inline gd active-text">
                                {item?.page_name}
                              </span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <hr />
                </div>
              </div>
              {
                <div className="row1 mx-auto">
                  <h3 className="main-head">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                    </svg>
                    {selectorTab?.page_name}
                  </h3>
                  <div>
                    {/* {console.log(selectorTab.page_name,"tabs")} */}
                    {selectorTab?.page_name == "Photo/Video Gallery" ||
                      selectorTab?.page_name == "Video Gallery" ? (
                      <div>
                        <div className="grid d-flex w-100 gap-2">
                          <div class="gallery-image">
                            {galleryData?.map((item, ind) => {
                              return (
                                <div
                                  class="img-box"
                                  onClick={() => openModal(item)}
                                  key={ind}
                                >
                                  <a>
                                    <img
                                      src={
                                        item?.image_paths
                                          ? BASE_FILE_URL +
                                          JSON.parse(item?.image_paths)[0]
                                          : ""
                                      }
                                      alt="imsage"
                                    />
                                  </a>
                                  <div class="transparent-box">
                                    <div class="caption">
                                      <p style={{color:"white"}}>{item?.place}</p>
                                      <p class="opacity-low" style={{color:"white"}}>{item?.title}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectorTab?.page_data,
                        }}
                      />
                    )}
                    {selectorTab?.page_name == "University News Letter" ? (
                      <div className="container">
                        <div className="row" style={{ width: "100%" }}>
                          {newsLetter
                            ?.filter(
                              (item) => item.type === "university_newsletter"
                            )
                            .map((item, ind) => {
                              return (
                                <div
                                  className="col-md-6"
                                  style={{ marginTop: 15 }}
                                  key={ind}
                                >
                                  <div className="card">
                                    {" "}
                                    <a
                                      href={BASE_FILE_URL + item?.pdf_file}
                                      target="_blank"
                                    >
                                      {" "}
                                      <img
                                        src={
                                          BASE_FILE_URL + item?.thumbnail_image
                                        }
                                        className="card-img-top"
                                        alt={
                                          BASE_FILE_URL + item?.thumbnail_image
                                        }
                                      />{" "}
                                    </a>
                                    <div className="card-body">
                                      <h5 className="card-title">
                                        <a
                                          href={BASE_FILE_URL + item?.pdf_file}
                                          target="_blank"
                                          className="text-decoration-none text-primary-emphasis"
                                        >
                                          {item.subject}
                                        </a>
                                      </h5>
                                      <p className="card-text">
                                        {" "}
                                        <b>News Date:</b>{" "}
                                        {moment(item.created_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                    {selectorTab?.page_name == "Atal Drishti" ? (
                      <div className="container">
                        <div className="row" style={{ width: "100%" }}>
                          {newsLetter
                            ?.filter((item) => item.type === "atal_drishti")
                            .map((item, ind) => {
                              return (
                                <div
                                  className="col-md-6"
                                  style={{ marginTop: 15 }}
                                  key={ind}
                                >
                                  <div className="card">
                                    <a
                                      href={BASE_FILE_URL + item?.pdf_file}
                                      target="_blank"
                                    >
                                      <img
                                        src={
                                          BASE_FILE_URL + item?.thumbnail_image
                                        }
                                        className="card-img-top"
                                        alt={
                                          BASE_FILE_URL + item?.thumbnail_image
                                        }
                                      />
                                    </a>
                                    <div className="card-body">
                                      <h5 className="card-title">
                                        <a
                                          href={BASE_FILE_URL + item?.pdf_file}
                                          target="_blank"
                                          className="text-decoration-none text-primary-emphasis"
                                        >
                                          {item.subject}
                                        </a>
                                      </h5>
                                      <p className="card-text">
                                        <b>News Date:</b>{" "}
                                        {moment(item.created_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                    {selectorTab?.page_name == "University News" ? (
                      <div className="container">
                        <div className="row" style={{ width: "100%" }}>
                          {newsLetter
                            ?.filter(
                              (item) => item.type === "university_in_news"
                            )
                            .map((item, ind) => {
                              return (
                                <div
                                  className="col-md-6"
                                  style={{ marginTop: 15 }}
                                  key={ind}
                                >
                                  <div className="card">
                                    {" "}
                                    <a
                                      href={BASE_FILE_URL + item?.pdf_file}
                                      target="_blank"
                                    >
                                      {" "}
                                      <img
                                        src={
                                          BASE_FILE_URL + item?.thumbnail_image
                                        }
                                        className="card-img-top"
                                        alt={
                                          BASE_FILE_URL + item?.thumbnail_image
                                        }
                                      />{" "}
                                    </a>
                                    <div className="card-body">
                                      <h5 className="card-title">
                                        <a
                                          href={BASE_FILE_URL + item?.pdf_file}
                                          target="_blank"
                                          className="text-decoration-none text-primary-emphasis"
                                        >
                                          {item.subject}
                                        </a>
                                      </h5>
                                      <p className="card-text">
                                        {" "}
                                        <b>News Date:</b>{" "}
                                        {moment(item.created_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                    {selectorTab?.page_name == "Annual Report" ? (
                    <div>
                      <>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="pageSize">
                                      Display Per Page
                                    </label>{" "}
                                    <select
                                      className="form-control"
                                      id="pagesize"
                                      name="pagesize"
                                    >
                                      <option value="{5}">5</option>
                                      <option value="{10}">10</option>
                                      <option value="{15}">15</option>
                                      <option selected="selected" value="{20}">
                                        20
                                      </option>
                                      <option value="{25}">25</option>
                                      <option value="{30}">30</option>
                                      <option value="{35}">35</option>
                                      <option value="{40}">40</option>
                                      <option value="{45}">45</option>
                                      <option value="{50}">50</option>
                                      <option value="{55}">55</option>
                                      <option value="{60}">60</option>
                                      <option value="{65}">65</option>
                                      <option value="{70}">70</option>
                                      <option value="{75}">75</option>
                                      <option value="{80}">80</option>
                                      <option value="{85}">85</option>
                                      <option value="{90}">90</option>
                                      <option value="{95}">95</option>
                                      <option value="{100}">100</option>{" "}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="name">Subject</label>{" "}
                                    <input
                                      className="form-control"
                                      defaultvalue=""
                                      id="name"
                                      name="name"
                                      placeholder="Subject"
                                      type="text"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="month">Month</label>{" "}
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                    >
                                      <option selected="selected" value="{0}">
                                        All
                                      </option>
                                      <option value="{1}">January</option>
                                      <option value="{2}">February</option>
                                      <option value="{3}">March</option>
                                      <option value="{4}">April</option>
                                      <option value="{5}">May</option>
                                      <option value="{6}">June</option>
                                      <option value="{7}">July</option>
                                      <option value="{8}">August</option>
                                      <option value="{9}">September</option>
                                      <option value="{10}">October</option>
                                      <option value="{11}">November</option>
                                      <option value="{12}">
                                        December
                                      </option>{" "}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlfor="year">Year</label>{" "}
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                    >
                                      <option selected="selected" value="{0}">
                                        All
                                      </option>
                                      <option value="{2019}">2019</option>
                                      <option value="{2020}">2020</option>
                                      <option value="{2021}">2021</option>
                                      <option value="{2022}">2022</option>
                                      <option value="{2023}">2023</option>
                                      <option value="{2024}">2024</option>{" "}
                                    </select>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="submit"
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table className="table table-hover table-striped "
                        style={{ marginTop: "20px" }}>
                          <thead>
                            <tr className="table-primary">
                              <th>S No.</th>
                              <th>Subject</th>
                              <th>Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {mediaNotification
                              ?.filter(
                                (item) =>
                                  item.notice_type === "University Annual Report"
                              )
                              .map((item, ind) => {
                                return (
                                  <tr>
                                    <td>{ind + 1}</td>
                                    <td>
                                      <a
                                        href={
                                          BASE_FILE_URL + item.notification_file
                                        }
                                        target="_blank"
                                      >
                                        <b>{item.title}</b>{" "}
                                      </a>
                                    </td>
                                    <td>
                                      <b>
                                        {moment(item.created_at).format(
                                          "DD/MM/YY"
                                        )}{" "}
                                      </b>
                                    </td>
                                    <td>
                                      <a
                                        href={
                                          BASE_FILE_URL + item.notification_file
                                        }
                                        target="_blank"
                                      >
                                        <img src="image/pdf.png" width="50px" />{" "}
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </>
                    </div>
                  ) : (
                    <div />
                  )}
                  </div>
                </div>
              }
            </div>
          </div>
          {showModal && (
            <div className="modal modal-lg show d-block">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header justify-content-between">
                    <h5 className="modal-title text-uppercase">
                      {
                        galleryData.find(
                          (item) => item.image_paths === modalData.image_paths
                        )?.title
                      }
                    </h5>

                    <div className="">
                      <button
                        type="button"
                        className="btn fs-3 p-0 m-0"
                        data-dismiss="modal"
                        onClick={onClose} // Add the onClick handler here
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div id="carouselExampleRide" style={{ width: "100%" }}>
                      <GalleryCarosel
                        gallery={modalData}
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
      <Footer />
    </div>
  );
});

export default PhotoGallary;
