import { configure, makeAutoObservable, runInAction } from "mobx"
import { axiosInstance, axiosInstanceInt, setAuthToken, setAuthTokenInt } from "../api/axiosInstance"
import { toast } from 'react-toastify';
class WebsiteStore {
  loading = false
  data = {
    notifications: [],
    pages: [],
    events: [],
    results: [],
    news: [],
    selectedSubTab: "",
    selectedDeptSubTab: "",
    webSliders: [],
    navTabs: [],
    departments: [],
    departmentSliders: [],
    navDeptTabs: [],
    deptPages: [],
    // navTabs: [],
    whatsnew: [],
    galleries: [],
    impWebs: [],
    navSubTabs: [],
    courseData:[],
    popModal:true
  }
  errors = {}
  constructor() {
    makeAutoObservable(this);
    configure({
      useProxies: "never"
    })
  }
  toggleLoading = (toggle) => {
    this.loading = toggle
  }
  toggleLoadingApply = (toggle) => {
    this.loadingApply = toggle
  }


  async getNotifications() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("website/notifications")
      if (response) {
        runInAction(() => {
          this.data.notifications = response?.data?.notifications;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getWhatsnew() {

    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("website/whats-new")
      if (response) {
        runInAction(() => {
          this.data.whatsnew = response?.data?.whats_new;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getPages() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("website/pages")
      if (response) {
        runInAction(() => {
          this.data.pages = response?.data?.pages;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getDepartmentPages(param) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post("website/department-pages", param)
      if (response) {
        runInAction(() => {
          this.data.deptPages = response?.data?.department_pages;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getEvents() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/event")
      if (response) {
        runInAction(() => {
          this.data.events = response?.data?.event;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getNews() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/news")
      if (response) {
        runInAction(() => {
          this.data.news = response?.data?.news;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getTabs() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/nav-tabs")
      if (response) {
        runInAction(() => {
          this.data.navTabs = response?.data?.nav_tab;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getDeptTabs() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/nav-dept-tabs")
      if (response) {
        runInAction(() => {
          this.data.navDeptTabs = response?.data?.dept_tabs;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getResults() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/website/results")
      if (response) {
        runInAction(() => {
          this.data.results = response?.data?.results;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getSlider() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("website/sliders")
      if (response) {
        runInAction(() => {
          this.data.webSliders = response?.data?.sliders;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getGallery() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("website/photo-video")
      if (response) {
        runInAction(() => {
          this.data.galleries = response?.data?.photoVideo;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getImpWebs() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("website/imp-web")
      if (response) {
        runInAction(() => {
          this.data.impWebs = response?.data?.impweb;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async addContact(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post("/website/add-contact", param)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async addComplaint(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post("/website/add-complaint", param)
      if (response) {
        navigationCallBack()
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getDeparments() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/website/departments")
      if (response) {
        runInAction(() => {
          this.data.departments = response?.data?.departments;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getSliderById(id) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post("/website/slider-by-id", { "id": id })
      if (response) {
        runInAction(() => {
          this.data.departmentSliders = response?.data?.slider;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getSettings() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/website/settings")
      if (response) {
        runInAction(() => {
          this.data.settings = response?.data?.settings;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getNavSubTabs(id) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`/website/nav-sub-tabs/${id}`)
      if (response) {
        runInAction(() => {
          this.data.navSubTabs = response?.data?.nav_tabs;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getSyllabus() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("website/syllabus")
      if (response) {
        runInAction(() => {
          this.data.syllabus = response?.data?.syllabus;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getCourseData(id) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`website/course-data/${id}`)
      if (response) {
        runInAction(() => {
          this.data.courseData = response?.data?.course_data;
        })
      } else {
        // toast.error("Something went to wrong!");
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

}
const websiteStore = new WebsiteStore();
export default websiteStore;