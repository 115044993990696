import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import { observer } from 'mobx-react';
import websiteStore from '../../../store/WebsiteStore';
import IqacHeader from '../../../components/IqacHeader';
import moment from 'moment';
import { BASE_FILE_URL } from '../../../api/config';
import { toJS } from 'mobx';

const IqacSubPages = observer(() => {
  const [iqacPageData, setIqacPageData] = useState(null);
  const [administrationData, setAdministrationData] = useState();

  const [iqacData, setIqacData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20); // Default to 20 items per page
  const [filterTabName, setFilterTabName] = useState("");

  const [filters, setFilters] = useState({
    subject: "",
    month: "0",
    year: "0",
  });

  useEffect(() => {
    setAdministrationData(toJS(websiteStore.data.notifications));
  }, [websiteStore.data.notifications]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

   // Filter and paginate the notices
   const filteredNotices = administrationData
   ?.filter((item) => item.notice_type === (iqacPageData?.page_name || ""))
   .filter((item) =>
     item.title.toLowerCase().includes(filters.subject.toLowerCase())
   )
   .filter(
     (item) =>
       filters.month === "0" ||
       moment(item.notice_date).month() + 1 === parseInt(filters.month)
   )
   .filter(
     (item) =>
       filters.year === "0" ||
       moment(item.notice_date).year() === parseInt(filters.year)
   );


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNotices?.slice(indexOfFirstItem, indexOfLastItem) || [];

  const totalPages = Math.ceil((filteredNotices?.length || 0) / itemsPerPage);
  useEffect(() => {
    const fetchData = async () => {
      await websiteStore.getDeparments();
      await websiteStore.getDepartmentPages({ dept_id: '7' });
      
      const selectedTab = localStorage.getItem('selected_tab');
      if (selectedTab) {
        setIqacPageData(JSON.parse(selectedTab));
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setIqacData(websiteStore.data.departments?.find(x => x.id === '7'));
    if (websiteStore.data.selectedDeptSubTab?.id) {
      setIqacPageData(websiteStore.data.deptPages?.find(x => x.id === websiteStore.data.selectedDeptSubTab?.id));
    } else {
      setIqacPageData(websiteStore.data.deptPages);
    }
    
  }, [websiteStore.data.departments, websiteStore.data.deptPages, websiteStore.data.selectedDeptSubTab]);

  const handleTabClick = (item) => {
    websiteStore.data.selectedDeptSubTab = item;
    localStorage.setItem("selected_tab", JSON.stringify(item));
    setIqacPageData(item);
  };

  return (
    <div>
      <Header />
      <IqacHeader />
      <div style={{ width: "100%" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 px-sm-2 px-0 bg-light">
              <div className="d-flex flex-column align-items-center px-3 pt-2 text-white min-vh-100">
                <h3 className="main-head w-100">IQAC</h3>
                {websiteStore.data.deptPages?.filter(x => x.tab_name === iqacPageData?.tab_name)?.map((item) => (
                  <ul key={item.id} className="nav mb-0 align-items-center w-100">
                    <li className="w-100">
                      <a
                        onClick={() => handleTabClick(item)}
                        className={
                          websiteStore.data.selectedDeptSubTab?.id === item.id
                            ? "active-tab nav-link align-middle px-2"
                            : "nav-link align-middle px-2"
                        }
                      >
                        <i className="fs-4 bi-house" />{" "}
                        <span className="d-sm-inline gd active-text">
                          {item.page_name}
                        </span>
                      </a>
                    </li>
                  </ul>
                ))}
                <hr />
              </div>
            </div>
            <div className="col-md-9 pt-2 bg-light">
              <div className="row">
                <div className="col">
                  <h3 className="main-head">{iqacPageData?.page_name}</h3>
                  <div>
                    {iqacPageData?.page_name === "IQAC Committee" || iqacPageData?.page_name === "IQAC Core Committee" || iqacPageData?.page_name === "Steering Committee" ? (
                      <div>
                        <form action="#">
                          <table className="table table-primary">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-group">
                                    <label htmlFor="pageSize">Display Per Page</label>
                                    <select
                                      className="form-control"
                                      id="pageSize"
                                      value={itemsPerPage}
                                      onChange={(e) =>
                                        setItemsPerPage(parseInt(e.target.value, 10))
                                      }
                                    >
                                      {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                                        (size) => (
                                          <option key={size} value={size}>
                                            {size}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlFor="subject">Subject</label>
                                    <input
                                      className="form-control"
                                      id="subject"
                                      name="subject"
                                      placeholder="Subject"
                                      type="text"
                                      value={filters.subject}
                                      onChange={handleFilterChange}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlFor="month">Month</label>
                                    <select
                                      className="form-control"
                                      id="month"
                                      name="month"
                                      value={filters.month}
                                      onChange={handleFilterChange}
                                    >
                                      <option value="0">All</option>
                                      {[...Array(12).keys()].map((i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {moment().month(i).format("MMMM")}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <label htmlFor="year">Year</label>
                                    <select
                                      className="form-control"
                                      id="year"
                                      name="year"
                                      value={filters.year}
                                      onChange={handleFilterChange}
                                    >
                                      <option value="0">All</option>
                                      {[2024, 2023, 2022, 2021, 2020].map(
                                        (year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td className="align-middle">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary mt-4"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#0b559f",
                                      }}
                                      type="button"
                                      onClick={() => setCurrentPage(1)} // Reset to the first page on filter change
                                    >
                                      Search!
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                        <table
                          className="table table-hover table-striped"
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="col-md-1">S No.</th>
                              <th className="col-md-7">Subject</th>
                              <th className="col-md-2">Notice Date</th>
                              <th>View/Download</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentItems.map((item, ind) => (
                              <tr key={ind}>
                                <td>{(currentPage - 1) * itemsPerPage + ind + 1}</td>
                                <td>
                                  <a
                                    href={BASE_FILE_URL + item.notification_file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{textDecoration:"none"}}
                                  >
                                    <b>{item.title}</b>
                                  </a>
                                </td>
                                <td>
                                  <b>
                                    {moment(item.notice_date).format("DD/MM/YY")}
                                  </b>
                                </td>
                                <td>
                                <a
                                      href={BASE_FILE_URL + item.notification_file}
                                      target="_blank"
                                    >
                                      <button
                                        className="btn btn-primary w-80"
                                        style={{
                                          color: "white",
                                          backgroundColor: "#0b559f",
                                        }}
                                      >
                                        View/Download
                                      </button>
                                    </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="d-flex justify-content-center mt-4">
                          <ul className="pagination">
                            <li
                              className={`page-item ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  setCurrentPage((prev) => Math.max(prev - 1, 1))
                                }
                              >
                                Previous
                              </button>
                            </li>
                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page + 1}
                                className={`page-item ${
                                  currentPage === page + 1 ? "active" : ""
                                }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}
                            <li
                              className={`page-item ${
                                currentPage === totalPages ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    Math.min(prev + 1, totalPages)
                                  )
                                }
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div
                      style={{
                        backgroundColor: "white",
                        borderBottom: "2px solid #03356E",
                        padding: 10,
                      }}
                      className="text-primary-emphasis"
                      dangerouslySetInnerHTML={{ __html: iqacPageData?.page_data }}
                    />
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default IqacSubPages;
