// src/pages/Home.js
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { observer } from "mobx-react";
import websiteStore from "../../store/WebsiteStore";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../api/config";
import moment from "moment";

const OnlineServices = observer(() => {
  const [onlineServices, setOnlineServices] = useState([]);
  const [selectorTab, setSelectorTab] = useState();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // Filter state
  const [subjectFilter, setSubjectFilter] = useState("");
  const [monthFilter, setMonthFilter] = useState(0);
  const [yearFilter, setYearFilter] = useState(0);

  useEffect(() => {
    setSelectorTab(websiteStore.data.selectedSubTab);
  }, [websiteStore.data.selectedSubTab]);

  useEffect(() => {
    setOnlineServices(toJS(websiteStore.data.notifications));
  }, [websiteStore.data.notifications]);

  // Filtering and Pagination logic
  const filteredItems = onlineServices
    .filter((item) => item.notice_type === "Online Services")
    .filter((item) =>
      subjectFilter
        ? item.title.toLowerCase().includes(subjectFilter.toLowerCase())
        : true
    )
    .filter((item) =>
      monthFilter > 0
        ? moment(item.notice_date).month() + 1 === monthFilter
        : true
    )
    .filter((item) =>
      yearFilter > 0 ? moment(item.notice_date).year() === yearFilter : true
    );

  const currentItems = filteredItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  return (
    <div>
      <Header />
      <section className="innerbanner" />
      <div className="container-fluid body-content content" id="main-content">
        <div className="row3 minusmrgin">
          <div className="col-md-12">
            <div className="boxed min700">
              <h3 className="text-primary-emphasis">
                <i className="fa-solid fa-list-check" /> Online Services
              </h3>
              <div>
                <div>
                  <>
                    <form>
                      <table className="table table-primary">
                        <tbody>
                          <tr>
                            <td>
                              <div className="form-group">
                                <label htmlFor="pagesize">Display Per Page</label>
                                <select
                                  className="form-control"
                                  id="pagesize"
                                  value={itemsPerPage}
                                  onChange={(e) => {
                                    setItemsPerPage(parseInt(e.target.value, 10));
                                    setCurrentPage(1); // Reset to first page
                                  }}
                                >
                                  {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100].map(size => (
                                    <option key={size} value={size}>{size}</option>
                                  ))}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="name">Subject</label>{" "}
                                <input
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  placeholder="Subject"
                                  type="text"
                                  value={subjectFilter}
                                  onChange={(e) => {
                                    setSubjectFilter(e.target.value);
                                    setCurrentPage(1); // Reset to first page
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="month">Month</label>{" "}
                                <select
                                  className="form-control"
                                  id="month"
                                  name="month"
                                  value={monthFilter}
                                  onChange={(e) => {
                                    setMonthFilter(parseInt(e.target.value, 10));
                                    setCurrentPage(1); // Reset to first page
                                  }}
                                >
                                  <option value={0}>All</option>
                                  {[...Array(12).keys()].map(i => (
                                    <option key={i + 1} value={i + 1}>
                                      {moment().month(i).format("MMMM")}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <label htmlFor="year">Year</label>{" "}
                                <select
                                  className="form-control"
                                  id="year"
                                  name="year"
                                  value={yearFilter}
                                  onChange={(e) => {
                                    setYearFilter(parseInt(e.target.value, 10));
                                    setCurrentPage(1); // Reset to first page
                                  }}
                                >
                                  <option value={0}>All</option>
                                  {[2024, 2023, 2022, 2021, 2020].map(year => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                    <table
                      className="table table-hover table-striped"
                      style={{ marginTop: "20px" }}
                    >
                      <thead>
                        <tr className="table-primary">
                          <th className="col-md-1">S No.</th>
                          <th className="col-md-7">Subject</th>
                          <th className="col-md-2">Notice Date</th>
                          <th>View/Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((item, ind) => (
                          <tr key={ind}>
                            <td>{(currentPage - 1) * itemsPerPage + ind + 1}</td>
                            <td>
                              <a
                                href={BASE_FILE_URL + item.notification_file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <b>{item.title}</b>{" "}
                              </a>
                            </td>
                            <td>
                              <b>
                                {moment(item.notice_date).format("DD/MM/YY")}
                              </b>
                            </td>
                            <td>
                              <a
                                href={BASE_FILE_URL + item.notification_file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button
                                  className="btn btn-primary w-80"
                                  style={{
                                    color: "white",
                                    backgroundColor: "#0b559f",
                                  }}
                                >
                                  View/Download
                                </button>{" "}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <nav aria-label="Page navigation">
                      <ul className="pagination">
                        {[...Array(totalPages).keys()].map((page) => (
                          <li
                            key={page}
                            className={`page-item ${
                              currentPage === page + 1 ? "active" : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => setCurrentPage(page + 1)}
                            >
                              {page + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default OnlineServices;
