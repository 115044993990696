import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Swal from "sweetalert";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import adminStore from "../../../store/AdminStore";
import { toast } from "react-toastify";
import { toJS } from "mobx";
import { BASE_FILE_URL } from "../../../api/config";
import Spinner from "../../../elements/Spinner";


const AddNewSlider = observer(() => {
  const navigate = useNavigate();
  const [slider, setSlider] = useState();
  const location = useLocation();

  useEffect(() => {
    setSlider(
      toJS(adminStore.data.sliders?.find((x) => x.id === location?.state?.id))
    );
  }, [location?.state?.sliders]);

  // console.log(slider, "sk");

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    images: Yup.mixed().required("Required"),
    status: Yup.boolean().required("Required"),
  });

  const addSlider = (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("delete_ids", values.delete_ids);

    values.images.forEach((image, index) => {
      console.log(image);
      
      formData.append(`images`, image?.filePath || null);
      formData.append(`images[${index}][order]`, image.order);
      formData.append(`images[${index}][caption]`, image.caption);
      formData.append(`images[${index}][image_id]`, image?.id || null);
      formData.append(`images[${index}][filename]`, image?.filePath?.name || null);
    });

    if (!values.id) {
      adminStore.addSlider(formData, navigationCallBack);
    } else {
      formData.append("id", values.id);
      adminStore.updateSlider(formData, navigationCallBackUpdate);
    }
  };
  const navigationCallBackUpdate = () => {
    Swal("Success!", "Successfully Updated", "success");
    navigate("/admin/slider");
  };

  const navigationCallBack = () => {
    Swal("success", "successfully Uploded", "success");
    navigate("/admin/slider");
  };

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div>
        <main className="main">
          <Header />
          <div className="container mt-2">
            <h3 className="text-center mb-4">Upload Slider</h3>
            <Formik
              enableReinitialize
              initialValues={{
                id: slider?.id,
                delete_ids: [],
                title: slider?.title || "",
                images: slider?.images || [
                  { caption: "", order: 1, filePath: "" },
                ],
                status: true,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addSlider(values);
              }}
            >
              {({ setFieldValue, handleChange, values }) => (
                <Form>
                  {/* {console.log(values, "formik-Values")} */}
                  {console.log(values.delete_ids, "delete_ids")}
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="title"
                          className="form-control"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="title">Title</label>
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <FieldArray
                      name="images"
                      render={(arrayHelpers) => (
                        <div>
                          <div className="d-flex flex-wrap">
                            {values.images.map((image, index) => (
                              <div key={index} className={values.delete_ids.includes(image.id) ? "form-group card deleted-card" : "form-group card"}>


                                <i onClick={() => {
                                  setFieldValue("delete_ids", values?.delete_ids?.filter(x => x != image?.id))
                                }} className="fas fa-undo-alt undo-icon" ></i>

                                {/* {console.log(image,"image")} */}
                                <div className="blur-card">
                                  <label htmlFor={`images.${index}.filePath`}>
                                    Select Image File
                                  </label>
                                  <input
                                    id={`images.${index}.filePath`}
                                    name={`images.${index}.filePath`}
                                    type="file"
                                    className="form-control"
                                    onChange={(event) => {
                                      const file = event.target.files[0];
                                      const fileSizeLimit = 40 * 1024 * 1024; // 2MB
                                      if (file.size <= fileSizeLimit) {
                                        setFieldValue(
                                          `images.${index}.filePath`,
                                          file
                                        );
                                      } else {
                                        toast.warn("File size exceeds 2MB limit");
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`images.${index}.filePath`}
                                    component="div"
                                    className="text-danger"
                                  />
                                  {image.image_path && <img
                                    src={BASE_FILE_URL + image.image_path}
                                    width={100}
                                    height={100}
                                    alt="image.image_path"
                                    style={{ border: 2, padding: 2 }}
                                  />}

                                  <label htmlFor={`images.${index}.caption`}>
                                    Caption
                                  </label>
                                  <input
                                    id={`images.${index}.caption`}
                                    name={`images.${index}.caption`}
                                    type="text"
                                    value={values.images[index].caption}
                                    className="form-control"
                                    onChange={(event) => {
                                      setFieldValue(
                                        `images.${index}.caption`,
                                        event.target.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`images.${index}.caption`}
                                    component="div"
                                    className="text-danger"
                                  />
                                  <button
                                    disabled={values.images.length === 1}
                                    type="button"
                                    className="btn btn-danger mt-3"
                                    onClick={() => {

                                      if (values.images[index].id) {
                                        setFieldValue('delete_ids', [values.images[index].id, ...values.delete_ids]);
                                      } else {
                                        arrayHelpers.remove(index);
                                      }
                                    }}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                          <button
                            type="button"
                            className="btn btn-success float-button "
                            onClick={() =>
                              arrayHelpers.push({
                                caption: "",
                                order: values.images?.length + 1,
                                filePath: "",
                              })
                            }
                          >
                            <i className="fas fa-plus pe-3"></i>
                            Add Image
                          </button>
                        </div>
                      )}
                    />
                  </div>
                  <div className="row  justify-content-center">
                    <button type="submit" className="btn btn-primary w-25">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
      {<Spinner loading={adminStore.loading} />}
    </div>
  );
});

export default AddNewSlider;
