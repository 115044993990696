// src/pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { observer } from "mobx-react";
import adminStore from "../../../store/AdminStore";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import Swal from "sweetalert";
import { BASE_FILE_URL } from "../../../api/config";
import moment from "moment";
import Spinner from "../../../elements/Spinner";


const NewsList = observer (() => {
  const [newsList, setNewsList] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    adminStore.getNews();
  }, []);

  
  useEffect(() => {
    setNewsList(toJS(adminStore.data.newsData));
  }, [adminStore.data.newsData]);

  const handleEdit = (row) => { 
    navigate('/admin/addnews', { state: { id: row.id } });
 
  };


  const navigationCallBack = () => {
    Swal("Success!", "Successfully Deleted", "success");
    adminStore.getNews();
  };

  
  const handleDelete = (row) => { 
    Swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this news!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        adminStore.deleteNews(row.id, navigationCallBack);
      }
    })
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width:"60px"
    },

    {
      name: "Thumbnail Image ",
      selector: (row) => <img width={50} src={BASE_FILE_URL + row.thumbnail_image}/>
      ,
      sortable: true,
      
    },
    {
      name: "File",
      selector: (row) => (
        <a
          href={BASE_FILE_URL + row.pdf_file}
          target="_blank"
          rel="noopener noreferrer"
        ><i class="fa-regular fa-folder-open pe-2 "></i><span className="text-uppercase">{row?.pdf_file?.substring(row?.pdf_file.lastIndexOf('.') + 1)}</span>File</a>
      ),
      sortable: true,
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    
   

    {
      name: "Dept Id",
      selector: (row) => row.dept_id,
      sortable: true,
    },

    {
      name: "Expiry Date",
      selector: (row) => moment(row.expiry_date).format("DD/MM/YYYY hh:mm A"),
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.status?"Active":"In-active",
      sortable: true,
    },

  

    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={() => handleEdit(row)}
          >
            <i className="far fa-edit"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDelete(row)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="d-flex bg-white" id="wrapper">
      <Sidebar />
      <div  >
        <main className="main">
          <Header />
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              News
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <a
                href="/admin/addnews"
                className="page-title signup-link fs-4 pointer"
              >
                <span className="page-title-icon linear ">
                  <i className="fas fa-plus"></i>
                </span>
                Add New News List
              </a>
            </div>
          </div>
          <CustomDataTable columns={columns} data={newsList} />
        </main>
      </div>
      {<Spinner loading={adminStore.loading}/>}
    </div>
  );
});

export default NewsList;
