import React from 'react'

const Copyright=() =>{
  return (
    <div><>
    <div className="container">
      <h1>Privacy Policy</h1>
      <p>
        Atal Bihari Vajpayee Vishwavidyalaya, Bilaspur (C.G.) - 495001, India
        (hereafter referred to as "ABVV", "we", "our", or "us") is committed to
        protecting your privacy and ensuring the security of your personal
        information.
      </p>
      <h2>1. Information We Collect</h2>
      <p>
        We may collect various types of personal information from you when you
        interact with us, including:
      </p>
      <ul>
        <li>
          Contact information such as your name, email address, and phone number.
        </li>
        <li>Demographic information such as age, gender, and interests.</li>
        <li>
          Information you provide voluntarily through forms, surveys, or
          correspondence.
        </li>
        <li>
          Technical information collected automatically, including IP address,
          browser type, and device identifiers.
        </li>
        <li>
          Cookies and similar technologies that may collect information about your
          browsing behavior and preferences.
        </li>
      </ul>
      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li>
          To provide and improve our services, including personalized content and
          recommendations.
        </li>
        <li>
          To communicate with you, respond to inquiries, and provide support.
        </li>
        <li>
          To analyze usage trends and preferences in order to enhance user
          experience.
        </li>
        <li>
          To comply with legal obligations and protect our rights and interests.
        </li>
        <li>
          To prevent fraud and ensure the security of our website and services.
        </li>
      </ul>
      <h2>3. Disclosure of Your Information</h2>
      <p>We may share your personal information with:</p>
      <ul>
        <li>
          Service providers and partners who assist us in delivering and improving
          our services.
        </li>
        <li>
          Legal authorities or government bodies when required by law or to
          protect our legal rights.
        </li>
        <li>
          Other third parties with your consent or as permitted or required by
          law.
        </li>
      </ul>
      <h2>4. Your Rights and Choices</h2>
      <p>You have the right to:</p>
      <ul>
        <li>
          Access and update your personal information by contacting us directly.
        </li>
        <li>
          Object to the processing of your information under certain
          circumstances.
        </li>
        <li>
          Request the deletion of your personal data, subject to legal
          obligations.
        </li>
        <li>Opt-out of receiving marketing communications at any time.</li>
      </ul>
      <h2>5. Security of Your Information</h2>
      <p>
        We employ security measures to protect your personal information from
        unauthorized access, alteration, or disclosure. However, no method of
        transmission over the internet or electronic storage is completely secure.
      </p>
      <h2>6. Children's Privacy</h2>
      <p>
        Our services are not intended for children under the age of 13. We do not
        knowingly collect or solicit personal information from children. If we
        learn that we have inadvertently collected personal information from a
        child under 13, we will take steps to delete the information as soon as
        possible.
      </p>
      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in
        our practices or for other operational, legal, or regulatory reasons. We
        encourage you to review this policy periodically for any updates.
      </p>
      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy or our practices
        regarding your personal information, please contact us at:
      </p>
      <p>
        Atal Bihari Vajpayee Vishwavidyalaya,
        <br />
        Address: Bilaspur (C.G.) - 495001, India
        <br />
        Email: contact@abvv.ac.in
        <br />
        Phone: +91 XXXXXXXXXX
      </p>
    </div>
    <footer>© ABVV, Bilaspur (C.G.) - 495001, India</footer>
  </>
  </div>
  )
}

export default Copyright